.o-cardProfile {
    &-active {
        .o-cardProfile_img {
            &:before {
                transform: scale(0);
            }
        }
    }
    &_img {
        border-radius: rem(5);
        overflow: hidden;
        position: relative;

        &:hover {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
            border: 3px solid $brand_yellow;
            &:before {
                transform: scale(0);
            }
            &::after {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
                border: 3px solid $brand_yellow;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: rem(-3);
            left: rem(-3);
            right: rem(-3);
            bottom: rem(-3);
            z-index: 1;
            transition: 0.3s ease;
            border: rem(3) solid transparent;
            border-radius: rem(5);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0.6;
            transition: 0.3s ease;
            transform: scale(1);
            background-image: linear-gradient(to top, #16222a, #3a6073);
        }
        a {
            display: block;
        }
        .o-cardProfile_img_first-character{
            position: relative;
            overflow: hidden;
            display: block;
            max-width: 100%;
            max-height: 100%;
            background-color: $brand_blue_dark;
            @include aspectRatio(260, 320); 
            .a-text{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: rem(110);
                font-weight: Bold;
                color: $white;
            }
        }
    }

    &_tag{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(28);
        padding: 0 rem(3);
        min-width: rem(145);
        border-top-left-radius: rem(5);
        border-top-right-radius: rem(5);

        .a-text{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        @include small-mobile {
            width: 100%;
            border-radius: 0;
            left: 0;
            transform: translateX(0);
        }
        &-completed{
            background-color: $bg_red;
        }
        &-marked{
            background-color: $persian_green;
        }
        &-progress{
            background-color: $bg_yellow;
        }
    }

    &_lateDate {
        position: absolute;
        top: rem(10);
        right: 0;
        padding: rem(5);
        height: rem(28);
        white-space: nowrap;
        background-color: $red;
        border-top-left-radius: rem(5);
        border-bottom-left-radius: rem(5);
    }

    &_info {
        color: $brand_blue_dark;
        margin-top: rem(10);
        &_name {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            a {
                font-weight: bold;
                margin-bottom: rem(10);
                font-size: rem(24);
                color: $brand_blue_dark;
                text-decoration: none;
                @include tablet-down {
                    font-size: rem(16);
                }
                @include mobile-down {
                    font-size: rem(14);
                }
            }
        }
        &_desc {
            font-size: rem(12);
            margin-top: rem(10);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}
