.t-modalnotification {
  $root: &;
  margin:auto; 

  &_mainContainer {
    background-color: $white;
    width: 100%;
    max-width: rem(1088);
    border: solid rem(2) $brand_yellow;
    border-radius: rem(5);
    padding: rem(40) rem(40) rem(50);
  }

  &_informationContainer {
    padding: 0 0 rem(17);
  }

  @include break-min(1200px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    #{$root}_avatarContainer {
      margin-right: rem(16);
      width: 100%;
      display: block;
    }

    #{$root}_avatarContainer-mobile {
      display: none;
    }
  }

  &_informationRow {
    align-items: center;

    .a-text {
      line-height: rem(18);
      overflow-wrap: break-word;
      padding-bottom: rem(20);
    }
  }

  @include break-max(768px) {
    #{$root}_informationRow {
      .a-text {
        padding-bottom: rem(5);
      }
    }
  }

  &_messageContainer {
    padding: rem(20);
    background-color: $white_smoke;
    max-height: rem(274);
    overflow: auto;
    margin-top: rem(22);
  }

  &_closeBtn {
    margin-top: rem(50);
  }

  &_avatarContainer {
    margin: 0 auto rem(16);
    max-width: rem(260);
    max-height: rem(260);
    display: none;

    .a-image-ratio {
      width: 100%;
    }

    &-mobile {
      display: block;
    }
  }
}
