.t-footweartcourse {
  $root: &;
  &_header {
    margin-bottom: rem(30);
    &_date {
      display: flex;
      align-items: center;
      @include desktop-down {
        justify-content: inherit;
      }
      .m-textvalue{
        margin-left: rem(16);
        &:first-child{
          margin-left: 0
        }
      }
    }
  }
  &_title {
    margin-bottom: rem(20);
    color: $brand_blue_dark;
    font-size: rem(24);
    font-weight: bold;
  }
  &_section {
    margin-top: rem(23);
    @include small-mobile{
      margin-top: rem(16);
    }
  }
  &_content {
    &-progress {
      background-color: $topaz_alpha;
      padding: rem(24) rem(20);
      border-radius: rem(5);
      &.completed {
        background-color: $ufoGreen_alpha;
        #{$root}_text-progressing {
          color: $green;
        }
      }
    }
    &-reminder {
      background-color: $oriolesOrange_alpha;
      padding: rem(24) rem(20);
      border-radius: rem(5);
    }
  }
  &_text {
    font-size: rem(18);
    font-weight: bold;
    &-progressing {
      color: $brand_yellow;
      margin-left: rem(10);
    }
    &-error {
      color: $red;
    }
  }

  &_button_submit{
    margin-top: rem(100);
    display: flex;
    justify-content: center;
    @include mobile-down{
      margin-top: rem(40);
    }
    .a-button{
      min-width: rem(200)
    }
  }

  &_notes {
    margin-top: 18px;
    display: flex;
    column-gap: 12px;

    &_text {
      .a-text {
        display: inline;
        margin-right: 4px;
      }
    }

    &_text--red {
      display: inline;
      .a-text {
        color: $red;
      }
    }
  }
}
