.o-courseProcess {
  display: flex;
  border-radius: 5px;
  border: $border_light_gray;
  background-color: $white;
  overflow: auto;
  @include small-mobile {
    background-color: transparent;
    align-items: flex-start;
  }
  &::-webkit-scrollbar {
    height: rem(6);
  }
  &::-webkit-scrollbar-thumb {
    background: $brand_yellow;
  }
  &_item {
    flex-shrink: 0;
    flex-grow: 1;
    margin-left: 3px;
    &:first-of-type{
      margin-left: 0;
    }
    &.only-item{
      @include small-mobile{
        .m-courseProcessItem{
          justify-content: center;
          &::before{
            display: none;
          }
          &_bar{
            display: none;
          }
        }
      }
    }
  }
  @include small-mobile {
    border: none;
    border-radius: 0;
    &_item {
      margin-left: 0;
    }
  }
}