%wrapper {
  width: 100%;
  font-family: $font-muli;
  font-size: rem(12);
  font-style: normal;
  line-height: 1.5;
  color: $white;
  font-stretch: normal;
  letter-spacing: normal;
}

%CustomTransition {
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}


.m-assessment-card {
  width: rem(315);
  height: rem(167);
  cursor: pointer;

  @include mobile-down {
    width: rem(235);
    height: rem(135);
  }

  &:hover &_card {
    margin-top: rem(-20);
    @extend %CustomTransition;

    &_wrap {
      &.not_hover {
        opacity: 0;
      }

      &.hover {
        display: flex;
        width: rem(315);
        height: rem(170);
        transition: all 0.3s ease-in-out;
        flex-direction: column;
        padding-top: rem(16);
        padding-left: rem(10);

        p {
          @extend %wrapper;
          font-weight: normal;
        }

        p:first-child {
          width: auto;
          font-weight: bold;
          font-size: rem(19);

          @include mobile-down {
            font-size: rem(14);
          }
        }

        p:nth-child(3) {
          width: auto;
          font-size: rem(18);
          font-weight: bold;

          @include mobile-down {
            font-size: rem(12);
          }
        }

        p:nth-child(3)::before {
          content: '';
        }

        @include mobile-down {
          width: rem(235);
          height: rem(135);
        }
        opacity: 1;
      }
    }
  }

  margin-right: rem(10);
  position: relative;

  @include media-breakpoint-up(md) {
    margin-right: rem(20);
  }

  &_card {
    @extend %CustomTransition;

    &_wrap {
      &.hover {
        opacity: 0;

        &_text {
          position: absolute;
          width: 76%;
          height: 100%;
          top: 0;
          display: flex;
          transition: all 0.3s ease-in-out;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            @extend %wrapper;
            font-weight: bold;
            line-height: rem(29);
          }
        }
      }

      &.not_hover {
        position: absolute;
        width: 85%;
        top: 0;
        height: rem(167);
        display: flex;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        @include mobile-down {
          height: rem(122);
        }

        p {
          @extend %wrapper;
          font-size: rem(19);
          text-align: center;
          font-weight: bold;
          line-height: rem(29);

          @include mobile-down {
            font-size: rem(14);
          }
        }
      }
    }
  }

  &-line {
    width: rem(50);
    padding: rem(1.5) 0;
    border-radius: rem(10);
    background-color: $white;
    margin: rem(5) 0;
  }
}


