.m-inputPhone{
  position: relative;
  &_errormessage{
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0;
    @include textOverflow(1);
  }
  .PhoneInput{
    height: rem(44);
    &--focus{
      .PhoneInputCountry{
        border-color: $brand_yellow;
      }
    }
  }
  .PhoneInputCountry{
    min-width: rem(64);
    border:1px solid $gray_light;
    justify-content: center;
    border-radius: rem(3);
    box-shadow: 0 3px 10px 0 $shadow_alpha;
  }
  .PhoneInputInput{
    height: 100%;
    border:1px solid $gray_light;
    border-radius: rem(3);
    box-shadow: 0 3px 10px 0 $shadow_alpha;
    outline: none;
    padding: 0 rem(27);
    color: $gray-light-3;
    font-size: rem(16);
    &:focus {
      border-color: $brand_yellow;
    }
  }
  .PhoneInputCountrySelectArrow{
    color: $brand_blue_dark;
  }
  &-error{
    .PhoneInputCountry,
    .PhoneInputInput{
      border-color:$bg_red_dark;
    }
  }
}