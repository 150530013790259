.a-input {
  position: relative;
  $root: &;
  &-account-form{
    #{$root}_input{
      &::placeholder{
        opacity: 0.9;
        color:$cadet;
      }
      background-color: $white;
      color:$cadet;
      height: rem(60);
      box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
    }
  }
  &-border-default{
    #{$root}_input{
      &:focus {
        border-color: $gray_light;
      }
      &::placeholder {
        border-color: $gray_light;
      }
    }
  }
  &-border-none{
    #{$root}_input{
      border: none;
    }
  }
  &-boxshadow-none{
    #{$root}_input{
      box-shadow: none;
    }
  }
  &-login{
    #{$root}_input {
      box-shadow: 0 3px 10px 0 $shadow_alpha;
      color: $gray-light-3;
      padding-top: rem(12);
      padding-bottom: rem(12);
      font-weight: 300;
      background-color: $white;
      &:disabled {
        background-color: #f2f2f2;
      }
    }
  }
  &-disabled-default{
    #{$root}_input{
      &:disabled {
        background-color: $white;
      }
    }
  }
  &-error-below {
    #{$root}_error {
      position: unset;
      text-align: left;
    }
  }
  &-transparent {
    #{$root}_input {
      background-color: $white;
      border-color: $white;
      &:focus {
        border-color: transparent;
      }
    }
  }
  &-no-padding {
    #{$root}_input {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &_input {
    width: 100%;
    border: $border_light_gray;
    position: relative;
    font-size: rem(16);
    line-height: 1.25;
    padding: rem(11) rem(20);
    border-radius: rem(5);
    color: $brand_blue_dark;
    outline: 0;
    -webkit-appearance: none;
    &:focus {
      border-color: $brand_yellow;
    }
    &::placeholder {
      color: $brand_gray;
      opacity: 0.4;
    }
    &:disabled {
      background-color: transparent;
      color: $light_gray_pale;
      &::placeholder {
        color: $brand_gray;
        opacity: 0.4;
      }
    }
  }
  &-error {
    &:not(#{$root}-transparent) {
      #{$root}_input {
        border-color: $orioles_orange;
      }
    }
  }
  &_error {
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0;
    @include textOverflow(1);
  }
  &-search{
    display: flex;
    padding:rem(18) rem(20) rem(18) rem(40);
    box-shadow: 0 3px 10px 0 $alpha_black_2;
    border: solid 1px $brand_yellow;
    border-radius: rem(5);
    background-color: $white;
    @include break-max(768px) {
      padding:rem(12) rem(10);
    }
    input{
      height: rem(20);
      padding-left: 0;
      border: none;
      box-shadow: none;  
    }
  }
  &-cancel{
    #{$root}_input{
      padding-right: rem(40);
    }
    .a-iconButton{
      position: absolute;
      top: 50%;
      right: rem(10);
      transform: translateY(-50%);
    }
  }
  &-disableCustom{
    .a-input_input{
      background: transparent;
      outline: none;
      box-shadow: none;
      border: none;
      color: $cadet;
      padding: 0;
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 500;
      &:disabled{
        background: transparent;
      }
    }
  }
}
.iconEye{
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
