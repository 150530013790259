.m-about-course-list {
    $root: &;
    position: relative;
    overflow: hidden;
    @include break-max(1199px) {
        max-width: 767px;
        margin: 0;
    }
    @include break-max(640px) {
        max-width: 365px;
        margin: 0;
    }
    width: 100%;
    position: relative;
    &_bg {
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        margin-top: -50px;

        @include break-min(1800px) {
            height: 2116px;
        }
        @include break(1440px, 1799px) {
            height: 1416px;
        }
        @include break(1366px, 1439px) {
            height: 1316px;
        }
        @include break(1200px, 1365px) {
            height: 1216px;
        }
        @include break-max(1199px) {
            height: 790px;
        }
        @include break-max(640px) {
            height: 400px;
        }
    }
    &_wrap {
        display: flex;
        align-items: center;
        position: relative;
        padding: 30px 35px;
        color: $brand_white;

        &_subscribe {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 20px;
            width: 203px;
            height: 40px;
            // background: $brand_white;
            // border: 1px solid $brand_yellow;
            // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            a {
                display: flex;
                height: 100%;
                width: 100%;
                align-items: center;
                justify-content: center;
                @include media-breakpoint-down(sm){
                  padding-left: 0;
                  padding-right: 0;
                }
            }
            &_text {
                font-family: $font-muli;
                font-weight: bold;
                font-size: 16px;
                color: #195d76;
                margin-left: 10px;
            }
            .a-button-ovalOutline {
                @include break-max(641px) {
                    font-size: 8px !important;
                }
            }
            @include break-min(1800px) {
                margin-right: 50px;
                margin-top: 250px;
            }
            @include break(1665px, 1800px) {
                margin-right: 210px;
                margin-top: 404px;
            }
            @include break(1200px, 1665px) {
                margin-right: 200px;
                margin-top: 200px;
            }
            @include break(1024px, 1199px) {
                margin-right: 80px;
                margin-top: 95px;
            }
            @include break(768px, 1023px) {
                margin-right: 60px;
                margin-top: 95px;
            }
            @include break-max(767px) {
                margin-right: -30px;
                margin-top: 90px;
            }
            @include break(375px, 766px) {
                margin-right: -36px;
                margin-top: 82px;
            }
            @include break(320px, 374px) {
                margin-right: 5px;
                margin-top: 45px;
            }
            @include break-max(1200px) {
                width: 140px;
                height: 40px;
                &_text {
                    font-size: 12px;
                }
            }
            @include break-max(641px) {
                width: 80px;
                height: 30px;
                margin-right: 10px;
                margin-top: 44px;
                &_text {
                    font-size: 8px;
                    margin-left: 5px;
                }
            }
            &:hover {
                opacity: 0.9;
            }
        }
        @include break-min(1366px) {
            padding: 50px 0;
        }
        @include break-max(1199px) {
            padding: 0 5px;
        }

        &_content {
            width: 100%;
            position: relative;
            max-width: 57%;
            @include break(1439px, 1800px) {
                margin-top: 150px;
            }
            @include break-min(1440px) {
                max-width: 60%;
                padding-top: 180px;
            }
            @include break(1366px, 1439px) {
                padding-top: 310px;
            }
            @include break(1200px, 1365px) {
                padding-top: 80px;
            }
            @include break-max(1199px) {
                padding-top: 80px;
            }
            @include break-max(640px) {
                padding-top: rem(40);
            }
            &_title {
                width: 100%;
                h2 {
                    @include break-min(1366px) {
                        max-width: 474px;
                        font-size: 50px;
                        line-height: 64px;
                    }
                    @include break(1200px, 1365px) {
                        font-size: 24px;
                        line-height: 30px;
                        margin-top: 72px;
                    }
                    @include break-max(1199px) {
                        font-size: 24px;
                        line-height: 30px;
                        margin-top: 72px;
                    }
                    @include mobile-down {
                        font-size: rem(16);
                        line-height: rem(24);
                        margin-top: 0;
                    }
                }
            }
            &_des {
                padding: 0;
                position: relative;
                @include break-min(1366px) {
                    padding: 30px;
                    padding-left:0;
                }
                &_text {
                    max-height: rem(145);
                    transition: all 0.2s ease-in-out;
                    overflow: auto;
                    overflow-x: hidden;
                    font-family: $font-muli;
                    * {
                        color: $brand_gray;
                        // fontsize set fixed because not CKeditor edit
                        @include mobile-down {
                            font-size: rem(14)!important;
                            line-height: rem(18)!important;
                        }
                    }
                    @include break-min(1920px) {
                        max-height: 700px;
                    }
                    @include break(1599px, 1920px) {
                        max-height: 400px;
                    }
                    @include break(1366px, 1599px) {
                        max-height: 300px;
                    }
                    @include break(1200px, 1365px) {
                        max-height: 200px;
                        li {
                            font-size: 14px;
                            line-height: 22px;
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                    @include break-max(1199px) {
                        max-height: 200px;
                        li {
                            font-size: 16px;
                            line-height: 24px;
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                    @include mobile-down {
                        max-height: rem(88);
                        li {
                            font-size: rem(12);
                            line-height: rem(21);
                            &:before {
                                font-size: rem(10);
                            }
                            a {
                                font-size: rem(8);
                            }
                        }
                        .m-list {
                            li {
                                font-size: rem(12);
                            }
                        }
                    }
                }
            }
            &_button {
                display: flex;
                justify-content: center;
                @include break-min(1366px) {
                    justify-content: flex-end;
                }
                @include break(1200px, 1365px) {
                    margin-right: 40px;
                }
            }
        }
    }
    .icon_mobile {
        .a-icon {
            display: none;
        }
    }
    .expaned {
        #{$root}_wrap_content {
            &:before {
                content: '';
                position: absolute;
                width: 100vh;
                height: 1000vh;
                background-color: rgb(34, 34, 34);
                opacity: 0.8;
                z-index: -1;
            }
            @include break-max(640px) {
                display: flex;
                flex-direction: column;
                color: $brand_white;
                border-radius: 5px;
                border: $border_yellow;
                background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    color-stop(0, #0f2027),
                    color-stop(0.477, #203a43),
                    color-stop(1, #2c5364)
                );
                background: -webkit-linear-gradient(#0f2027, #203a43, #2c5364);
                background: -moz-linear-gradient(#0f2027, #203a43, #2c5364);
                background: -o-linear-gradient(#0f2027, #203a43, #2c5364);
                background: -ms-linear-gradient(#0f2027, #203a43, #2c5364);
                background: linear-gradient(#0f2027, #203a43, #2c5364);
                padding: 15px;
                position: fixed;
                max-width: 325px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 999999;
                max-height: 428px;
                display: flex;
                align-items: center;
                justify-content: center;
                h2 {
                    text-align: center;
                    color: $brand_white;
                }

                .a-text {
                    font-size: 10px;
                    line-height: 15px;
                    color: $brand_white;
                }
                .a-icon {
                    width: 26px;
                    height: 26px;
                }
            }

            &_title {
                font-size: 16px;
                line-height: 24px;
                h2 {
                    font-size: 16px;
                    line-height: 24px;
                }
                @include break-min(1366px) {
                    font-size: 32px;
                    line-height: 39px;
                    h2 {
                        font-size: 32px;
                        line-height: 39px;
                    }
                }
            }
            &_des {
                margin-bottom: 10px;
                @include break-min(1366px) {
                    margin-bottom: 0;
                }
                &_text {
                    max-height: 300px;
                    overflow: auto;
                    overflow-x: hidden;
                    @include break-min(1366px) {
                        max-height: 400px;
                    }
                    @include break-max(1199px) {
                        max-height: 200px;
                    }
                    @include break-max(640px) {
                        max-height: 300px;
                    }
                }
            }
        }
        .a-button {
            @include break-max(640px) {
                display: none;
            }
        }
        .icon_mobile {
            .a-icon {
                display: none;
            }
            @include break-max(640px) {
                display: inline-block;
                text-align: center;
                .a-icon {
                    display: block;
                }
            }
        }
    }

    .a-button {
        @include break-max(1199px) {
            margin: auto;
            font-size: 14px;
            .a-icon-up {
                width: 15px;
                height: 15px;
            }
            .a-icon-down {
                width: 15px;
                height: 15px;
            }
        }
    }
    .a-button-link {
        @include break(1200px, 1365px) {
            font-size: 18px;
        }
        @include break-max(1199px) {
            margin: 0;
            font-size: 14px;
            padding-right: 0;
            .a-icon-up {
                width: 15px;
                height: 15px;
            }
            .a-icon-down {
                width: 15px;
                height: 15px;
            }
        }
    }
    &_button {
        .container {
            position: relative;
        }
        &_wrap {
            @include break-min(768px) {
                position: absolute;
                left: 15px;
                z-index: 100;
                bottom: 100px;
            }
        }
    }
}

