.m-participant {
  display: flex;
  &_image {
    .a-image-ratio {
      border-radius: rem(5);
      border: 1px solid $brand_yellow;
      img {
        border-radius: rem(5);
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &_info {
    flex: auto;
    padding-left: rem(16);
  }
  &_item {
    margin-bottom: rem(24);
  }
}
