@mixin fontBase ($fontsize: 12) {
  color: $light_gray_pale;
  font-family: $font;
  font-weight: normal;
  font-size: $fontsize * 1px;
}

.m-courseProcessItem {
  $root: &;
  padding: rem(14);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  @include break(576px,1023px){
   padding-left: rem(5);
   padding-right: rem(5);
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 3px solid $isabelline;
  }
  &_circle {
    width: rem(60);
    height: rem(60);
    border-radius: 50%;
    border: 1px solid $light_gray_pale;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white_smoke;
    font-size: rem(16);
    color: $light_gray_pale;
    font-family: $font;
    font-weight: bold;
    box-shadow: 0 0 5px 0 $shadow_black;
    @include break(576px,1023px){
      width: rem(40);
      height: rem(40);
    }
    #{$root}-active & {
      background-color: $white;
      border: 2px solid $brand_yellow;
      &:after {
        content: '';
        width: rem(24);
        height: rem(24);
        background: url("~images/icons/icon_active_process.svg") no-repeat top left / 100% auto;
      }
    }
    #{$root}-success & {
      background-image: linear-gradient(to bottom, $bg_blue_light, $persian_green);
      @include small-mobile{
        background-image: none;
        background-color: $green;
      }
      border: none;
      position: relative;
      &:after {
        content: '';
        width: rem(30);
        height: rem(20);
        @include break(576px,1023px){
          width: rem(20);
          height: rem(15);
         }
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("~images/icons/icon_checked.svg") no-repeat top left / 100% auto;
      }
    }
  }
  &_container {
    display: flex;
    align-items: center;
    #{$root}-success &, #{$root}-active & {
      cursor: pointer;
    }
  }
  &_wrapper {
    margin-left: rem(10);
    display: flex;
    flex-direction: column;
    align-items: center;
    @include break(576px,1023px){
      margin-left: rem(5);
    }
  }
  &_phase {
    @include fontBase(14);
    #{$root}-active & {
      color: $brand_yellow;
      font-weight: bold;
    }
    #{$root}-success & {
      color: $light-gray;
      font-weight: bold;
      font-size: rem(16);
      @include break-max(374px){
        font-size: rem(12);
      }
    }
  }
  &_time {
    @include fontBase();
    @include break-max(374px){
      font-size: rem(10);
    }
    #{$root}-active & {
      color: $rick-black;
    }
    #{$root}-success & {
      color: $light-gray;
    }
  }
  &_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-image: linear-gradient(to right, $bg_blue_light, $persian_green);
    #{$root}-success & {
      width: 100%;
    }
  }
  @include small-mobile {
    justify-content: unset;
    padding: 0;
    &:before {
      top: rem(16);
      left: rem(45);
      bottom: unset;
      width: calc(100% - 25px);
    }
    &_circle {
      width: rem(32);
      height: rem(32);
      font-size: rem(18);
      #{$root}-success & {
        &:after {
          width: rem(17);
          height: rem(12);
        }
      }
    }
    &_container {
      flex-direction: column;
      justify-content: center;
      z-index: 3;
    }
    &_wrapper {
      margin-left: 0;
      margin-top: rem(8);
    }
    &_phase {
      font-size: rem(16);

      #{$root}-active & {
        font-size: inherit;
        @include break-max(374px){
          font-size: rem(12);
        }
      }
    }
    &_time {
      margin-top: rem(8);
    }
    &_bar {
      top: rem(16);
      left: rem(45);
      z-index: 2;
      #{$root}-success & {
        width: calc(100% - 25px);
      }
    }
  }
}