.m-contact {
  $root: &;
  position: relative;
  overflow: hidden;
  font-family: $font-muli;
  .bg-puzzles {
    position: relative;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    @include break-max(1200px) {
      background-image: url("~assets/images/bg_puzzles_mobile.png") !important;
    }
  }
  &_wrap {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      width: 100%;
      height: 100%;
      content: "";
      background-image: url("~assets/images/bg_gradient_down.png");
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &_item {
    width: 100%;
    position: relative;
    &_wrap {
      display: flex;
      align-items: center;
      max-height: 100%;
      position: relative;
      padding: 30px 35px;
      color: $brand_white;
      min-height: 190px;
      @include break-min(768px) {
        padding: 50px 0;
        min-height: 975px;
      }
      &_content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        &_left {
          width: 100%;
          position: relative;
          @include break-min(1440px) {
            max-width: 630px;
          }
          @include break(1336px, 1439px) {
            max-width: 500px;
            h2 {
              font-size: 60px;
            }
          }
          @include break(767px, 1200px) {
            max-width: 763px;
            margin: auto;
          }
          &_title {
            width: 100%;
          }
          &_des {
            margin-top: 20px;
            padding: 0;
            position: relative;
            &_form {
              .form-group {
                background-color: $brand_white;
                padding: {
                  top: 20px;
                  bottom: 20px;
                  right: 30px;
                  left: 30px;
                }
                border-radius: 8px;
                margin-bottom: 1px;
                .form-label {
                  color: $brand_gray;
                  font-weight: 600;
                }
                .form-control {
                  padding: initial;
                  &:focus {
                    border-style: none;
                    box-shadow: none;
                  }
                  border-style: none;
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                input[type="number"] {
                  -moz-appearance: textfield;
                }
                @include small-mobile {
                  padding-top: rem(5);
                  padding-bottom: rem(5);
                  margin-bottom: rem(10);
                }
                .m-textarea_input {
                  font-size: rem(16);
                }
              }
            }
            &_button {
              margin-top: 40px;
              float: right;
              @include break-max(1335px) {
                width: 100%;
                .a-button {
                  margin: auto;
                }
              }
              @include small-mobile {
                margin-top: 0;
                .a-button {
                  background-size: rem(200);
                  padding:0;
                width: rem(200);
                height: rem(124);
                }
              }
            }
          }
        }
        &_right {
          position: relative;
          display: none;
          @include break-min(768px) {
            display: inline;
            &_wrap {
              position: absolute;
              right: 0;
              margin-right: -5%;
              top: 50%;
              transform: translateY(-50%);
              &_bg {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 147px;
                height: 147px;
                @include break-min(768px) {
                  width: 647px;
                  height: 647px;
                }
              }
            }
          }
        }
      }
    }
  }
  .icon_mobile {
    .a-icon {
      display: none;
    }
  }
  .expaned {
    #{$root}_item_wrap_content_left {
      @include break-max(767px) {
        border-radius: 5px;
        border: $border_yellow;
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(0, #0f2027),
          color-stop(0.477, #203a43),
          color-stop(1, #2c5364)
        );
        background: -webkit-linear-gradient(#0f2027, #203a43, #2c5364);
        background: -moz-linear-gradient(#0f2027, #203a43, #2c5364);
        background: -o-linear-gradient(#0f2027, #203a43, #2c5364);
        background: -ms-linear-gradient(#0f2027, #203a43, #2c5364);
        background: linear-gradient(#0f2027, #203a43, #2c5364);
        padding: 15px;
        position: fixed;
        max-width: 325px;
        margin: auto;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
      &_title {
        font-size: 16px;
        line-height: 24px;
        h2 {
          font-size: 16px;
          line-height: 24px;
        }
        @include break-min(768px) {
          font-size: 32px;
          line-height: 39px;
          h2 {
            font-size: 32px;
            line-height: 39px;
          }
        }
      }
      &_des {
        margin-bottom: 10px;
        @include break-min(768px) {
          margin-bottom: 0;
        }
        &_text {
          max-height: 100px;
          overflow: auto;
          overflow-x: hidden;
          @include break-min(768px) {
            max-height: 445px;
          }
        }
      }
    }
    .a-button {
      @include break-max(767px) {
        display: none;
      }
    }
    .icon_mobile {
      .a-icon {
        display: none;
      }
      @include break-max(768px) {
        display: inline-block;
        text-align: center;
        .a-icon {
          display: block;
        }
      }
    }
  }
  .a-button {
    @include break-max(767px) {
      margin: auto;
      font-size: 14px;
      .a-icon-up {
        width: 15px;
        height: 15px;
      }
      .a-icon-down {
        width: 15px;
        height: 15px;
      }
    }
  }
  .a-link-button {
    @include break-max(767px) {
      margin: auto;
      font-size: 14px;
      .a-icon-up {
        width: 15px;
        height: 15px;
      }
      .a-icon-down {
        width: 15px;
        height: 15px;
      }
    }
  }
}
