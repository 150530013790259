.a-returnButton {
  display: flex;
  align-items: center;
  padding: rem(18) rem(18) rem(18) rem(20);
  object-fit: contain;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: rem(28);
  border: $border_yellow;
  background-color: $white;
  font-family: $font-muli;
  font-size: rem(16);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #195d76;
  &:focus {
    outline: none;
  }
  &-wrap-icon {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
}