.p-success{
  padding: rem(100) 0;
  &_img{
    max-width: rem(200);
    margin: 0 auto;
    @include small-mobile{
      max-width: rem(100);
    }
  }
  &_info{
    margin-top: rem(30);
  }
  &_button{
    margin-top: rem(30);
    display: flex;
    justify-content: center;
  }
  &_description{
    margin-top: rem(50);
  }
}