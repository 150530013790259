.p-footwearcourse {
  $root: &;
  font-family: $font-muli;
  &_main {
    overflow: hidden;
    margin-bottom: rem(50);
    @include break-max(768px) {
      margin-top: 0;
    }
    @include mobile-down {
      margin-bottom: rem(24);
    }
    #{$root}_row {
      @include desktop-up {
        position: relative;
        // width: 100%;
        height: 100%;
        min-height: rem(940);
        flex-direction: column;
      }
    }
    #{$root}_left {
      position: relative;
      padding-top: rem(50);
      height: 100%;
      @include desktop-down {
        padding-bottom: rem(30);
      }
      @include mobile-down {
        padding-top: rem(24);
        padding-bottom: rem(16);
      }
      #{$root}_icon {
        position: absolute;
        top: rem(50);
        left: 0;
        .a-icon {
          width: rem(20);
          height: rem(38);
        }
        @include mobile-down {
          top: rem(25);
          .a-icon {
            width: rem(10);
            height: rem(15);
          }
        }
      }
      #{$root}_left-heading {
        @include small-mobile {
          .a-heading {
            font-size: rem(24);
          }
        }
      }
      #{$root}_assessment {
        margin: rem(30) 0 rem(10);

        &_card{
          display: flex;
          justify-content: center;

          .m-assessment-sheet{
            &_card.available{
              background-image: url('~images/m-assessment-sheet/last-available-sharp.png');
            }
            &_card_wrap{
              &.available.hover{
                padding-left: rem(50);
              }
            }
          }
        }

        .a-text {
          @include mobile-down {
            font-size: rem(18);
          }
        }
        @include desktop-down {
          margin-bottom: rem(43);
        }
        @include mobile-down {
          margin-bottom: 0;
        }
        @include break-min(768px){
          &.clear-margin{
            .slick-track {
              margin-left: unset;
              cursor: pointer;
            }
          }
        }
      }
    }
    #{$root}_col-end {
      margin-top: rem(20);
      @include desktop-down {
        margin-top: rem(24);
      }
      @include desktop-up {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      #{$root}_claim {
        margin-bottom: rem(20);
        @include mobile-down {
          margin-bottom: rem(16);
          .a-text {
            font-size: rem(18);
            line-height: rem(23);
          }
        }
        #{$root}_number {
          font-weight: bold;
        }
      }
      height: 100%;
      #{$root}_course-folder {
        margin-top: rem(17);
        @include desktop-up {
          position: relative;
          width: 100%;
          flex-grow: 1;
          .t-courseFolder {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            .t-courseFolder_custom {
              flex-grow: 1;
              overflow-y: auto;
              .m-panel {
                position: relative;
                height: 100%;
                overflow-y: auto;
              }
              .t-courseFolder_wrapper {
                max-height: unset;
                height: 100%;
                overflow-y: auto;
              }
            }
          }
        }
      }
    }
    #{$root}_col-right {
      @include desktop-up {
        position: absolute;
        height: 100%;
        right: 0;
      }
      #{$root}_right {
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 $alpha_black_16;
        border: solid 1px $brand_yellow;
        background-color: $white;
        padding: rem(20);
        padding-top: rem(30);
        @include mobile-down {
          .t-footweartcourse {
            &_header {
              margin-bottom: rem(16);
              .a-text {
                font-size: rem(14);
                span {
                  font-size: rem(12);
                }
              }
            }
            &_title {
              font-size: rem(18);
            }
            &_content-reminder {
              font-size: rem(14);
              .t-footweartcourse_text {
                font-size: rem(24);
              }
            }
          }
        }
      }
      .t-footweartcourse_content {
        .m-avatar-in-folder {
          @include break-max(1400px) {
            width: rem(150);
            height: rem(150);
          }
          @include mobile-down {
            width: rem(125);
          }
          &-first-character{
            position: inherit;
            width: 100%;
            height: 100%;
          }
        }
        .m-participant_info {
          @include mobile-down {
            padding-left: rem(9);
          }
          .m-participant_item {
            @include break-max(1400px) {
              margin-bottom: rem(10);
            }
            @include mobile-down {
              .a-text {
                font-size: rem(17);
                span {
                  font-size: rem(16);
                }
              }
            }
          }
        }
      }
    }
  }
}
.o-siteLayout_noPadding{
  max-width: rem(1610);
  margin: auto;
}