.m-textarea {
  $root: &;
  &_input {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    font-size: 12px;
    font-family: $font;
    font-weight: normal;
    color: $cadet;
    border: $border_light_gray;
    border-radius: 5px;
    box-shadow: 0 3px 40px 0 $shadow_black;
    outline: none;
    resize: none;
    &::placeholder{
      opacity: 0.4;
    }
    ::-webkit-resizer {
      display: none;
    }

    &-error {
      border-color: $orioles_orange;
    }
  }

  &_required {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &-text,&-min {
      font-size: 12px;
      font-weight: 400;
      font-family: $font;
      color: $brand_gray;
    }
    &-text {
      display: flex;
      &::before {
        content: '*';
        display: block;
      }
    }
  }

  &_error {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &-text {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      font-family: $font;
      color: $orioles_orange;
    }
  }
  &-transparent {
    #{$root}_input {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      &:focus {
        border-color: transparent;
      }
    }
  }
  &-no-padding {
    #{$root}_input {
      padding-left: 0;
      padding-right: 0;
    }
  }
}