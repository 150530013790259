.p-expertcourse {
  $root: &;
  font-family: $font-muli;

  &_courseList {
    margin: 0 rem(-45);
  }

  &_category_course {
    margin-bottom: rem(60);

    .a-text {
      margin-bottom: rem(20);
    }
  }

  &_cardContainer {
    .o-cardcourse {
      max-width: rem(260);
      margin: auto;
    }
  }

  &_viewmore {
    display: flex;
    justify-content: center;
    padding: rem(40) 0 rem(10);
    .a-button {
      width: rem(200);
    }
  }

  &_search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: rem(39.5);
    padding-bottom: rem(30);

    .a-text {
      display: inline-block;
      @include small-mobile{
        font-size: rem(24);
      }
      @include break-max(374px){
        font-size: rem(18);
      }
    }

    .a-input {
      background-color: $white;
      width: rem(398);
    }
  }

  &_emptytext {
    padding-top: rem(123);
    padding-bottom: rem(266);
    .a-text {
      font-size: rem(50);
      line-height: rem(63);
      color: $light_gray_pale;
    }
  }

  &_banner {
    padding-top: rem(118);
    position: relative;
    z-index: 1;
    @include break-max(768px) {
      padding: rem(15) 0;
    }
  }

  @include break-max(768px) {
    #{$root}_cardContainer {
      padding-bottom: rem(32);

      .o-cardcourse {
        height: rem(400);
        max-height: none;
        .a-button{
          @include tablet-down {
            font-size: rem(12);
            line-height: rem(16);
          }
        }
        @include small-mobile{
         height: auto;
        }
      }
    }
    #{$root}_searchInput {
      .a-input{
        width: auto;
        .a-input_input{
          display: none;
        }
      }
    }
    &_search.active {
      .a-text{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: nowrap;
        overflow: hidden;
      }
      #{$root}_searchInput {
        width: 90%;
        .a-input{
          width: 100%;
          padding-left: rem(20);
          .a-input_input{
            display: block;
          }
        }
      }   
    }

    #{$root}_cardContainer {
      .a-button {
        font-size: rem(16);
        line-height: rem(33);
      }

      .a-heading {
        font-size: rem(24);
        line-height: rem(30);
      }
    }
  }
  .m-breadcrumb {
    padding-top: rem(20);
  }
}
