/* SECTION */
.o-section {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  &-nopadding {
    padding-top: 0;
    padding-bottom: 0;
  }
  &-container {
    @media (min-width: 576px) {
      max-width: rem(540);
    }

    @media (min-width: 1024px) {
      max-width: rem(720);
    }

    @media (min-width: 992px) {
      max-width: rem(960);
    }

    @media (min-width: 1200px) {
      max-width: rem(1140);
    }

    @media (min-width: 1280px) {
      max-width: rem(1270);
    }

    @media (min-width: 1480px) {
      max-width: rem(1400);
    }
  }

  &-primary {
    background-color: #0097e0;
  }
  &-space {
    &-default {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-xs {
      padding-top: rem(25);
      padding-bottom: rem(25);

      @include break-min(1024px) {
        padding-top: rem(45);
        padding-bottom: rem(45);
      }
    }

    &-sm {
      padding-top: rem(30);
      padding-bottom: rem(30);

      @include break-min(1024px) {
        padding-top: rem(60);
        padding-bottom: rem(60);
      }
    }

    &-md {
      padding-top: rem(35);
      padding-bottom: rem(35);

      @include break-min(1024px) {
        padding-top: rem(65);
        padding-bottom: rem(65);
      }
    }

    &-xl {
      padding-top: rem(10);
      padding-bottom: rem(10);

      @include break-min(1024px) {
        padding-top: rem(180);
        padding-bottom: rem(180);
      }
    }
    &-lg {
      padding-top: rem(10);
      padding-bottom: rem(10);

      @include break-min(1024px) {
        padding-top: rem(240);
        padding-bottom: rem(180);
      }
    }
    &-xs-t {
      padding-top: 2rem;

      @include break-min(1024px) {
        padding-top: 3rem;
      }
    }

    &-sm-t {
      padding-top: rem(30);

      @include break-min(1024px) {
        padding-top: rem(60);
      }
    }

    &-md-t {
      padding-top: rem(35);
      @include break-min(1024px) {
        padding-top: rem(65);
      }
    }

    &-xl-t {
      padding-top: rem(90);

      @include break-min(1024px) {
        padding-top: rem(180);
      }
    }

    &-xs-t {
      padding-top: 2rem;

      @include break-min(1024px) {
        padding-top: 3rem;
      }
    }

    &-sm-t {
      padding-top: 30px;

      @include break-min(1024px) {
        padding-top: 60px;
      }
    }

    &-md-t {
      padding-top: rem(35);
      @include break-min(1024px) {
        padding-top: rem(65);
      }
    }

    &-xl-t {
      padding-top: rem(90);

      @include break-min(1024px) {
        padding-top: rem(180);
      }
    }

    &-xs-b {
      padding-bottom: 2rem;

      @include break-min(1024px) {
        padding-bottom: 3rem;
      }
    }

    &-sm-b {
      padding-bottom: rem(30);

      @include break-min(1024px) {
        padding-bottom: rem(60);
      }
    }

    &-md-b {
      padding-bottom: rem(35);
      @include break-min(1024px) {
        padding-bottom: rem(65);
      }
    }

    &-xl-b {
      padding-bottom: rem(90);

      @include break-min(1024px) {
        padding-bottom: rem(180);
      }
    }
  }

  &-nopadding {
    padding: 0;
  }
}
