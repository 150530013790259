.m-courseDocument {
    &-container {
        text-align: center;
        padding: 50px 80px;
        background: url(~images/course-support/bg-course-download-section.jpg)
            no-repeat;
        background-size: cover;
        transition: 0.2s;
        overflow: hidden;
        @include break-max(768px) {
            padding: 25px 40px;
        }
        @include break-max(480px) {
            padding: 25px;
        }
        @include break-max(400px) {
            padding: 5px;
        }
    }
    &-heading {
        margin-top: 15px;
    }
    &-hidden {
        visibility: hidden;
        height: 0;
        padding: 0;
        border: none;
    }
    &-heading {
        font-family: $font-muli;
        font-weight: bold;
        font-size: 36px;
        line-height: 31px;
        color: $brand_blue_dark;
        @include break-max(768px) {
            text-align: center;
            font-size: 25px;
        }
        @include break-max(480px) {
            font-size: 16px;
        }
    }
    &-list {
        position: relative;
        overflow: hidden;
        padding-left: 58px;
        padding-right: 58px;
        margin-top: 80px;
        justify-content: center;
        @include break-max(546px) {
            padding: 0;
            margin-top: 45px;
        }
        .slick-arrow {
            display: none !important;
        }
        .slick-slider {
            @include break-max(955px) {
                margin-top: -20px;
                margin-left: -20px;
            }
        }
        .slick-slide {
            padding-left: 15px;
            div {
                display: flex;
            }
        }
        .slick-dots {
            position: relative;
            bottom: 0;
            margin-top: 50px;
            display: flex !important;
            justify-content: center;
            li {
                display: block;
                width: unset;
                height: unset;
                button {
                    width: 10px;
                    height: 10px;
                    background: $brand_blue_dark;
                    opacity: 1;
                    transition: 0.2s;
                    border-radius: 50%;
                    &:before {
                        content: none;
                    }
                }
                &.slick-active {
                    button {
                        width: 30px;
                        border-radius: 5px;
                        background: linear-gradient(
                            90deg,
                            #ff5f6d 0%,
                            #ffc371 100%
                        );
                    }
                }
            }
        }
    }
    &-item {
        margin-left: 20px;
        &:first-of-type {
            margin-left: 0;
        }
        &:focus {
            outline: none;
        }
        @include break-max(955px) {
            display: flex !important;
            justify-content: center;
        }
    }
    &-wrapper {
        display: block !important;
        position: relative;
        height: 455px;
        text-align: center;
        padding: 125px 15px;
        border-radius: 10px;
        background: url(~images/course-support/bg-document-download.png)
            no-repeat;
        background-size: contain;
        background-position: center;
        transition: all 0.2s;

        @include break-max(1655px) {
            padding-left: 16px;
            padding-right: 16px;
        }
        @include break-max(1489px) {
            padding-top: 110px;
            padding-bottom: 110px;
        }
        @include break-max(1365px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        @include break-max(1078px) {
            padding-top: 119px;
            padding-bottom: 119px;
        }
        @include break-max(1030px) {
            padding-left: 60px;
            padding-right: 60px;
        }
        @include break-max(955px) {
            height: 440px;
            margin-top: 20px;
            padding: 101px 26px;
        }
        @include break-max(768px) {
            width: 155px;
            height: 255px;
            padding: 60px 8px;
        }
        &:hover {
            background-image: url(~images/course-support/bg-document-download-active.png);

            .m-courseDocument {
                &-button {
                    visibility: visible;
                }
                &-title,
                &-icon {
                    color: $brand_white;
                }
                &-line {
                    background-color: $brand_white;
                }
            }
        }
    }

    &-header {
        display: flex;
        align-items: center;
    }
    &-icon {
        width: 40px;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        transition: 0.2s;
        color: $brand_blue_dark;
        @include break-max(768px) {
            width: 25px;

            font-size: 16px;
            line-height: 20px;
            font-weight: normal;
        }
    }
    &-line {
        height: 50px;
        width: 3px;
        border-radius: 1.5px;
        background: $brand_blue_dark;
        transition: 0.2s;
        flex-shrink: 0;
        @include break-max(768px) {
            height: 28px;
            width: 1.2px;
        }
    }
    &-title {
        flex-grow: 1;
        font-weight: bold;
        line-height: 30px;
        font-size: 20px;
        max-height: 65px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: $brand_blue_dark;
        margin-left: 10px;
        transition: 0.2s;
        @include break-max(768px) {
            font-size: 12px;
            line-height: 16px;
            font-weight: normal;
        }
    }
    &-button {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 125px;
        visibility: hidden;
        @include break-max(768px) {
            bottom: 68px;
        }
        button,
        .a-button {
            margin: auto;
            width: 158px;
            height: 48px;
            max-height: 48px;
            font-size: 20px;
            border: $border_yellow;
            padding-left: 0;
            padding-right: 0;
            transition: unset;
            a {
                color: $brand_white;
                &:hover {
                    text-decoration: unset;
                }
            }
            @include break-max(768px) {
                width: 119px;
                height: 23px;
                font-size: 9px;
            }
        }
    }
    &-control {
        position: absolute;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
        &-prev {
            cursor: pointer;
            width: 25px;
            height: 45px;
            background: url(~images/icons/icon_prev.svg) no-repeat center;
        }
        &-next {
            cursor: pointer;
            width: 25px;
            height: 45px;
            background: url(~images/icons/icon_next.svg) no-repeat center;
        }
        @include break-max(955px) {
            display: none;
        }
    }
    &-close {
        display: inline-block;
        cursor: pointer;
        margin-top: 50px;
        @include mobile-down {
            margin-top: rem(16);
        }
    }
    &_notify{
        margin-top: rem(30);
        @include mobile-down {
            margin-top: rem(16);
        }
    }
}
.m-courseDocument-item_bg-red {
    .m-courseDocument-wrapper {
        &:hover {
            background-image: url(~images/course-support/bg-document-download-active.png);
        }
    }
}
.m-courseDocument-item_bg-electric-blue {
    .m-courseDocument-wrapper {
        &:hover {
            background-image: url(~images/course-support/bg-document-download-medium-electric-blue-active.png);
        }
    }
}
.m-courseDocument-item_bg-verdigris {
    .m-courseDocument-wrapper {
        &:hover {
            background-image: url(~images/course-support/bg-document-download-verdigris-active.png);
        }
    }
}
.m-courseDocument-item_bg-yellow {
    .m-courseDocument-wrapper {
        &:hover {
            background-image: url(~images/course-support/bg-document-download-yellow-active.png);
        }
    }
}
