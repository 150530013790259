$linear: 90deg, rgba(15, 32, 39, 0.6) 0%, rgba(32, 58, 67, 0.6) 47.7%,
  rgba(44, 83, 100, 0.6) 100%;
$linear-blue-dark-to-blue-light: 93deg, #0f2027, #203a43 48%, #2c5364;

.o-header {
  font-family: $font-muli;
  background: linear-gradient(90deg, #0f2027 0%, #203a43 47.7%, #2c5364 100%);
  transition: 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-999;
  &-hidden {
    visibility: hidden;
    transform: translateY(-100%);
    @include break-max(768px) {
      transform: unset;
      visibility: visible;
    }
  }
  &.gradient-blue-dark-to-blue-light {
    background: linear-gradient($linear-blue-dark-to-blue-light);
  }
  @include break-min(768px) {
    background: linear-gradient($linear);
  }
  &-top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(96);
    @include break-max(767px) {
      justify-content: center;
    }
    &-left-logo{
      display: flex;
      align-items: center;
      .a-heading{
        margin-left: rem(10);
        .special{
          font-size: rem(30);
        }
        @include tablet-desktop {
          font-size: rem(24);
        }
        @include desktop {
          display: none;
        }
        @include break-max(767px) {
          display: none;
        }
      }
    }
    &-right {
      &-option {
        .menu-button {
          position: absolute;
          margin-top: rem(-10);
          right: rem(30);
          background: none;
          border: none;
          width: rem(20);
          height: rem(20);
          padding: 0;
          transition: 0.2s;
          z-index: $z-index-999;
          cursor: pointer;
          &--main {
            .a-icon-x-white {
              display: none;
            }
            &.collapsed {
              .a-icon-x-white {
                display: block;
              }
              .a-icon-menu-mb {
                display: none;
              }
            }
          }
        }
        @include break-min(992px) {
          display: none;
        }
      }
    }
  }
}
