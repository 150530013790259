body{
  background-color: transparent;
}
.t-layout {
  overflow: hidden;
  max-width: 1920px;
  margin: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: auto;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  margin: auto;
  border: 0px none $brand_white;
  border-radius: 50px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, $bg_red), color-stop(1, $bg_red_light));
  background: linear-gradient($bg_red, $bg_red_light);
  @include break-min(768px) {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, $bg_blue_dark),
      color-stop(1, $bg_blue_light)
    );
    background: linear-gradient($bg_blue_dark, $bg_blue, $bg_blue_light);
  }
}

::-webkit-scrollbar-thumb:hover {
  background: $bg_red_light;
}
::-webkit-scrollbar-thumb:active {
  background: $bg_yellow;
}
::-webkit-scrollbar-track {
  background: $brand_white;
  border: 0px none $brand_white;
  border-radius: 50px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

input:-internal-autofill-selected {
  background-color: $brand_white !important;
  color: $brand_gray !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $brand_white inset !important;
  -webkit-text-fill-color: $brand_gray;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $brand_white inset !important;
  -webkit-text-fill-color: $brand_gray;
}
button {
  &:focus {
    outline: none;
  }
}
a {
  &:focus {
    outline: none;
  }
}

input {
  // background:transparent !important;
  border-radius: 0;
  border: 0;
  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
}

@include break-min(1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1640px;
  }
}


.wraper {
  max-width: 536px;
  margin: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0 50px 0;
  @include break-min(456px) {
    padding: 50px 0 100px 0;
  }
  .a-text {
    padding:0 15px;
    margin-top:10px;
    @include break-min(456px) {
      margin-top:30px;
    }
  }
  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_button {
    margin-top:20px;
    position: relative;
    &_box {
      position: relative;
    }
    @include break-min(456px) {
      margin-top:40px;
    }
    a {
      font-size: 16px;
      font-family:$font;
      color:$brand_blue_dark;
      font-weight: bold;
      text-decoration: underline;
      @include break-min(768px) {
        font-size: 40px;
      }    
    }
    .a-icon {
      margin-left:20px;
   
    } 
   
    &_xs {
      justify-content: flex-start;
      &-link {
        font-size: 16px;
        color:$brand_blue_dark;
        font-weight: normal;
        text-decoration: none;
        position: relative;
        display: inline-flex;
        cursor: pointer;
        @include break-min(768px) {
          font-size: 32px;
        }
      }
      .a-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -40px;
        @include break-min(768px) {
          right: -60px;
          margin-top:4px;
        }
      }
    }   
  }
 
}