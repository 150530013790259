.p-overview { 
  $root: &;
  width: 100%;
  height: 100%;
  font-family: $font-muli;

  &_banner {
    margin-top: rem(70);
    @include break-max(767px) {
      margin-top: rem(40);
    }
  }

  &_category_item {
    margin-bottom: rem(30);
  }

  &_wrap_card_course {
    margin-bottom: rem(40);
  }

  @include break-max(767px) {
    padding-top: 0;
    &_wrap_back_button {
      margin-bottom: rem(20);
      .a-returnButton {
        padding: rem(5) rem(10);
        font-size: rem(12);
      }
    }

    &_wrap_card_course {
      padding-bottom: rem(42);
    }
  }

  @include break-max(767px) {
    padding-top: 0;
    &_wrap_back_button {
      margin-bottom: rem(20);
      .a-returnButton {
        padding: rem(5) rem(10);
        font-size: rem(12);
      }
    }
  }

  &_wrap_button_view_more {
    display: flex;
    justify-content: center;
    button {
      padding: rem(10) rem(52);
    }
  }

  &_btn_open_work_folder {
    margin-bottom: rem(20);

    .a-button {
      margin-left: auto;
      border-radius: rem(10);
    }
  }

  .o-siteLayout {
    max-width: 100%;
    .o-banner {
      margin-bottom: rem(50);
      @include break-max(767px) {
        margin-bottom: rem(40);
      }
    }
    
    #{$root}_your_course {
      p {
        height: rem(30);
        object-fit: contain;
        font-family: $font-muli;
        font-size: rem(24);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: $brand_blue_dark;
        margin-bottom: rem(20);
      }
    }
  }

  @include mobile-down {
    &_wrap_button_view_more {
      margin-top: 0;
    }
  }
}

.item_card_course {
  width: 100%;
  height: 100%;
}