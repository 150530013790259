.p-expertListParticipant {
  $root: &;
  font-family: $font-muli;

  .m-breadcrumb {
    padding-top: rem(20);
    margin-bottom: rem(53);
    @include small-mobile {
      margin-bottom: rem(20);
    }
  }

  &_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem(35) 0;
    @include small-mobile {
      margin: rem(20) 0;
    }
    .a-text {
      @include break-max(768px) {
        line-height: 1.25;
        font-size: rem(24);
      }
    }
    .a-button {
      min-width: rem(200);
      @include mobile-down {
        min-width: auto;
      }
    }
  }

  &_row {
    min-height: rem(407.63);

    .o-cardProfile {
      padding-bottom: rem(46);
      &_img {
        .a-image-ratio {
          &_img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  &_empty{
    padding-top: rem(123);
    padding-bottom: rem(266);
    .a-text {
      font-size: rem(50);
      line-height: rem(63);
      color: $light_gray_pale;
    }
  }

  &_viewMore {
    display: flex;
    justify-content: center;
    padding-bottom: rem(50);
    .a-button {
      width: rem(200);
    }
  }
}

.filter-radio {
  position: relative;
  display: inline-flex;
  min-height: rem(24);
  padding-left: rem(30);
  align-items: center;
  cursor: pointer;

  @include mobile-down {
    min-height: rem(20);
    padding-left: rem(26);
  }

  input {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }

  input:checked + &_mark{
    border-color: $bg_yellow;
  }
  
  input:checked + &_mark::after{
    transform: scale(1) translate(-50%,-50%);
  }

  &_label {
    font-size: rem(16);
    @include mobile-down {
      font-size: rem(14);
    }
  }

  &_mark {
    width: rem(24);
    height: rem(24);
    border: 2px solid $bg_yellow;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: rem(18);

    @include mobile-down {
      width: rem(20);
      height: rem(20);
    }

    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(12);
      height: rem(12);
      background-color: $bg_yellow;
      transform: scale(0) translate(-50%,-50%);
      border-radius: 50%;
      @include mobile-down {
        width: rem(10);
        height: rem(10);
      }
    }
  }
}



.filter-input {
  position: relative;
  box-shadow: 0 rem(3) rem(10) 0 $alpha_black_2;

  &_input {
    background-color: $white;
    border: solid 1px $brand_yellow;
    border-radius: rem(5);
    box-sizing: border-box;
    font-family: inherit;
    outline: 0;
    padding:rem(18) rem(50) rem(18) rem(20);
    color: $brand_blue_dark;
    width: 100%;
    font-size: rem(16);
    line-height: 1.25;

    @include break-max(768px) {
      padding:rem(12) rem(40) rem(12) rem(10);
    }

    &::placeholder {
      color: $brand_gray;
      opacity: 0.4;
    }
  }

  .a-iconButton {
    position: absolute;
    right: rem(20);
    top: 50%;
    transform: translateY(-50%);
    @include break-max(768px) {
      right: rem(10);
    }
  }
}

.filter {
  position: relative;
  $root: &;
  &-isShow {
    #{$root}_content{
      opacity: 1;
      visibility: visible;
    }
  }

  &_content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right:0;
    top: calc(100% + 30px);
    z-index: 5;
    background-color: $white;
    min-width: rem(560);
    padding: rem(20);
    border: solid 1px $bg_yellow;
    border-radius: rem(5);

    @include mobile-down {
      padding: rem(20) rem(10);
      min-width: rem(330);
    }

    @include break-max(350px) {
      min-width: rem(280);
    }

    &::before {
      content: "";
      position: absolute;
      top: rem(-16);
      right: 15%;
      background-color: $white;
      width: rem(32);
      height: rem(32);
      transform: rotate(45deg) skew(10deg,10deg);
      border-top: solid rem(1) $brand_yellow;
      border-left: solid rem(1) $brand_yellow;
      z-index: -1;
      @include mobile-down {
        right: rem(20);
      }
    }
  }

  &_name-status{
    position: relative;
    margin-top: rem(20);

    .a-text{
      background-color: $white;
      position: relative;
      display: inline-block;
      z-index: 2;
      padding-right: rem(20);
      @include mobile-down {
        font-size: rem(16);
      }
    }

    &::after {
      z-index: 1;
      content: '';
      position: absolute;
      height: 1px;
      background-color: $platinum;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &_list-status {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-8);

    &-item {
      flex: 0 0 50%;
      margin-top: rem(10);
      padding: 0 rem(8);
    }
  }

  &_checkbox {
    padding-top: rem(10);
  }

  &_name-input {
    margin-top: rem(10);
  }
}