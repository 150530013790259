.t-popuplogin {
    display: block;
    $root: &;
    color: $white;
    padding: 50px 138px;
    width: 100%;
    @include tablet-down {
        padding-left: 10px;
        padding-right: 10px;
    }
    &_btn {
        display: flex;
        justify-content: center;
        @include small-mobile {
            display: block;
        }
        button.a-button {
            width: 100%;
        }
        &_login {
            max-width: 200px;
            @include small-mobile {
                margin: 0 auto;
            }
        }
        &_register {
            margin-left: rem(20);
            max-width: 200px;
            @include small-mobile {
                margin: 0 auto;
            }
        }
    }
    &_login {
        text-align: center;
        margin-bottom: rem(61);
        @include small-mobile {
            .a-text {
                font-size: rem(30);
            }
        }
    }
    &_input {
        .t-popuplogin_content {
            margin-top: rem(30);
            .a-text:first-child {
                margin-bottom: rem(20);
            }
        }
        .a-input {
            .a-input_input {
                color: $rick_black;
                padding-left: rem(20);
            }
        }
    }
    &_action {
        display: flex;
        justify-content: space-between;
        margin-top: rem(20);
        margin-bottom: rem(50);
        align-items: center;
        @include small-mobile {
            display: block;
        }
        .a-checkbox {
            width: auto;
            @include small-mobile {
                margin-bottom: 10px;
            }
            &-label {
                color: $white;
                font-size: rem(18);
            }
        }
        #{$root}_forgot {
            background-color: transparent;
            border: 0;
            color: $white;
            @include hover {
              text-decoration: underline;
            }
        }
    }
}
