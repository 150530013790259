@mixin border {
  box-shadow: 0 0 6px 0 $rick_black;
  border: solid 2px $brand_yellow;
  background: $rick_black;
  position: absolute;
}
.m-hoverinfodetail {
  position: relative;
  $root: &;
  &:hover &_wrap {
    display: block;
  }
  &_wrap {
    min-width: 450px;
    min-height: 250px;
    position: absolute;
    z-index: 5;
    top: 100%;
    left: -150px;
    display: none;
  }
  &_dropdown {
    min-height: 220px;
    padding: 20px;
    @include border;
    opacity: 0.9;
    top: 25px;
    #{$root}_arrow {
      @include border;
      border-right: none;
      border-bottom: none;
      top: -16px;
      left: 160px;
      width: 30px;
      height: 30px;
      z-index: $z-index-1;
      transform: rotate(45deg) skew(15deg, 15deg);
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      background: url("~images/hoverInfoDetail/puzzle01.svg") no-repeat center
        center;
      z-index: $z-index-1;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 25px;
      height: 25px;
      background: url("~images/hoverInfoDetail/puzzle02.svg") no-repeat center
        center;
      z-index: $z-index-1;
    }
    #{$root}_content {
      max-width: 450px;
      max-height: 250px;
      position: relative;
      z-index: $z-index-3;
      #{$root}_header {
        display: flex;
        #{$root}_img {
          max-width: 60px;
          max-height: 60px;
          border-radius: 50%;
          overflow: hidden;
          .a-image_img {
            width: 100%;
          }
        }
        #{$root}_name {
          color: $white;
          margin-left: rem(10);
          .a-text:first-child {
            line-height: 1.25;
            margin-bottom: rem(10);
          }
          .a-text:last-child {
            line-height: 1.25;
            font-size: rem(12);
          }
        }
        #{$root}_age {
          color: $white;
          margin-left: rem(15);
          margin-top: rem(8);
          .a-text {
            font-size: rem(12);
            line-height: calc(1.25 + rem(12));
            span {
              margin-left: rem(10);
            }
          }
        }
      }
      #{$root}_description {
        margin-top: rem(10);
        overflow-y: auto;
        max-height: 100px;
        color: $white;
        font-size: rem(16);
        line-height: 1.25;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: $white;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 1px;
          border: 1px solid $white;
        }
      }
    }
  }
}
