.m-contact-popup {
  &_bg {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: auto;
  }
  &_wrap {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 670px;
    margin: auto;
    @include break-min(768px) {
      height: 554px;
      padding: 40px;
    }
    .a-text {
      padding-top:30px;
    }
    .a-button,.a-button {
      margin-top:50px;
    }
    &_dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:50px;
      &_title {
        margin-right: 10px;
        font-size: 16px;
        color: $brand_gray;
      
      }
      &_content {
        font-family: $font-muli;
        font-size: 18px;
        font-weight: bold;
        color: $brand_gray;
    
        button {
          text-align: left;
          width: 100%;
          min-width: 200px;
          color: $brand_gray !important;
          background-color: $brand_white !important;
        
          border: 0 !important;
          height: 60px;
          @include break-min(768px) {
            min-width: 471px;
          }

          &.dropdown-toggle {
            background-color: $gray_light_2;
            border: $border_gray !important;
            font-weight: bold;
            &::after {
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:focus {
              box-shadow: none !important;
            }
          }
          &.btn-success {
            background-color: $gray_light_2;
            &.dropdown-toggle {
              background-color: $gray_light_2;
            }
          }
        }
        .dropdown {
          font-family: $font-muli;
          border: $border_gray;
          color: $brand_gray;
          font-weight: bold;
          height: 60px;
          &.show {
            .dropdown-toggle {
              &::after {
                border-bottom: 0.3em solid;

                border-top: 0;
              }
            }
          }
          &-item {
            font-family: $font-muli;
            color: $brand_gray;
            border-bottom: $border_gray;
            font-weight: bold;
            padding: 15px;
            &:last-child {
              border-bottom: 0;
            }
            &:active {
              background-color: $brand_white;
            }
          }
          &-menu {
            min-width: 200px;
            margin: 0;
            border: $border_gray;
            @include break-min(768px) {
              min-width: 471px;
            }
          }
        }
      }
    }
  }
}
.modal-dialog {
  @include break-min(456px) {
    max-width: 1088px;
  }
  .modal-body {
    padding: 0;
    border-radius: 9.5px;
  }

}