@function rem($pixels, $context: 16) {
  @if (unitless($pixels)) {
      $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
      $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

/* make area that keep the aspect-ratio of area.
--------------------------------------------------------- */
@mixin aspectRatio($width: 1, $height: 1) {
  position: relative;

  &:before {
      display: block;
      padding-bottom: ($height / $width) * 100%;
      content: '';
  }
}