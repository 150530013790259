.o-about-course {
  &_button {
    .container {
      position: relative;
    }
    &_wrap {
      position: absolute;
      z-index: 100;
      left: 15px;
      bottom: 0;
      @include break-min(1920px) {
        bottom: 50px;
      }
      @include break(1366px,1919px) {
        bottom: 30px;
      }
    }
  }
}