.o-courseListHome{
  margin-top: rem(60);
  @include mobile-down{
    margin-top: rem(30);
  }
  &_slider{
    margin-top: rem(40);
    position: relative;
    padding: rem(50) rem(138);
    border: rem(3) solid $brand_yellow;
    border-radius: rem(10);
    box-shadow: 0 0 rem(40) rgba(0, 0, 0, 0.6);
    background-image: linear-gradient(to right,$midnight_blue_dark 0%, $midnight_blue 100%);
    @include tablet-down {
      padding: rem(30) rem(16);
    }
    @include mobile-down{
      margin-top: rem(20);
    }
  
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url('~images/bg_courselist.svg');
      background-size: cover;
      opacity: 0.6;
    }
  }
  &_list {
    .slick-list{
      margin-left: rem(8);
      margin-right: rem(8);
    }
    .slick-arrow{
      width: rem(20);
      height: rem(38);
      top: 40%;
      @include tablet-down {
        display: none!important;
      }
      &::before{
        display: none;
      }
    }
    .slick-prev{
      left: rem(-68);
      background: url("~images/icons/icon_prev.svg") no-repeat center / 120% auto;
     
    }
    .slick-next{
      right: rem(-68);
      background: url("~images/icons/icon_next.svg") no-repeat center / 120% auto;
    }

    .slick-slide {
        padding-left: rem(8);
        padding-right: rem(8);
    }

    .slick-dots {
        position: relative;
        bottom:0;
        margin-top: rem(50);
        display: flex !important;
        justify-content: center;

        @include tablet-down {
          margin-top: rem(30);
        }
   
        li {
            display: block;
            width: unset;
            height: unset;
            button {
                width: rem(10);
                height: rem(10);
                background: white;
                opacity: 1;
                transition: 0.2s;
                border-radius: 50%;
                &:before {
                    content: none;
                }
            }
            &.slick-active {
                button {
                    width: rem(30);
                    border-radius: rem(5);
                    background: linear-gradient(
                        90deg,
                        $bg_red_light 0%,
                        $bg_yellow 100%
                    );
                }
            }
        }
    }
  }
}