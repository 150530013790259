.work-folder {
  margin-bottom: rem(30);
  
  .a-image-ratio {
    cursor: pointer;
  }

  &-image {
    position: relative;
  }

  &-avatar {
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 70%;
    border-radius: 50%;
    border: 2px solid $brand_blue_dark;
    cursor: pointer;
    object-fit: cover;
  }
}