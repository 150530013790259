@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.t-profileform {
  $root: &;
  display: block;
  &_minheight{
    min-height: 60vh,
  }
  &_row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-77);
    min-height: 30vh;
    @include break-max(1366px){
      margin: 0 rem(-20);
    }
  }
  &_groupCheckbox{
    display: flex;
    justify-content: flex-start;
    @include small-mobile{
      display: block;
    }
   
  }
  &_checkboxWrap{
    display: flex;
    align-items: center;
    margin-right: rem(20);
    .a-checkboxCustom{
      margin-bottom: 0;
    }
    @include small-mobile{
      margin-top: rem(5);
    }
    .a-checkbox{
      width: auto;
    }
  }
  &_heading {
    margin-bottom: rem(48);
    @include tablet-down {
      .a-heading {
        font-size: rem(24);
        font-weight: bold;
      }
    }
    @include small-mobile {
      margin-bottom: rem(20);
    }
  }
  &_avatar {
    position: relative;
    display: flex;
    padding-bottom: rem(50);
    border-bottom: remn(1) solid $brand_blue_dark;
    input#file{
      display: none;
    }
    &-img {
      display: inline-block;
      .m-avatar {
        .a-image-ratio {
          &_img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    @include small-mobile {
      border-color: $white-light;
      padding-bottom: rem(16);
      display: block;
      text-align: center;
      #{$root}_avatar-img {
        .m-avatar-first-character,
        .m-avatar-upload {
          width: 100%;
        }
      }
    }
    &-description {
      @include flex;
      margin-left: rem(50);
      max-width: rem(562);
      @include small-mobile {
        margin-left: 0;
      }
      @include break-max(1300px) {
        flex: 1;
      }
      #{$root}_descripttion-wrap {
        @include break-max(1300px) {
          width: 100%;
          height: 100%;
        }
        #{$root}_name {
          margin-bottom: rem(20);
          @include small-mobile {
            margin-top: rem(16);
            .a-text {
              font-size: rem(24);
              font-weight: bold;
            }
          }
        }
        #{$root}_textarea {
          min-width: rem(562);
          height: rem(180);
          overflow-y: auto;
          @include break-max(1200px) {
            min-width: unset;
          }
          @include small-mobile {
            height: auto;
            .a-text {
              text-align: left;
              font-size: rem(16);
              color: $rick_black;
              line-height: 1.5;
            }
          }
          .m-textarea {
            height: 100%;
            max-height: rem(180);
            textarea {
              font-size: rem(16);
              color: $brand_blue_dark;
            }
            @include break-max(768px) {
              height: rem(300);
            }
          }
        }
      }
    }
  }
  &_info {
    display: flex;
    justify-content: space-between;
    @include small-mobile {
      display: block;
    }
    #{$root}_block {
      margin-top: rem(30);
      .a-text:first-child {
        margin-bottom: rem(10);
      }
      .t-profileform_block-language {
        .a-input {
          display: inline-block;
          max-width: rem(161);
          margin-right: rem(50);
          .a-input_error{
            bottom: rem(-20);
            text-align: left;
          }
        }
        .t-profileform_btn-add {
          appearance: none;
          background: transparent;
          border: none;
          color: $dodger_blue;
          text-decoration: underline;
        }
      }
      &.right-block {
        width: rem(360);
        word-break: break-all;
        @include mobile {
          width: rem(250);
        }
        @include small-mobile {
          width: auto;
        }
      }
      @include tablet-down {
        .a-text:first-child {
          font-size: rem(24);
          line-height: 1.5;
        }
        .a-text:last-child {
          font-size: rem(16);
          line-height: 1.13;
          color: $gray_dark;
        }
      }
      .a-input_input {
        color: $black;
      }
    }
    #{$root}_two-block {
      display: flex;
      width: rem(360);
      @include mobile {
        width: rem(250);
      }
      @include small-mobile {
        display: block;
        width: auto;
      }
      #{$root}_block {
        margin-right: rem(54);
      }
    }
  }
  &-expert {
    #{$root}_footer {
      margin-top: rem(190);
    }
  }
  &_footer {
    margin-top: rem(70);
    text-align: center;
    @include small-mobile {
      margin-top: rem(40);
      .a-text {
        color: $gray_dark;
      }
    }
    .a-button {
      margin: auto;
      margin-top: rem(30);
      margin-bottom: rem(50);
      width: rem(200);
      @include small-mobile {
        margin-bottom: rem(24);
      }
    }
    #{$root}_cancel {
      display: flex;
      justify-content: center;
      .a-button {
        margin-left: 0;
        margin-right: rem(34);
        @include break-min(1200px) {
          padding-right: 0;
          padding-left: 0;
        }
      }
      @include small-mobile {
        justify-content: center;
        .a-button {
          margin-right: 0;
          width: rem(125);
          &:first-child {
            margin-right: rem(10);
          }
        }
      }
    }
  }
}
