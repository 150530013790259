.t-accountform {
  padding-bottom: rem(50);
  @include small-mobile {
    padding-bottom: rem(24);
  }
  display: block;
  $root: &;
  &_header {
    display: block;
    margin-bottom: rem(44);
    .a-heading {
      font-weight: 900;
      @include tablet-down {
        font-size: rem(24);
        font-weight: bold;
      }
    }
  }
  &_title {
    margin-bottom: rem(20);
    @include small-mobile {
      .a-text {
        font-size: rem(16);
      }
    }
    @include tablet-down {
      .a-text {
        font-size: rem(24);
        font-weight: bold;
      }
    }
  }
  &_email {
    padding-bottom: rem(30);
    border-bottom: 1px solid $brand_blue_dark;
  }
  &_password {
    margin-top: rem(30);
    #{$root}_content {
      .a-input {
        background-color: $white;
        margin-bottom: rem(40);
        @include small-mobile {
          margin-bottom: rem(20);
        }
      }
      @include mobile-down {
        .a-input_error {
          position: relative;
        }
        .a-input {
          background-color: transparent;
        }
      }

    }
  }
  &_footer {
    margin-top: rem(215);
    @include tablet-down {
      margin-top: rem(50);
    }
    #{$root}_text {
      text-align: center;
      .a-text {
        font-size: rem(12);
        line-height: 1.5;
        color: $gray_dark;
      }
    }
    #{$root}_button {
      width: 100%;
      margin-top: rem(30);
      display: flex;
      justify-content: center;
      .a-button {
        width: rem(200);
      }
      &-small {
        .a-button {
          @include break-min(1200px) {
            padding-right: 0;
            padding-left: 0;
          }
          &:first-child {
            margin-right: rem(77);
          }
          @include small-mobile {
            width: rem(125);
            &:first-child {
              margin-right: rem(10);
            }
          }
        }
      }
    }
  }

  &_wrapper {
    width: '100%';
    height: '100%';
  }

  &_modal {
    width: 100%;
    height: rem(324);
    border-radius: 5px;
    border: solid 3px $chardonnay;
    background-image: linear-gradient(to bottom, $blue_dark, $blue 48%, $blue_light);
    .a-text {
      width: 100%;
      height: rem(34);
      font-family: $font-muli;
      font-size: rem(24);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      margin-top: rem(100);
      margin-bottom: rem(50);
    }
  }
  @include mobile-down {
    &_modal {
      height: rem(202);
      .a-text {
        font-size: rem(16);
        margin-top: rem(52);
        margin-bottom: rem(34);
        padding-left: rem(16);
        padding-right: rem(16);
      }
    }
  }
}
