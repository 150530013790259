.m-account-header {
  &_wrap {
    display: flex;
    list-style: none;
    height: rem(100);
    padding-left: rem(20);
    padding-right: rem(20);
    align-items: center;
    overflow: hidden;
    &:hover {
      .m-account-header_submenu {
        display: block;
      }
    }
    p {
      display: flex;
      align-self: center;
      font-size: rem(24);
      font-family: $font-muli;
      list-style-type: none;
      color: $white;
      line-height: rem(36);
      position: relative;
    }
  }
  &_avatar {
    margin-right: rem(10);
    border-radius: 50%;
    overflow: hidden;
    @include mobile-down {
      overflow: initial;
    }
    @include tablet {
      overflow: initial;
    }
    
    border: rem(3) solid $chardonnay;
    .a-image_img {
      display: block;
      width: rem(60);
      height: rem(60);
      border-radius: 50%;
      object-fit: cover;
    }
    &.background-avatar {
      background-color: $brand_blue_dark;
      p {
        height: rem(57);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    p {
      width: rem(60);
      height: rem(60);
      align-items: center;
      justify-content: center;
      background-color: $brand_blue_dark;
    }
  }
  &_submenu {
    display: none;
    height: auto;
    position: absolute;
    bottom: rem(-5);
    margin-left: rem(-39);
    background-color: darkcyan;

    &:hover {
      display: inline-block;
    }

    &::before {
      width: rem(150);
      height: rem(50);
      content: '';
      position: absolute;
      top: rem(-50);
      left: 0;
    }

    ul {
      position: absolute;
      float: left;
      padding: 0;
      list-style: none;
      border: rem(2) solid $chardonnay;
      border-radius: rem(3);
      width: rem(150);
      display: flex;
      flex-direction: column;
      z-index: 1;
      background-color: rgba(22, 34, 42, 0.9);
      overflow: hidden;
      .m-account-header-item {
        border-right: 0;
        border-left: 0;
        border-top: $bg_white;
        border-bottom: $bg_white;
        height: rem(60);
        &:first-child {
          border-top: 0;
          border-bottom: 0;
        }
        &:last-child {
          border-top: 0;
          border-bottom: 0;
        }

        li {
          padding: 0;
        }
        li a {
          display: flex;
          width: 100%;
          height: 100%;
          color: $white;
          font-size: rem(16);
          font-family: $font-muli;
          font-weight: bold;
          text-decoration: none;
          text-transform: none;
          justify-content: center;
          align-items: center;
          &::before {
            content: none;
          }
        }
      }
    }
    .hover:hover {
      background-color: $bright-blue;
    }

    @include tablet-down {
      margin-right: rem(231);
      ul .m-account-header-item li {
        margin: 0;
      }   
    }

    @include mobile-down {
      ul {
        background-color: rgb(22, 34, 42);
      }
    }
  }
  
  &_triangle {
    position: absolute;
    background-color: $bg_yellow;
    top: rem(-15);
    left: rem(67);
    width: rem(12);
    height: rem(12);
    border-top-right-radius: 28%;
    z-index: 2;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

    &:before, &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      width:  rem(12);
      height: rem(12);
      border-top-right-radius: 0%;
    }
    &:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    &:after {
      transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
  }

  &_triangle_override {
    position: absolute;
    background-color: #16222A;
    top: rem(-11.5);
    left: rem(68);
    width: rem(10);
    height: rem(10);
    border-top-right-radius: 0%;
    z-index: 2;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

    &:before, &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      width:  rem(10);
      height: rem(10);
      border-top-right-radius: 0%;
    }
    &:before {
      top: 0;
      left: 0;
      transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    &:after {
      transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
  }

}

