.t-courseFolder {
  border-radius: 5px;
  position: relative;
  &_wrapper {
    padding-right: rem(18);
    max-height: rem(203);
    overflow: auto;
    padding-top: rem(25);
    @include media-breakpoint-up(md) {
      padding: rem(20);
      padding-right: rem(40);
      max-height: rem(350);
    }
    @include mobile-down{
      max-height: rem(400);
    }
  }
  &_layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: $transition ease;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    border-radius: 5px;
    &:after {
      width: 100%;
      height: 100%;
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      filter: blur(6px);
      border: solid rem(10) $rick_black;
    }
    &-show {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
      // box-shadow: 0 3px 26px rgba($rick_black, 0.7);
    }
    &_title {
      font-size: rem(24);
      color: #fff;
      text-transform: uppercase;
      margin-bottom: rem(50);
      font-weight: bold;
      @include media-breakpoint-down(sm) {
        margin-bottom: rem(25);
        font-size: rem(16);
      }
    }
    &_action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      .a-button {
        min-width: rem(200);
        white-space: nowrap;
        padding-left: rem(24);
        padding-right: rem(24);
        @include  mobile-down {
          min-width: rem(230);
          padding-left: 0;
          padding-right: 0;
        }
        &:first-child {
          margin-bottom: rem(14);
          @include media-breakpoint-up(md) {
            margin-right: rem(70);
            margin-bottom: 0;
          }
        }
      }
    }
    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(45deg, $bg_blue_dark, $bg_blue_light);
      opacity: 0.9;
      object-fit: contain;
      border-radius: 5px;
      &::before {
        @include absolute;
        top: 0;
        left: 0;
        width: rem(73.7);
        height: rem(111.5);
        background: url(~images/o-modal/icon_bg_blue.svg) center center;
      }
      &::after {
        @include absolute;
        width: rem(81.5);
        height: rem(87.1);
        bottom: 0;
        right: 0;
        background: url(~images/o-modal/icon_bg_red.svg) center rem(1);
      }
    }
    &_content {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-10);
    margin-right: rem(-10);
    min-height: rem(140);
    @include mobile-up {
        min-height: rem(181);
    }
    .m-folder {
      flex: 0 0 20%;
      max-width: 20%;
      padding-left: rem(10);
      padding-right: rem(10);
      margin-bottom: rem(20);
      @include media-breakpoint-down(lg) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @include media-breakpoint-down(sm) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
      &_icon {
        @include media-breakpoint-down(sm) {
          height: rem(50);
        }
      }
      &_label {
        @include media-breakpoint-down(sm) {
          margin-top: rem(10);
          font-size: rem(14);
        }
      }
    }
  }
  &_top {
    margin-bottom: rem(20);
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .a-button {
      width: rem(200);
      padding-left: 0;
      padding-right: 0;
      @include small-mobile {
        width: rem(150);
        margin: auto;
        margin-top: rem(15);
      }
    }
  }
  .o-listFiles {
    height: 100%;
    padding-right: rem(15);
    padding-left: rem(15);
    background: $white;
    border-radius: rem(5);
    box-shadow: 0 3px 6px 0 $alpha_black_16;
    border:$border_yellow;
    overflow: auto;
    .o-listFiles_wrapper {
      height: 100%;
      overflow-y: auto;
      @include small-mobile {
        max-height: unset;
      }
      &::-webkit-scrollbar-track {
        margin-bottom: rem(80);
      }
      &::-webkit-scrollbar:horizontal {
        height: 7px;
      }
    }
    table {
      tbody {
        tr {
          border-top: solid 1px rgba(128, 128, 128, 0.3);
          &:first-child{
            border: none;
          }
        }
      }
    }
  }
  .m-panel {
    position: relative;
  }
  ::-webkit-scrollbar-track {
    box-shadow: 0 3px 6px 0 $alpha_black_16;
    border-radius: rem(10);
  }

  &_notes {
    display: flex;
    column-gap: 12px;
    margin-top: 24px;

    &_text {
      flex: 1;

      > .a-text {
        margin-top: 6px;
      }
    }

    &_inline_txt {
      display: inline;

      .a-text {
        display: inherit;
        margin: 0 4px;
        color: $red;
      }
    }
  }
}
