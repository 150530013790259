.o-notification {
  $root: &;
  position: relative;
  max-height: rem(36);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &_info {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &_hasinfo {
      position: absolute;
      top: rem(7);
      right: rem(132);
      width: rem(10);
      height: rem(10);
      border-radius: 50%;
      background: $tahiti_radial_gradient;

      @include tablet-up {
        right: rem(4);
      }

      @include mobile-down {
        right: rem(80);
      }

      @include break-max(375px) {
        right: rem(68);
      }
    }

    .a-text {
      display: inline-block;
      @include tablet-up {
        display: none;
      }
      @include tablet {
        width: rem(125);
      }

      @include mobile-down {
        width: rem(75);
      }

      @include break-max(375px) {
        font-size: rem(12);
        width: rem(64);
      }
    }
  }

  &_messageContainer {
    visibility: hidden;
    opacity: 0;
    transition: opacity $transition linear;
    position: absolute;
    top: rem(66);
    right: rem(-70);
    width: rem(536);
    min-height: rem(105);
    background-color: $dark_jungle_green;
    box-shadow: $box_shadow_light;
    border: solid rem(2) $brand_yellow;
    border-radius: rem(5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $z-index-10;
    @include tablet {
      right: rem(-248);
    }

    @include mobile-down {
      right: rem(-115);
      width: rem(332);
    }

    @include break-max(374px) {
      right: rem(-106);
      width: rem(284);
    }

    &::before {
      content: "";
      position: absolute;
      top: rem(-17);
      right: rem(72);
      width: rem(30);
      height: rem(30);
      transform: rotate(45deg);
      background: $dark_jungle_green;
      border-top: solid rem(2) $brand_yellow;
      border-left: solid rem(2) $brand_yellow;

      @include tablet {
        right: rem(248);
      }

      @include mobile-down {
        right: rem(150);
      }

      @include break-max(374px) {
        right: 126px;
      }
    }
  }

  &_emptytext {
    .a-text {
      color: $cadet;
    }
  }

  &_messageList {
    z-index: 1;
    list-style: none;
    padding-inline-start: 0;
    margin-block-end: 0;
    max-height: rem(400);
    overflow-y: auto;
    width: 100%;

    @include mobile-down {
      overflow-x: hidden;
    }

    @include break-max(374px) {
      max-height: 22rem;
    }


  }

  &_message {
    padding: rem(10) rem(86) 0 rem(20) !important;
    position: relative;

    &:hover {
      background-color: $bright-blue;
      cursor: pointer;

      &:first-of-type {
        border-top-left-radius: rem(5);
        border-top-right-radius: rem(5);
      }

      &:last-of-type {
        border-bottom-left-radius: rem(5);
        border-bottom-right-radius: rem(5);
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        left: rem(20);
        bottom: 0;
        height: 1px;
        width: 496px;
        border-bottom: 1px solid $ghost_white;
      }
    }

    &:last-child {
      padding-bottom: rem(14);
    }

    &-action{
      width: 100%;
    }

    &-time {
      position: absolute;
      bottom: rem(5);
      right: rem(20);

      .a-text {
        font-size: rem(12);
        line-height: rem(18);
      }

      &-has-icon {
        display: flex;
        align-items: center;
        column-gap: rem(6);

        .a-text {
          margin-top: rem(2);
        }
      }
    }
  }

  &_textMessage {
    .a-text {
      font-size: rem(12);
      line-height: rem(18);
  }
    padding-top: rem(5);
    padding-bottom: rem(25);
    white-space: pre-line;
  }

  &-show {
    #{$root}_messageContainer {
      visibility: visible;
      opacity: 0.9;
    }
  }
}
