.t-forgotpassword {
  text-align: center;
  color: $white;
  width: rem(812);
  padding: rem(133) rem(138);
  @include break-max(1023px) {
    padding: rem(33) rem(18);
    width: 100%;
    padding-top: rem(100);
  }
  &_title {
    margin-bottom: rem(50);
    @include mobile-down {
      margin-bottom: rem(30);
      .a-heading {
        font-size: rem(24);
      }
    }
  }
  &_description {
    font-size: rem(18);
    line-height: 1;
    margin-bottom: rem(30);
    @include mobile-down {
      font-size: rem(16);
      line-height: 1.25;
    }
  }
  &_input {
    margin-bottom: rem(50);
    @include mobile-down {
      margin-bottom: rem(20);
    }
  }
  &_button {
    margin-bottom: rem(20);
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    button.a-button {
      width: rem(200);
      margin-top: unset;
    }
  }
  &_signin {
   
    font-size: rem(18);
    @include mobile-down {
      font-size: rem(16);
    }
    &_link {
      background-color: transparent;
      border: 0;
      color: $white;
      @include hover {
        color: $azure;
      }
    }
  }

  &_notify {
    text-align: center;
    color: $white;
    padding: rem(135) rem(145) rem(105) rem(145);
    @include media-breakpoint-down(sm) {
      padding: rem(80) rem(20) rem(50) rem(20);
    }

    & > p:first-child {
      font-size: rem(32);
      @include media-breakpoint-down(sm) {
        font-size: rem(28);
      }
    }

    & > p:last-child {
      margin: 0 auto;
      max-width: rem(492);
      font-size: rem(18);
      @include media-breakpoint-down(sm) {
        font-size: rem(16);
      }
    }
  }
}
