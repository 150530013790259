.o-profile {
  $root: &;
  height: 100%;
  &_wrap {
    display: flex;
    @include tablet-down {
      display: block;
    }
    @include small-mobile {
      margin-top: 0;
    }
    #{$root}_left {
      padding-top: rem(76);
      min-width: rem(260);
      position: relative;
      border-right: $border_light_gray;
      @include tablet-down {
        padding-top: rem(24);
        border-right: none;
        .a-heading {
          font-size: rem(24);
          font-weight: bold;
          text-align: center;
        }
      }
      #{$root}_list {
        padding: 0;
        margin: 0;
        margin-top: rem(48);
        list-style: none;
        @include tablet-down {
          margin-top: 0;
          display: flex;
          align-items: center;
          border-bottom: $border_light_gray;
          .m-menuitem {
            margin-right: rem(34);
            border: none;
            &.m-menuitem-active {
              .m-menuitem_link {
                border: none;
              }
            }
          }
        }
        @include small-mobile {
          justify-content: space-between;
          .m-menuitem {
            margin-right: 0;
          }
        }
        .m-menuitem:last-child {
          border: none;
        }
      }
      #{$root}_btn {
        left: 50%;
        bottom: rem(50);
        position: absolute;
        transform: translateX(-50%);
        button.a-button {
          width: rem(200);
        }
        @include break-min(1200px) {
          width: 100%;
          margin: auto;
        }
        @include tablet-down {
          display: none;
        }
      }
    }
    #{$root}_right {
      position: relative;
      flex: 1;
      padding-top: rem(76);
      padding-left: rem(10);
      @include break-min(1640px) {
        padding-left: rem(150);
      }
      @include tablet-down {
        padding-top: rem(48);
        padding-left: 0;
      }
      @include small-mobile {
        padding-top: rem(24);
      }
      #{$root}_angle {
        top: rem(65);
        left: rem(16);
        position: absolute;
        @include tablet-down {
          display: none;
        }
      }
    }
  }
}
