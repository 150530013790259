.m-course-structure-carousel {
  $root: &;
  font-family: $font-muli;
  position: relative;
  overflow: hidden;

  width: 100%;
  position: relative;
  h2 {
    margin-bottom: rem(50);
    margin-top: 5px;
    font-size: rem(50);
    @include mobile-down {
      font-size: rem(18);
      line-height: rem(30);
      margin-bottom: rem(9);
    }
  }

  &_bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0;
    width: 100%;
    position: relative;
    @include break-min(768px) {
      padding: 40px 0;
    }
  }
  &_item {
    &_wrap {
      $wrap: &;
      display: flex;
      max-height: 100%;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top left;
      padding-top: rem(70);
      padding-bottom: rem(100);
      @include break-max(1300px) {
        background-position: right;
      }
      @include break(992px, 1300px) {
        div.col-lg-6 {
          flex: 0 0 100%;
          max-width: 100%;
          #{$wrap}_left {
            margin: auto;
            #{$wrap}_title {
              margin: 0;
              justify-content: center;
              margin-top: rem(30);
            }
            #{$wrap}_img {
              margin: rem(10) auto;
            }
          }
          #{$wrap}_content {
            height: auto;
            padding: rem(20) rem(100);
            &_des_text {
              margin: 0;
              max-height: rem(350);
            }
            &_des_inner {
              margin: 0;
              margin-top: rem(20);
              width: 100%;
            }
          }
        }
      }
      @include break-max(992px) {
        padding-top: rem(10);
        background-image: none !important;
      }
      @include small-mobile {
        padding-bottom: 0;
      }

      color: $brand_white;
      &_left {
        margin-left: rem(104);
        width: rem(663);
        @include break(1360px, 1366px) {
          margin-left: rem(80);
        }
        @include break-max(992px) {
          display: flex;
          width: 100%;
          margin-bottom: 20px;
          flex-direction: column;
          margin-left: 0;
          align-items: center;
        }
      }
      &_title {
        color: $brand_white;
        font-size: 32px;
        line-height: 36px;
        margin-top: 20px;
        margin-left: 36px;
        display: flex;
        align-items: center;
        padding-bottom: rem(10);
        @include break(768px, 992px) {
          width: 80%;
        }
        @include break-max(992px) {
          margin: 0;
          color: $brand_blue_dark;
        }
        @include mobile-down {
          font-weight: bold;
          font-size: rem(16);
          line-height: rem(21);
        }
        @include small-mobile {
          padding-left: rem(15);
          padding-right: rem(15);
        }
        &_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url(~assets/images/bg_course_structure_on.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          color: $brand_white;
          background-color: transparent;
          border: none;
          transition: 0.2s;
          min-width: rem(75);
          height: rem(75);
          font-size: rem(14);
          @include break-max(992px) {
            width: rem(70);
            height: rem(70);
            font-size: rem(11);
          }
          &:focus {
            outline: none;
          }
        }
      }
      &_img {
        position: relative;
        max-width: rem(663);
        padding-bottom: calc(405 / 663 * 100%);
        margin-top: rem(20);
        .m-player {
          position: absolute;
        }
        @include break-max(1600px) {
          max-width: rem(560);
          padding-bottom: calc(300 / 560 * 100%);
        }
        @include break-max(1430px) {
          max-width: rem(500);
          padding-bottom: calc(200 / 500 * 100%);
        }
        @include break-max(992px) {
          width: calc(100% - 32px);
          max-width: rem(500);
          padding-bottom: calc(190 / 500 * 100%);
        }
        @include break-max(480px) {
          width: calc(100% - 32px);
          max-width: rem(480);
          padding-bottom: calc(230 / 480 * 100%);
        }
      }
      &_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        height: rem(680);
        padding: rem(60) rem(20) rem(60) rem(50);
        @include break(1360px, 1366px) {
          height: rem(550);
        }
        @include small-mobile {
          padding: rem(60) rem(15);
          height: rem(400);
        }
        @include break-max(992px) {
          padding-top: rem(30);
          padding-bottom: rem(30);
          background-repeat: no-repeat;
          background-position: right;
          background-size: unset;
          height: auto;
          &-0 {
            background-image: url(~images/course-structure/bg_research_innovation.png);
          }
          &-1 {
            background-image: url(~images/course-structure/bg_decision.png);
          }
          &-2 {
            background-image: url(~images/course-structure/bg_refine.png);
          }
          &-3 {
            background-image: url(~images/course-structure/bg_infrastructure.png);
          }
          &-4 {
            background-image: url(~images/course-structure/bg_finalize.png);
          }
        }
        &_title {
          width: 100%;
        }
        &_description {
          padding: 0;
          position: relative;

          &_text {
            transition: all 0.2s ease-in-out;
            overflow: auto;
            overflow-x: hidden;
            max-height: rem(236);
            a {
              color: $brand_red_dark;
              text-decoration: underline;
            }
             // fontsize set fixed because not CKeditor edit
            *{
              @include mobile-down {
                font-size: rem(14)!important;
                line-height: rem(18)!important;
              }
            }
            @include break-min(768px) {
              font-size: rem(24);
              max-height: rem(535);
            }
            @include break-min(1300px){
              min-height: rem(350);
            }
            @include break(768px, 1919px) {
              max-height: rem(445);
            }
            @include break(1300px, 1400px) {
              max-height: rem(400);
            }
            @include break-max(992px) {
              margin-right: 0;
            }
            @include break(768px, 1000px) {
              max-height: rem(310);
            }
            @include mobile-down {
              font-size: rem(12);
              max-height: rem(200);
              .a-text {
                font-size: rem(12);
                line-height: rem(21);
              }
              ul {
                li {
                  font-size: rem(12);
                }
              }
            }
          }
          &_inner {
            width: calc(100% - 120px);
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            justify-content: center;
            @include break-max(768px) {
              width: 100%;
            }
            .a-checkbox-input {
              @include small-mobile {
                width: rem(20);
                height: rem(20);
              }
            }
            .a-link {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              @include mobile-down {
                width: rem(170);
                height: rem(30);
                span {
                  height: 30px;
                  font-size: rem(14);
                }
              }
            }
            &_start {
              border-radius: rem(20);
              width: rem(203);
              height: rem(40);
              background: #ffffff;
              border: 1px solid #ffc371;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $font-muli;
              font-weight: bold;
              font-size: rem(16);
              color: $rick_black;
              text-transform: uppercase;
              cursor: pointer;
              &:hover {
                opacity: 0.9;
              }
            }
            .a-checkbox {
              justify-content: center;
              margin-bottom: rem(20);
              @include mobile-down {
                margin-bottom: rem(10);
              }
              span {
                font-size: rem(18);
                color: $white;
                @include mobile-down {
                  font-size: rem(14);
                }
                .a-link {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }

  .swiper-slide {
    overflow: hidden;
    counter-increment: demoCounter;
    #{$root}_thumbnail_item_wrap_bg_icon {
      &:before {
        font-size: 11px;
        font-weight: bold;
        color: $brand_white;
        content: counter(demoCounter);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        @include break-min(768px) {
          font-size: 21px;
        }
      }
    }
  }
  &_pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: rem(10);
    @include break-min(769px) {
      margin-top: rem(20);
    }
    &_item {
      position: relative;
      width: rem(280);
      height: rem(300);
      background-image: url(~assets/images/bg_thumbnail_on.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      transition: 0.2s;
      margin-left: -10px;
      &:hover,
      &-active {
        @include break-min(768px) {
          transform: translateY(-23px);
        }
      }
      @include break(1366px, 1440px) {
        width: rem(260);
      }
      @include break(1025px, 1200px) {
        width: rem(260);
      }
      @include break-max(767px) {
        width: 170px;
        height: 135px;
        margin-top: 40px;
      }
      @include break-max(374px) {
        width: 154px;
      }
      &_wrap {
        $root: &;
        position: relative;
        height: 100%;
        min-height: 300px;
        width: 260px;

        &_bg {
          &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 15%;
            left: calc(50% - 20px);
            font-size: 14px;
            font-weight: bold;
            background-image: url(~assets/images/bg_course_structure_on.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            color: $brand_white;
            background-color: transparent;
            border: none;
            transition: 0.2s;
            width: 105px;
            height: 105px;

            @include break-max(767px) {
              width: 62px;
              height: 62px;
              top: 0;
              left: calc(50% - 12px);
              font-size: 8px;
            }
            &:focus {
              outline: none;
            }
          }
        }
        &_content {
          color: $brand_gray;
          padding: 110px 60px 20px 60px;
          @include break-max(767px) {
            padding: 40px 35px 20px 36px;
          }
          @include break-max(992) {
            padding-right: 50px;
          }
          &_title {
            font-family: $font-muli;
            font-size: rem(16);
            line-height: rem(24);
            font-weight: bold;
            position: relative;
            &_phase {
              font-size: 14px;
              font-weight: normal;
            }
            transition: 0.2s;
            span{
              font-size: 12px;
            }
            &:before {
              content: "";
              width: 50px;
              height: 1px;
              border-radius: 10px;
              background-color: $brand_white;
              position: absolute;
              bottom: -5px;
            }
            @include break-max(1199px) {
              font-size: rem(12);
              line-height: rem(18);
              margin-bottom: 12px;
            }
          }
          &_des {
            color: $brand_white;
            font-family: $font-muli;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            font-size: 14px;
            line-height: 22.4px; /* fallback */
            margin-top: rem(12);
            max-height: 65px; /* fallback */
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
            transition: 0.2s;
            ul{
              padding-left: rem(16);
            }
            * {
              // fontsize set fixed because not CKeditor edit
              @include mobile-down {
                font-size: rem(12)!important;
                line-height: rem(14)!important;
              }
            }

            @include break(768px, 1919px) {
              max-height: rem(70);
              p {
                font-size: rem(14);
              }
            }

            @include break-max(767px) {
              font-size: 12px;
              line-height: 12px;
              margin-top: 0;
            }
          }
        }
      }
      margin-top: -13px;

      @include break-max(767px) {
        margin-top: 40px;
      }
    }
    &_item {
      &:nth-child(1) {
        &_wrap_content {
          padding-left: 35px;
        }
      }
    }
  }
  .swiper-wrapper {
    position: relative;
    &-prev {
      position: absolute;
      top: 50%;
      left: 20px;
      width: 70px;
      height: 70px;
      background: url("~images/icons/icon_prev.svg") no-repeat;
      background-size: contain;
      z-index: 5;
      cursor: pointer;
      @include break-max(992px) {
        display: none;
      }
    }
    &-next {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 70px;
      height: 70px;
      background: url("~images/icons/icon_next.svg") no-repeat;
      background-size: contain;
      z-index: 5;
      cursor: pointer;
      @include break-max(992px) {
        display: none;
      }
    }
  }
  .swiper-button {
    &-prev {
      @include break-max(768px) {
        display: none;
      }

      color: #ffc371;
      margin-top: 30px;
      margin-left: 20px;
      z-index: 5555;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      &:focus {
        outline: none;
      }
    }
    &-next {
      @include break-max(768px) {
        display: none;
      }
      color: #ffc371;
      margin-top: 30px;
      margin-right: 20px;
      z-index: 5555;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      &:focus {
        outline: none;
      }
    }
    &-disabled {
      opacity: 0;
    }
  }

  &_thumbnail {
    position: relative;
  }
  .wraper_button {
    padding-bottom: 20px;
    position: relative;
    @include break-min(768px) {
      padding-bottom: 40px;
    }
    .a-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -20px;
      margin-top: 3px;
      @include break-min(768px) {
        right: -60px;
        margin-top: 4px;
      }
    }
  }
  &_btn_star_course {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 5px;
    button {
      font-family: $font-muli;
      text-transform: uppercase;
      color: $brand_blue_dark;
      font-size: 16px;
      font-weight: bold;
      width: 409px;
      height: 40px;
      border-radius: 20px;
      background: $brand_white;
      border: 1px solid $brand_yellow;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
}

.divider {
  width: 812px;
  height: 1px;
  background-color: $brand_white;
  margin: auto;
  @include break-max(1199px) {
    display: none;
  }
}

.wraper_button_xs {
  padding: 20px 0;
  @include break-min(768px) {
    padding: 40px 0;
  }
  &-link{
    font-family: $font-muli ;
  }
}
.slick-slide div {
  outline: none;
}
