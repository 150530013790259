.o-siteLayout {
  background: $ghost_white url("~images/background_siteLayout.png");
  background-size: cover;
  padding-top: rem(96);
  .main{
    min-height: rem(1000);
    @include mobile-down {
      min-height: rem(700);
    }
  }
  &-heightNormal{
    .main{
      min-height: 70vh;
      @include mobile-down {
        min-height: auto;
      }
    }
  }
}