.m-list {
  $root: &;
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  &-icon {
    display: flex;
    align-items: center;
  }
  @include break-min(768px) {
    display: flex;
    align-items: center;
  }
  li {
    display: flex;
    align-items: center;
    padding: 0 15px;
    color: $blue_gray_light;
    height: 100%;
    font-size: 20px;
    @include break-min(768px) {
      padding: 0 20px;
    }
    @include break-max(767px){
      font-size: 8px;
    }
    .a-link {
      color: $blue_gray_light;

      font-size: 20px;
      font-weight: 500;
      &:hover {
        color: $blue_hover;
      }
    }
    span {
      font-weight: bold;
    }
  }

  &-disc {
    display: grid;
    padding:0;
    margin:0;
    li {
      display: block;
      position: relative;
      align-items: flex-start;
      color: $brand_gray;
      &:before {
        content: "•";
        position: absolute;
        top:0;
        left: 0;
        font-size: 23px;
        line-height: 1.2;
        color: $brand_gray;
        @include break-max(767px){
          font-size: 10px;
        }
      }
    }
  }
  &-white {
    color: white;

    li {
      color: white;
      &::before {
        color: white;
      }
      a {
        color: white;
      }
    }
  }
  &-horizontal {
    @include break-min(768px) {
      flex-wrap: wrap;
    }
    @include break-max(767px) {
      text-align: center;
      li {
        margin-bottom: 20px;
      }
    }
  }
}
