.m-herocarousel {
  $root: &;
  position: relative;

  .bg-puzzles {
    position: relative;
  }

  &_wrap {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include break-max(768px) {
      background-image: url(~images/m-herocarousel/banner_mobile.jpg) !important;
    }
  }

  &_item {
    width: 100%;
    position: relative;
    &_wrap {
      display: flex;
      align-items: center;
      max-height: 100%;
      position: relative;
      padding: 30px 35px;
      color: $brand_white;
      min-height: 190px;
      @include break-min(768px) {
        padding: 50px 0;
        min-height: 975px;
      }
      &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        a {
          color: $brand_white;
          text-decoration: underline;
        }
        &_left {
          width: 100%;
          position: relative;
          text-align: center;
          &_button {
            @include break-min(768px) {
              margin-top: 20px;
              display: none;
            }
            .a-button{
              @include break-min(768px) {
                font-size: rem(32);
              }
            }
          }
          @include break-min(1440px) {
            max-width: 630px;
            text-align: left;
          }
          @include break(1024px, 1439px) {
            max-width: 60%;
            text-align: left;
          }
          &_title {
            width: 100%;
            @include tablet-desktop {
              text-align: left;
            }
            h2 {
              font-size: 16px;
              line-height: 24px;
              font-weight: bold;
              margin-bottom: 7px;
              @include break-min(768px) {
                max-width: 536px;
                font-size: 50px;
                line-height: 65px;
              }
            }
          }
          &_des {
            padding: 0;
            position: relative;
            max-height: 36px;
            overflow: hidden;
            @include break-min(768px) {
              max-height: 250px;
              padding: 30px;
              padding-left: 0;
            }
            // fontsize set fixed because not CKeditor edit 
            *{
              @include mobile-down {
                font-size: rem(14)!important;
                line-height: rem(18)!important;
              }
            }
            &_text {
              max-height: 36px;
              overflow: hidden;
              transition: height 2s;
              -moz-transition: height 2s;
              /* Firefox 4 */
              -webkit-transition: height 2s;
              /* Safari and Chrome */
              -o-transition: height 2s;
              /* Opera */
              @include break-min(768px) {
                max-height: 220px;
                overflow: auto;
              }
              p {
                @include mobile-up {
                  text-align: left;
                }
                @include break-min(768px) {
                  font-size: rem(20);
                  line-height: rem(36);
                }
              }
            }
          }
        }
        &_right {
          position: relative;
          display: none;
          @include break-min(768px) {
            display: inline;
            &_wrap {
              position: absolute;
              right: 0;
              margin-right: -12px;
              top: 50%;
              transform: translateY(-50%);
              &_bg {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 147px;
                height: 147px;
                @include break-min(768px) {
                  width: 647px;
                  height: 647px;
                }
              }
            }
          }
        }
      }
    }
  }
  .icon_mobile {
    .a-icon {
      display: none;
    }
  }

  .expaned {
    @include break-max(767px) {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1000vh;
        background-color: #222222;
        opacity: 0.6;
        z-index: $z-index-10;
      }
    }
    #{$root}_item_wrap {
      #{$root}_item_wrap_content_left {
        @include break-max(767px) {
          display: flex;
          flex-direction: column;
          color: $brand_white;
          border-radius: 5px;
          border: $border_yellow;
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0, #0f2027),
            color-stop(0.477, #203a43),
            color-stop(1, #2c5364)
          );
          background: linear-gradient(#0f2027, #203a43, #2c5364);
          padding: 15px;
          position: absolute;
          max-width: 325px;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          z-index: $z-index-10;
          max-height: rem(428);
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            text-align: center;
            color: $brand_white;
          }
          .a-text {
            font-size: 10px;
            line-height: 15px;
            color: $brand_white;
          }
          #{$root}_item_wrap_content_left_des_text {
            max-height: 328px;
          }
          .a-icon {
            @include break-max(480px){
              &-info-white{
                width: 12px;
                height: 12px;
                margin-right: 5px;
              }      
            }
          }

        }
        &_title {
          font-size: 16px;
          line-height: 24px;
          h2 {
            font-size: 16px;
            line-height: 24px;
          }
          @include break-min(768px) {
            font-size: 32px;
            line-height: 39px;
            h2 {
              font-size: 32px;
              line-height: 39px;
            }
          }
        }
        &_des {
          margin-bottom: 10px;
          max-height: 100%;
          @include break-min(768px) {
            margin-bottom: 0;
          }
          &_text {
            max-height: 100px;
            overflow: auto;
            @include break-min(768px) {
              max-height: 445px;
              overflow-x: hidden;
            }
          }
        }
      }
    }
    .a-button, .a-button {
      @include break-max(767px) {
        display: none;
      }
    }
    .icon_mobile {
      .a-icon {
        display: none;
      }
      @include break-max(768px) {
        display: inline-block;
        text-align: center;
        .a-icon {
          display: block;
        }
      }
    }
  }
  .hidden {
    #{$root}_item_wrap {
      #{$root}_item_wrap_content_left {
        &_des {
          &_text {
            @include break-max(767px) {
              height: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
  .a-button ,.a-button{
    @include break-max(767px) {
      margin: auto;
      font-size: 14px;
      .a-icon-up {
        width: 15px;
        height: 15px;
      }
      .a-icon-down {
        width: 15px;
        height: 15px;
      }
    }
  }
  .a-link-button {
    @include break-max(767px) {
      margin: auto;
      font-size: 14px;
      .a-icon-up {
        width: 15px;
        height: 15px;
      }
      .a-icon-down {
        width: 15px;
        height: 15px;
      }
    }
  }
  .a-button-view-more,.a-button {
    padding: 0;
    .a-icon {
      right: -30px;
      margin-top: 3px;
      @include break-min(768px) {
        right: -60px;
        margin-top: 6px;
      }
    }
  }
  .a-icon-info-white {
    margin-left:5px;
    margin-right:10px;
  }
}
