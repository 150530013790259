.modal-custom  {
  background-color: transparent;
  @include break-min(456px) {
    max-width: 807px!important;
  }
  .modal-content {
    border-radius: 3px;
    border: solid 3px #ffc371;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #0f2027),
      color-stop(0.477, #203a43),
      color-stop(1, #2c5364)
    );
    background: -webkit-linear-gradient(#0f2027, #203a43, #2c5364);
    background: -moz-linear-gradient(#0f2027, #203a43, #2c5364);
    background: -o-linear-gradient(#0f2027, #203a43, #2c5364);
    background: -ms-linear-gradient(#0f2027, #203a43, #2c5364);
    background: linear-gradient(#0f2027, #203a43, #2c5364);
  }
  .modal-header {
    border-radius: 0;
    border: 0;
    .close {
      color: white;
      font-size: 30px;
      opacity: 1;
    }
  }
  .wraper {
    max-width: 536px;
    margin: auto;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 0 50px 0;
    @include break-min(456px) {
      padding: 50px 0 100px 0;
    }
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_button {
      cursor: pointer;
      margin-top: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &_box {
        position: relative;
      }
      @include break-min(456px) {
        margin-top: 40px;
      }
      .a-text {
        font-size: 16px;
        font-family: $font;
        color: $white;
        font-weight: bold;
        text-decoration: underline;
        margin-top: 0;
        @include break-min(768px) {
          font-size: 32px;
        }
      }
      .a-icon {
        margin-left: 20px;
      }

      &_xs {
        justify-content: flex-start;
        &-link {
          font-size: 16px;
          color: $brand_blue_dark;
          font-weight: normal;
          text-decoration: none;
          position: relative;
          display: inline-flex;
          cursor: pointer;
          @include break-min(768px) {
            font-size: 32px;
          }
        }
        .a-icon {
          margin-left: 20px;
          margin-top: 3px;
          @include break-min(768px) {
            margin-top: 8px;
          }
        }
      }
    }
  }
}
