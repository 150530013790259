.modalUploadProject {
  padding: rem(50);

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(20);
    column-gap: rem(10);
  }

  &_form {
    margin-top: rem(40);

    &_group {
      margin-bottom: rem(30);

      .a-text {
        margin-bottom: rem(10);
      }

      &_upload {
        display: flex;
        align-items: center;
        gap: rem(20);

        @include mobile-down {
          flex-direction: column;
          align-items: start;
        }

        .a-icon {
          color: $black;
          cursor: pointer;
        }
      }
    }
  }
}

.modalUploadSuccess {
  padding: rem(50);
  text-align: center;

  &_description {
    margin-top: rem(30);
  }

  &_button {
    margin-top: rem(40);
    display: flex;
    justify-content: center;
  }

  &_modal {
    .modal-content {
      width: 600px;
      margin: 0 auto;
    }
  }
}