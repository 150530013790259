.m-player {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  &-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("~images/course-structure/placeholder_courses_video.png")
      no-repeat;
    background-size: cover;
    &_hidden{
      opacity: 0;
    }
  }
  &-playbtn {
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url("~assets/images/play.png") no-repeat;
    background-size: cover;
    @include break-max(768px) {
      width: 20px;
      height: 20px;
    }
  }
}
