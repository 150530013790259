.o-banner {
  $root: &;
  display: flex;
  width: 100%;
  min-height: rem(200);
  position: relative;
  &_heading {
    .a-heading {
      font-size: rem(50);
      font-family: $font-muli;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: rem(63);
      letter-spacing: normal;
      color: $white;
      @include mobile-down {
        font-size: rem(18);
        line-height: rem(30);
      }
    }
  }
  &_intersection {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("~images/banner/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    padding-right: rem(269);
    z-index: 2;
  }
  &_content {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: rem(137);
  }
  &-viewProfile{
    #{$root}_content{
      justify-content: space-between;
      @include tablet-down {
        flex-direction: column;
        justify-content:center;
        align-items: flex-start;
      }
    }
  }
  &_btnViewProfile{
    margin-right: rem(50);
    @include tablet-down {
      margin-right: 0;
      margin-top: rem(10);
    }
  }
  &_image {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    border: $border_yellow;
    border-left: none;
    flex-shrink: 0;
    width: rem(269);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &_name {
      width: 100%;
      height: 100%;
      display: flex;
      padding-left: 28%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $brand_blue_dark;
      color: $white;
      font-size:rem(110);
      @include mobile-down{
        font-size:rem(70);
      }
    }
  }

  @include break-max(767px) {
    min-height: rem(100);
    &_image {
      width: rem(140);
    }

    &_intersection {
      padding-right: 0;
    }

    &_content {
      padding-left: 6%;
      padding-right: rem(125);
    }
  }
  &-bg{
    position: relative;
    min-height: rem(155);
    @include mobile-down{
      min-height: rem(100);
    }
    #{$root}_intersection{
      padding-right: 0;
      background-image:unset;
      #{$root}_content {
        padding-left: 0;
        justify-content: center;
        @include mobile-down{
          padding-right: 0;
        }
      }
    }
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: rem(73.7);
      height: rem(111.5);
      background: url(~images/o-modal/icon_bg_blue.svg) center center;
      @include mobile-down{
        background: url(~images/o-modal/icon_bg_blue.svg)no-repeat center center / 130%;
        width: rem(40);
        height: rem(50);
      }
    }
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: rem(81.5);
      height: rem(87.1);
      background: url(~images/o-modal/icon_bg_red.svg) center center;
      @include mobile-down{
        background: url(~images/o-modal/icon_bg_red.svg)no-repeat center center / 140%;
        width: rem(40);
        height: rem(50);
      }
    }
  }
  &-green{
    background: linear-gradient(264deg, $black, $bg_blue_light);
  }
  &-raspberry{
    background-image: linear-gradient(264deg, $dark_jungle_green, $raspberry);
  }

  &_limit_des {
    .a-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      @include desktop {
        -webkit-line-clamp: 6;
      }

      @include tablet {
        -webkit-line-clamp: 5;
      }

      @include mobile-down {
        -webkit-line-clamp: 4;
      }
    }
  }

  &_bg_image {
    background-image: url(~images/banner/banner_employer.svg);
  }
}
