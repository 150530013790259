.p-expertPublishPortfolio{
  $root: &;
  font-family: $font-muli;
  overflow-x: hidden;

  &_backBtn{
    padding-top: rem(27);
    margin-bottom: rem(37);
    @include small-mobile {
      margin-bottom: rem(27);
      span{
        width: rem(15);
        height: rem(20);
      }
    }
  }

  &_banner{
    background: linear-gradient(276deg, $bg_blue_dark, $bg_blue_light);
  }

  &_cardCourseList{
    padding-top: rem(78);
  }

  &_viewmore{
    margin-top: rem(10);
    margin-bottom: rem(160);

   .a-button{
     margin: auto;
     width: rem(200);
   }
  }

  @include break-max(768px){
    #{$root}_cardCourseList{
      .o-cardcourse{
        height: rem(450);
        max-height: none;
      }
    }
  }
  @include small-mobile {
    #{$root}_cardCourseList{
      padding-top: rem(60);
      padding-bottom: rem(20);
      .o-cardcourse{
        height: rem(364);
        max-height: none;
      }
    }
  }
}