.a-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1060;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 0;
  &-hide {
    visibility: hidden;
    z-index: -1;
  }
  &-wrapper {
    width: 48px;
    height: 48px;
    clear: both;
  }
  &-circle {
    width: 100%;
    height: 100%;
    border: 5px rgba(#05498d, 0.25) solid;
    border-top: 5px rgba(#05498d, 1) solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.6s infinite linear;
    animation: spCircRot 0.6s infinite linear;
  }
}
@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
