%wrap {
    width: 100%;
    font-family: $font-muli;
    font-size: rem(12);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $white;
}

%CustomImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-size: rem(198) rem(105);
}

%CustomTransition {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

%CustomHovering {
    width: rem(202);
    height: rem(105);
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    padding-top: rem(16);
    padding-left: rem(10);
    p {
        @extend %wrap;
        font-weight: normal;
    }
    p:first-child {
        font-weight: bold;
        width: rem(130);
    }
}

@mixin contents() {
    margin-right: rem(10);
    position: relative;
    @include media-breakpoint-up(md) {
        margin-right: rem(20);
    }
    &_card {
        @extend %CustomTransition;
        &.available {
            background-image: url('~images/m-assessment-sheet/available-sharp.png');
            @extend %CustomImage;
        }

        &.unavailable {
            background-image: url('~images/m-assessment-sheet/unavailable-sharp.svg');
            @extend %CustomImage;
        }

        &.last-unavailable {
            background-image: url('~images/m-assessment-sheet/last-unavailable-sharp.svg');
            @extend %CustomImage;
        }

        &_wrap {
            &.hover {
                @extend %CustomHovering;
                opacity: 0;
            }
            &.not_hover {
                &.available {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0;
                    // width: rem(162);
                    min-width: rem(110);
                    height: rem(105);
                    display: flex;
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    p {
                        @extend %wrap;
                        text-align: center;
                        font-weight: bold;
                    }
                }

                &.unavailable,
                &.last-unavailable {
                    width: rem(162);
                    height: rem(105);
                    display: flex;
                    position: absolute;
                    margin-top: rem(-106);
                    flex-direction: column; 
                    left: 50%;
                    transform: translateX(-50%);
                    p:first-child {
                        @extend %wrap;
                        text-align: center;
                        font-weight: bold;
                        margin-top: rem(35);
                        color: $light_gray_pale;
                    }
                    p:last-child {
                        @extend %wrap;
                        text-align: center;
                        font-weight: bold;
                        position: absolute;
                        bottom: 0;
                        margin-bottom: rem(10);
                    }
                }

                &.last-unavailable {
                    width: rem(198);
                }
            }
        }
    }
    &-line {
        width: rem(50);
        padding: rem(1.5) 0;
        border-radius: rem(10);
        background-color: $white;
        margin: rem(5) 0;
    }
}

.available {
    &.m-assessment-sheet {
        width: rem(198);
        height: rem(105);
        cursor: pointer;
        &:hover &_card {
            margin-top: rem(-10);
            @extend %CustomTransition;

            &_wrap {
                &.not_hover {
                    opacity: 0;
                }
                &.hover {
                    @extend %CustomHovering;
                    opacity: 1;
                }
            }
        }
        @include contents();
    }
}

.unavailable,
.last-unavailable {
    &.m-assessment-sheet {
        width: rem(202);

        @include contents();
    }
}
