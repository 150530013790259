.o-modal_children{
  .o-modalnotifycomment{
    position: relative;
    padding: rem(100) rem(140);
    background: linear-gradient(to bottom, $blue_dark, $blue_light);
    margin: auto;
    text-align: center;
    border: 3px solid $brand_yellow;
    border-radius: rem(5);
    @include tablet-down {
      padding: rem(70) rem(40);
    }
    @include mobile-down {
      padding: rem(60) rem(30);
    }
    @include small-mobile {
      padding: rem(50) rem(20);
    }

    &_message {
      max-width: rem(700);
      .a-text {
        @include small-mobile {
          font-size: rem(14);
          line-height: rem(26);
        }
      }
    }

    &_btn{
      margin-top: rem(30);
      display: flex;
      align-items: center;
      justify-content: center;

      @include small-mobile {
        margin-top: rem(20);
      }

      .a-button{
        margin: 0;
        &:last-child {
          margin-left: rem(20);
          @include small-mobile {
            margin-left: rem(10);
          }
        }
        &-bold{
          @include small-mobile {
            min-width: rem(120);
           }
        }
      } 
    }
  }
}

