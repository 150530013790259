.o-carousel-partner {
  position: relative;
  background: #ff5f6d;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ff5f6d), color-stop(1, #ffc371));
  background: -webkit-linear-gradient(#ff5f6d, #ffc371);
  background: -moz-linear-gradient(#ff5f6d, #ffc371);
  background: -o-linear-gradient(#ff5f6d, #ffc371);
  background: -ms-linear-gradient(#ff5f6d, #ffc371);
  background: linear-gradient(#ff5f6d, #ffc371);
  &_wrap {
    padding-top: 30/1920 * 100%;
    padding-bottom: 30/1920 * 100%;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .carousel-control {
    &-next {
    }
    &-prev {
      @include break-min(992px) {
      }
    }
  }
}
