.expert-view-work-folder {
  &_course-item {
    margin-bottom: rem(40);

    .o-participantCard {
      z-index: 1;

      &_img {
        height: rem(266);

        .a-text {
          top: unset;
        }
      }
    }
  }
}