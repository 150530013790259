.o-expertbanner {
  $root: &;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  @include break-max(992px) {
    display: block;
    #{$root}_publicportfolio {
      margin-top: rem(50);
    }

    #{$root}_intersection {
      padding-left: rem(19);
    }
  }

  &_publicportfolio {
    position: relative;
    cursor: pointer;

    &-img {
      background-image: url("~images/o-expert-banner/expertThumb.png");
      background-repeat: no-repeat;
      height: rem(117);
      width: rem(260);
      background-position: center;
      background-size: contain;
      margin-left: auto;
      margin-right: auto;
    }

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .a-text {
        line-height: rem(28);
        font-size: rem(20);
      }
    }
  }

  &_intersection {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: rem(37.7);
    padding-left: rem(138);
    background-image: url("~images/o-expert-banner/expertBanner.png");
    background-repeat: no-repeat;
    background-position: right;
    height: rem(138);

    @include mobile-down {
      padding-top: rem(21);
    }

    &-avatar {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 100%;
      width: rem(184);
    }

    &-textHeading {
      display: flex;
      .a-text {
        margin-right: rem(3);
        font-size: rem(24);
        line-height: rem(30);

        @include break-max(375px) {
          font-size: rem(18);
        }
      }
      margin-bottom: rem(10);
    }

    &-textDescription {
      width: calc(100% - 148px);

      .a-text {
        font-size: rem(16);
        line-height: rem(20);
      }

      &-mobile {
        display: none;
      }
    }
  }
}
