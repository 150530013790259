.m-score{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(16);
  line-height: 1.25rem;
  border: 1px solid $gray_light;
  border-radius: rem(3);
  width: rem(78);
  height: rem(30);
  font-weight: bold;
  background: $white;
  &-red{
    color: $red;
  }
  &-yellow{
    color: $brand_yellow;
  }
  &-green{
    color: $green;
  }
  &-mark{
    color: #e6eaed;
  }
}