.o-modalShowError {
  position: relative;
  width: 80%;
  padding: rem(115) rem(50);
  background: linear-gradient(to bottom, $blue_dark, $blue_light);
  margin: auto;
  text-align: center;
  border: rem(3) solid $brand_yellow;
  border-radius: rem(5);
  @include mobile-down {
    padding: rem(80) rem(30);
    width: 100%;
  }

  &_icon{
    display: inline-block;
    position: absolute;
    z-index: $z-index-3;
    top: rem(20);
    right: rem(20);
  }
  
  &_descriptionError {
    color: white;
    font-size: rem(24);
    line-height: rem(30);
    text-align: center;
    @include mobile-down {
      font-size: rem(16);
      line-height: rem(24);
    }
  }

  .a-button.a-button-bold {
    color: $white;
    background-color: $dodger_blue;
    &:hover {
      background-color: $white;
      color: $rick_black;
    }
  }
}
