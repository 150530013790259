.p-employercourse {
  $root: &;
  font-family: $font-muli;

  &_chooseBtn {
    margin-top: rem(40);
    column-gap: rem(10);
  }

  &_radioBtnGroup {
    display: block;

    .p-expertpublishcourse_radioBtn {
      display: block;
      margin-bottom: rem(10);
      font-size: rem(18);

      &::before {
        top: 2px;
      }

      &-checked {
        &::after {
          top: rem(8);
        }
      }
    }
  }
}
