@mixin aspect-ratio($size) {
  width: $size;
  height: $size;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  &-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: $size;
    height: $size;
    background-color: rgba(25, 93, 118, 0.6);
    z-index: 3;
    border-radius: 5px;
    .a-image_img {
      width: $size;
      height: $size;
    }
    &_wrapContent{
      position: absolute;
      width: 100%;
      bottom: rem(20);
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &_wrapImg{
      width: 43px;
      cursor: pointer;
      input{
        display:none;
      }
    }
    p { 
      margin-top: 10px;
      margin-bottom: 10px;
      flex: 1;
      height: 23px;
      font-family: $font-muli;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: normal;
      text-align: left;
      color: $white;
    }
  }
  &-first-character {
    position: absolute;
    top: 0;
    left: 0;
    width: $size;
    height: $size;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $brand_blue_dark;
    border-radius: 5px;
    > p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 73.6px;
      height: 74.6px;
      color: $white;
      font-size: 110px;
      font-weight: Bold; 
      text-transform: uppercase;
    }
  }
}

.border-avatar-1 {
  border: 1px solid $chardonnay;
}

.border-avatar-2 {
  border: 2px solid $chardonnay;
}

.m-avatar {
  @include aspect-ratio(260px);
}

.m-avatar-in-folder {
  @include aspect-ratio(240px);
}