.a-countdown {
  $root: &;
  &_child {
      display: inline-block;
      margin-right: 0.875rem;
           &:last-child #{$root}_date {
        background-image: linear-gradient(135deg, $bg_red 3%, $bg_red_light 96%);
      }
      .a-text{
        margin-top: rem(10);
        font-size: 0.75rem;
        line-height: 1;
        font-weight: bold;
      }
  }
  &_wrap{
    padding-left: 1px;
    padding-right: 1px;
    overflow: hidden;
  }
  &_date {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 0.3125rem;
      background-image: linear-gradient(to bottom, $blue_dark_1, $blue_light_1);
      position: relative;
      

      &::before {
          content: '';
          height: 0.3125rem;
          width: 2px;
          border-radius: 0.3125rem;
          position: absolute;
          background-color:$white;
          top: 50%;
          left: 0;
          transform: translate(-50%,-50%);
      }
      &::after {
          content: '';
          height: 0.3125rem;
          width: 2px;
          border-radius: 0.3125rem;
          position: absolute;
          background-color:$white;
          top: 50%;
          right: 0;
          transform: translate(50%,-50%);
      }
      span {
          color:$white;
          font-size: 1.875rem;
          font-weight: bold;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &::after {
              position: absolute;
              top: 50%;
              left: -1000px;
              content: '';
              width: 1000px;
              height: 1px;
              background-color:$white;
              transform: translateY(-50%);
          }
          &::before {
              position: absolute;
              top: 50%;
              right: -1000px;
              content: '';
              width: 1000px;
              height: 1px;
              background-color:$white;
              transform: translateY(-50%);
          }
      }
  }
  &#{$root}-white {
      #{$root}_child {
          .a-text {
              color: $white ;
          }
      }
  }
}