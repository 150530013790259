.m-carousel-partner {
  position: relative;
  background-color: #fff;
  &_wrap {
    padding-top: 30/1920 * 100%;
    padding-bottom: 30/1920 * 100%;

    .item {
      text-align: center;
    }

    img {
      max-width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }
}
