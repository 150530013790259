.o-listitem {
  list-style: none;
  margin: 0;
  padding: 0;
  &_item {
    margin-bottom: rem(10);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}