.m-upload {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    &:first-child {
        margin-top: 0;
    }
    &_icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-right: 20px;
        background-image: linear-gradient(to top, $bg_blue, $bg_blue_light);
        @include mobile-down {
          min-width: rem(38);  
        }
    }
    &_status {
        width: 100%;
        position: relative;
        max-width: 330px;
        &-name {
            color: $rick_black;
            display: flex;
            align-items: center;
            span {
                &:first-child {
                    line-height: 20px;
                    max-width: 230px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
                &:last-child {
                    font-size: rem(12);
                    color: $gray_light;
                    margin-left: rem(20);
                    margin-right: rem(20);
                }
            }
        }
        &-status {
            font-size: 12px;
            line-height: 15px;
        }
        &-failed {
            color: $orioles_orange;
        }
        &-close {
            position: absolute;
            right: 0;
            top: -5px;
            .a-iconButton {
                width: 20px;
                height: 20px;
                border-radius: 5px;
                position: initial !important;
                .a-icon {
                    width: 7px;
                    height: 7px;
                    background: none;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 15px;
                        left: 4px;
                        width: 12px;
                        height: 1px;
                        background-color: $white;
                        transform: rotate(45deg);
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 15px;
                        left: 4px;
                        width: 12px;
                        height: 1px;
                        background-color: $white;
                        transform: rotate(-45deg);
                    }
                }
            }
            &-disabled {
                .a-iconButton {
                    opacity: 0.5;
                    cursor: default;
                } 
            }
        }
        span {
            &.isLoading {
                color: $ufo_green;
            }
            &.isDone {
                color: $dodger_blue;
            }
            &.isFailed {
                color: $orioles_orange;
            }
        }
    }
    @include mobile-down {
        &_icon {
            width: auto;
            height: rem(40);
            padding-left: rem(2);
            padding-right: rem(2);
        }
        &_status {
            &-name {
                width: rem(196);
                font-size: rem(12);
                span {
                    &:first-child {
                        width: rem(196);
                        max-width: auto;
                    }
                    &:last-child {
                        margin-left: rem(24);
                        margin-right: rem(24);
                    }
               }
            }

            &-close {
                .a-iconButton {
                    width: rem(18);
                    height: rem(18);
                    .a-icon {
                        width: rem(6);
                        height: rem(5);
                        &:before, &:after  {
                            top: rem(16);
                            left: rem(3.5);
                        }
                    }
                }
            }

            &-status {
                font-size: rem(10);
                line-height: rem(15);
            }
        }
    }
    
}

.m-progress {
    margin-top: 3px;
    margin-bottom: 3px;
    position: relative;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    border: $border_light_gray;
    display: flex;
    overflow: hidden;
    &_bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-image: linear-gradient(
            to right,
            $bg_blue_dark,
            $midnight_blue
        );
        transition: width 0.6s ease;
        &-failed {
            background-image: linear-gradient(
                to right,
                $bg_red_light,
                $brand_yellow
            );
        }
    }
}
