.a-text {
  font-family: $font;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  @include break-min(768px) {
    font-size: 24px;
    line-height: 33px;
  }
  @include break-max(375px) {
    font-size: 8px;
    line-height: 12px;
  }
  &-xxl {
    font-size: 24px;
  }
  &-xl {
    font-size: 18px;
    @include break-min(768px) {
      font-size: 32px;
      line-height: 33px;
    }
    @include break-max(375px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &-lg {
    font-size: 14px;
    @include break-min(768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-sm {
    font-size: 8px;
    line-height: 12px;
    @include break-min(768px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &-sm-small{
    font-size: rem(12);
    line-height: rem(18);
  }

  &-xs {
    font-size: 10px;
    line-height: 18px;
    @include break-min(768px) {
 
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-sz16 {
    font-size: 16px;
    line-height: 20px;

    @include break-max(768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-fs50-lg{
    font-size: rem(50);
    line-height: 1.26;
    text-transform: uppercase;
  }
  &-fs24-lg{
    font-size: rem(24);
    line-height: 0.75;
  }
  &-fs12-lg{
    font-size: rem(12);
    line-height: 1.25;
  }
  &-fs18-lg{
    font-size: rem(18);
    line-height: 1;
  }
  &-fs16-ld{
    font-size: rem(16);
    line-height: rem(24);
  }
  &-white {
    color: $brand_white;
  }

  &-brand-blue-dark {
    color: $brand_blue_dark;
  }
  &-cadet {
    color: $cadet;
  }

  &-red-dark {
    color: $brand_red_dark;
  }

  &-gray {
    color: $brand_gray;
  }

  &-error {
    color: rgb(169, 68, 66);
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-bold {
    font-weight: bold;
  }

  &-light {
    font-weight: 100;
  }

  &-italic {
    font-style: italic;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-decoration {
    text-decoration: line-through;
  }
  &-overflow2{
    @include textOverflow(2);
    @include small-mobile{
      @include textOverflow(3);
    }
  }

  &-underline{
    text-decoration: underline;
  }
}
