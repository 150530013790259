.a-toggle-tooltip {
  margin-left: 5px;
  margin-right: 5px;
  .__react_component_tooltip{
    display: block;
    line-height: 30px;
    opacity: 1;
    background: linear-gradient(#0f2027, #203a43, #2c5364);
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    &:after {
      border-color: #0f2027 transparent transparent transparent;
      -webkit-filter: drop-shadow(1px 2px 1px $brand_yellow);
      filter: drop-shadow(1px 1px 1px $brand_yellow);
    }
  }
}