.own-work-detail {
  font-family: $font-muli;

  &_breadcrumb {
    margin: rem(40) 0;

    .m-breadcrumbitem_link {
      font-weight: bold;
    }
  }

  &_button_view_more {
    display: flex;
    justify-content: center;
    margin: rem(30) 0;

    button {
      padding: rem(10) rem(52);
    }
  }

  &_category-item {
    margin-bottom: rem(40);
  }

  &_empty{
    padding-top: rem(123);
    padding-bottom: rem(266);
    .a-text {
      font-size: rem(50);
      line-height: rem(63);
      color: $light_gray_pale;
    }
  }

  &_file {
    margin-bottom: rem(30);
    cursor: pointer;

    .a-text {
      margin-top: rem(10);
      word-wrap: break-word;
      line-height: 24px;
    }
  }
}