.m-panel {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 $shadow_black;
  &-warning {
    background-color: rgba($color: $chardonnay, $alpha: 0.2);
  }
  &-danger {
    background-color: rgba($color: $orioles_orange, $alpha: 0.2);
  }
  &-success {
    background-color: rgba($color: $ufo_green, $alpha: 0.2);
  }
  &-outline {
    background-color: $white;
    border: $border_yellow;
  }
}