.a-image-ratio {
    position: relative;
    overflow: hidden;
    display: block;
    max-width: 100%;
    max-height: 100%;
    @include aspectRatio(1, 1);
    &_img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
    &-16x9 {
        @include aspectRatio(16, 9);
    }
    &-2x1 {
        @include aspectRatio(2, 1);
    }
    &-3x2 {
        @include aspectRatio(3, 2);
    }
    &-42x53{
        @include aspectRatio(42, 53);
    }
    &-card {
        @include aspectRatio(260, 320);
    }
}