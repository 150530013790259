.employee-work-folder {
  font-family: $font-muli;

  &_breadcrumb {
    margin: rem(40) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .m-breadcrumbitem_link {
      font-weight: bold;
    }
  }

  &_btn-upload {
    .a-button {
      margin-left: auto;
      border-radius: rem(10);
    }
  }

  &_course-title {
    margin-bottom: rem(20);

    .a-text {
      color: $brand_blue_dark;
    }
  }

  &_button-view-more {
    display: flex;
    justify-content: center;
    margin: rem(20) 0 rem(30);

    button {
      padding: rem(10) rem(52);
    }
  }

  &_course-col {
    @include mobile {
      margin-bottom: rem(80);
    }

    @include small-mobile {
      margin-bottom: rem(60);
    }
  }

  &_emptyText {
    padding-top: rem(123);
    padding-bottom: rem(266);

    .a-text {
      font-size: rem(50);
      line-height: rem(63);
      color: $light_gray_pale;
    }
  }
}