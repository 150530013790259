.p-expertcourse{
  $root:&;
  font-family: $font-muli;
  .o-siteLayout{
    // padding-top: rem(20);
    .m-breadcrumb{
      margin-bottom: rem(53);
    }
    #{$root}_row{
      margin-top:rem(50);
      margin-bottom: rem(140);
      @include mobile-up{
        min-height: rem(400);
      }
      .o-cardPhase {
        padding-bottom: rem(20);
        .a-button{
          text-align: center;
        }
        .inactive {
          cursor: initial;
        }
        &_img {
          cursor: pointer;
          .a-image-ratio {
            &_img {
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &_button {
          width: 85%;
          top: 0;
          transform: translate(10%, 0%);
        }
      } 
    }
  }
  &.add-between{
    #{$root}_row{
      @include desktop-up {
        justify-content: space-between;
      }
    }
  }
}