.p-expertphasecourse {
  $root: &;
  font-family: $font-muli;
  .o-siteLayout {
    .m-breadcrumb {
      padding-top: rem(20);
      margin-bottom: rem(53);
      @include small-mobile {
        margin-bottom: rem(20);
      }
    }
    #{$root}_title {
      .a-heading {
        margin-top: rem(63);
        margin-bottom: rem(14);
        @include mobile-up {
          font-size: rem(50);
          line-height: 1.26;
        }
        @include small-mobile {
          margin-top: rem(20);
          font-size: rem(18);
        }
      }
    }
    .o-cardProfile {
      padding-bottom: rem(46);
    }
    #{$root}_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(35);
      .a-text {
        @include break-max(768px) {
          line-height: 1.25;
          font-size: rem(24);
          margin-bottom: rem(10);
        }
      }
      &_search {
        width: rem(398);
        @include break-max(768px) {
          width: auto;
          .a-input-search {
            .a-input_input {
              display: none;
            }
          }
        }
      }
      &.active {
        .a-text {
          @include break-max(768px) {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .p-expertphasecourse_info_search {
          @include break-max(768px) {
            width: 90%;
            .a-input-search {
              padding-left: rem(20);
              .a-input_input {
                display: block;
              }
            }
          }
        }
      }
    }
    #{$root}_row {
      min-height: rem(407.63);

      .o-cardProfile {
        &_img {
          .a-image-ratio {
            &_img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    #{$root}_btn {
      margin-top: rem(44);
      margin-bottom: rem(50);
      .a-button {
        margin: auto;
      }
    }
  }
  &_emptytext{
    padding-top: rem(123);
    padding-bottom: rem(266);
    .a-text {
      font-size: rem(50);
      line-height: rem(63);
      color: $light_gray_pale;
    }
  }
}
