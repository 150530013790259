.m-tab-benefits-list {
  $root: &;
  position: relative;
  @include break-min(1360px) {
    margin-left: rem(-198);
  }
  @include break-max(1023px) {
    max-width: rem(767);
    margin: auto;
  }
  @include break-max(640px) {
    max-width: rem(375);
    margin: auto;
  }

  &_bg {
    background-repeat: no-repeat;
    background-position: center top;
  }
  &_wrap {
    position: relative;
    padding-left: rem(25);
    padding-right: rem(25);
    @include break-min(1024px) {
      padding-left: rem(30);
      padding-right: rem(30);
      margin-top: rem(80);
    }
    @include break-max(1023px) {
      overflow: hidden;
      min-height: rem(290);
    }
    &_left {
      width: 100%;
      position: relative;
      @include break-min(1024px) {
        max-width: rem(700);
        margin: auto;
        top: rem(-250);
      }
      .list-group {
        position: absolute;
        width: 100%;
        @include break-min(1024px) {
          top: rem(10);
        }
        @include break-max(1023px) {
          margin-left: 30%;
          margin-top: rem(90);
        }
        @include break-max(640px) {
          margin-left: rem(50);
        }

        #{$root}_link-bg {
          position: absolute;
          z-index: 2;
          right: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          text-align: center;
          font-family: $font-muli;
          font-weight: bold;
          color: $brand_white;
          font-size: rem(22);
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: transparent;
          width: rem(218);
          height: rem(218);
          font-size: 8px;
          @include break-min(1024px) {
            font-size: rem(20);
          }
          @include break(1024px, 1919px) {
            font-size: rem(16);
          }
          @include break-max(1023px) {
            width: rem(61);
            height: rem(61);
          }
          &:first-child {
            right: auto;
            width: rem(132);
            height: rem(132);
            @include break-min(1024px) {
              width: rem(470);
              height: rem(470);
              font-size: rem(90);
              line-height: 25.2px;
              left: rem(22);
              top: rem(110);
              z-index: 2;
            }

            @include break-max(1023px) {
              font-size: rem(22);
              top: rem(50);
            }
          }
          &:nth-child(2) {
            z-index: 3;
            top: rem(41);
            right: rem(73);
            @include break-max(1023px) {
              top: rem(30);
              left: rem(105);
              white-space: nowrap;
              font-size: rem(6);
            }
          }
          &:nth-child(3) {
            top: rem(235);
            right: rem(-22);
            z-index: 2;
            @include break-max(1023px) {
              top: rem(85);
              left: rem(135);
            }
          }
          &:last-child {
            z-index: 4;
            @include break-min(1024px) {
              top: rem(415);
              right: rem(72);
            }
            @include break-max(1023px) {
              top: rem(140);
              left: rem(100);
              white-space: nowrap;
              font-size: rem(7);
            }
          }
          &:hover {
            background-color: transparent;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    &_right {
      width: 100%;
      @include break-min(1024px) {
        max-width: rem(900);
        margin: auto;
      }
      &_content {
        $root: &;
        margin-top: rem(185);
        @include break-min(1024px) {
          margin-top: rem(250);
        }
        &_group {
          $root: &;
          position: relative;
          padding: 0 rem(15);
          @include break-min(1024px) {
            padding: 0 rem(35);
          }
          &_des {
            -webkit-transition: opacity 0.8s ease-in-out;
            -moz-transition: opacity 0.8s ease-in-out;
            transition: opacity 0.8s ease-in-out;
            overflow: hidden;
            opacity: 0;
            position: absolute;
            @include break-max(1023px) {
              margin-top: rem(90);
            }
            &_box {
              &_text {
                overflow: auto;
                max-height: rem(230);
                @include break-min(1367px) {
                  max-width: rem(600);
                  width: rem(600);
                }
                @include break-max(1023px) {
                  max-height: rem(200);
                  width: rem(110);
                }
                @include break-max(640px) {
                  max-height: rem(200);
                }
                *{
                  @include mobile-down {
                    font-size: rem(9)!important;
                    line-height: rem(12)!important;
                  }
                }
              }
            }
          }
          &_img {
            img {
              @include break-min(1024px) {
                width: rem(316);
                height: rem(316);
              }
              @include break-max(1023px) {
                display: none;
              }
            }
          }
          &._benefits {
            #{$root}_des {
              max-width: rem(423);
              background-image: url(~assets/images/line_tab_benefits.svg);
              background-repeat: no-repeat;
              background-position: left top;
              margin-left: rem(45);
              top: rem(-397);

              @include break-max(1023px) {
                background-image: url(~assets/images/line_tab_benefits_mobile.svg);
                background-position: right top;
                top: rem(-250);
                right: 0;
                margin-left: 0;
                max-width: rem(235);
              }
              &_box {
                margin-top: rem(-14);
                padding-left: rem(40);
                @include break-max(1023px) {
                  margin-top: rem(-10);
                  padding-left: 0;
                  padding-right: rem(30);
                }
                @include break-max(375px) {
                  &_title {
                    padding-left: rem(10);
                  }
                  &_text {
                    padding-left: rem(10);
                  }
                }
              }
            }
            #{$root}_img {
              @include break-min(1024px) {
                margin-left: rem(150);
              }
              @include break(1366px, 1799px) {
                margin-left: rem(115);
              }
              @include break(1200px, 1365px) {
                margin-left: rem(108);
              }
              @include break(1024px, 1199px) {
                margin-left: rem(92);
                margin-top: rem(57);
              }
            }
          }

          &._college {
            #{$root}_des {
              background-image: url(~assets/images/line_tab_college.svg);
              background-repeat: no-repeat;
              background-position: left top;

              @include break-min(1024px) {
                top: rem(-397);
                margin-left: rem(193);
                width: rem(600);
                max-width: rem(600);
              }

              @include break-max(1023px) {
                background-image: url(~assets/images/line_tab_college_mobile.svg);
                top: rem(-244);
                left: rem(38);
                margin-left: 0;
              }
              &_box {
                padding-left: rem(187);
                margin-top: rem(-14);
                @include break-max(1023px) {
                  margin-top: rem(-10);
                  padding-left: rem(25);
                  &_title {
                    h3 {
                      font-size: rem(14);
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
            #{$root}_img {
              position: absolute;
              left: 0;
              top: 0;

              @include break-min(1024px) {
                margin-top: rem(-175);
                margin-left: rem(-115);
              }
              @include break(1440px, 1799px) {
                margin-top: rem(-112);
                margin-left: rem(-97);
              }
              @include break(1366px, 1439px) {
                margin-top: rem(-113);
                margin-left: rem(-76);
              }
              @include break(1200px, 1365px) {
                margin-top: rem(-102);
                margin-left: rem(-55);
              }
              @include break(1024px, 1199px) {
                margin-top: rem(-28);
                margin-left: rem(-42);
              }
            }
          }
          &._participants {
            #{$root}_des {
              background-image: url(~assets/images/line_tab_participants.svg);
              background-repeat: no-repeat;
              background-position: left top;

              @include break-min(1024px) {
                margin-top: rem(282);
                margin-left: rem(412);
              }
              @include break-max(1023px) {
                background-image: url(~assets/images/line_tab_participants_mobile.svg);
                margin-top: rem(105);
                margin-left: rem(140);
                background-size: contain;
              }
              @include break-max(640px) {
                margin-left: rem(57);
              }
              &_box {
                margin-top: rem(70);
                margin-left: rem(-60);

                @include break-min(1024px) {
                  max-width: rem(400);
                }
                @include break(1366px, 1799px) {
                  max-width: rem(600);
                  width: rem(600);
                }
                @include break(1024px, 1365px) {
                  max-width: rem(230);
                }
                @include break-max(1023px) {
                  max-width: rem(200);
                  margin-left: 0;
                  margin-top: rem(40);
                }
              }
            }
            #{$root}_img {
              position: absolute;
              left: 0;
              top: 0;

              @include break-min(1024px) {
                margin-top: rem(-140);
                margin-left: rem(363);
              }
              @include break(1366px, 1799px) {
                margin-top: rem(-114);
                margin-left: rem(265);
              }
              @include break(1200px, 1365px) {
                margin-top: rem(-105);
                margin-left: rem(245);
              }
              @include break(1024px, 1199px) {
                margin-top: rem(-93);
                margin-left: rem(213);
              }
            }
          }
          &._employers {
            margin-top: rem(-66);
            padding-left: rem(20);
            position: relative;
            padding-bottom: 0;
            #{$root}_des {
              background-image: url(~assets/images/line_tab_employers.svg);
              background-repeat: no-repeat;

              @include break-min(1337px) {
                // top: rem(16);
                // right: rem(-283);
                transform: translate(-7%, 3%);
                background-position: 34% 22%;
              }
              @include break(1024px, 1336px) {
                background-image: url(~assets/images/line_tab_employers_ipad.svg);
                top: rem(100);
                right: rem(-139);
              }
              @include break-max(1023px) {
                background-image: url(~assets/images/line_tab_employers_mobile.svg);
                margin-left: rem(-87);
                margin-top: rem(85);
                top: 0;
                left: -2px;
                right: 0;
                background-position: 3% 0%;
                @include break-max(640px) {
                  margin-left: rem(7);
                }
              }
              &_box {
                margin-top: rem(134);
                max-width: rem(400);
                margin-left: rem(300);
                @include break(1024px, 1336px) {
                  margin-top: rem(289);
                  margin-left: 0;
                  padding-right: rem(50);
                }
                @include break-max(1023px) {
                  margin-top: rem(15);
                  margin-left: 0;
                  max-width: rem(200);
                }
                @include break-max(375px) {
                  &_title {
                    padding-right: rem(10);
                  }
                  &_text {
                    padding-right: rem(10);
                  }
                }
              }
            }
            #{$root}_img {
              position: absolute;
              left: 0;
              top: 0;

              @include break-min(1024px) {
                margin-top: rem(-245);
                margin-left: rem(-221);
              }
              @include break(1440px, 1799px) {
                margin-top: rem(-157);
                margin-left: rem(-197);
              }
              @include break(1366px, 1439px) {
                margin-top: rem(-157);
                margin-left: rem(-166);
              }
              @include break(1200px, 1365px) {
                margin-top: rem(-137);
                margin-left: rem(-130);
              }
              @include break(1024px, 1199px) {
                margin-top: rem(-108);
                margin-left: rem(-108);
              }
            }
          }
          &_des {
            max-width: rem(600);
            &_img {
              max-width: 100%;
              height: auto;
              width: rem(315);
              margin-top: rem(108);
              margin-left: rem(80);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              position: relative;
              @include break-max(1024px) {
                display: none;
              }
            }
            .a-text {
              margin-top: rem(10);
              margin-bottom: rem(20);
              @include break-max(1023px) {
                margin-top: 0;
                margin-bottom: rem(10);
              }
            }
          }
        }
        .m-tab-benefits-list_wrap_right_content_group_img_not-hightlight {
          opacity: 1;
          height: auto;
        }
        .m-tab-benefits-list_wrap_right_content_group_img_hightlight {
          opacity: 1;
          height: auto;
        }
        .hidden {
          .m-tab-benefits-list_wrap_right_content_group_img_not-hightlight {
            overflow: visible;
            opacity: 1;
            height: auto;
          }
        }
        .expaned {
          .m-tab-benefits-list_wrap_right_content_group {
            &_des {
              overflow: visible;
              padding-bottom: rem(370);
              @include break-max(1023px) {
                padding-bottom: rem(250);
              }
            }
            &._benefits {
              #{$root}_des {
                opacity: 1;
              }
            }
            &._college {
              #{$root}_des {
                opacity: 1;
                animation-delay: .2s;
                transition: opacity 1s ease-in-out;
              }
            }
            &._employers {
              #{$root}_des {
                opacity: 1;
                animation-delay: 2s;
                transition: opacity 2s ease-in-out;
              }

              padding-bottom: rem(450);

              @include break-max(1023px) {
                padding-bottom: rem(250);
              }

              @include break(768px, 1023px) {
                padding-bottom: rem(400);
              }

              @include break(1024px, 1336px) {
                padding-bottom: rem(550);
              }

              &_des {
                height: auto;
              }
            }
            &._participants {
              #{$root}_des {
                opacity: 1;
                animation-delay: 3s;
                transition: opacity 3s ease-in-out;
              }
              padding-bottom: rem(350);
              @include break-max(1023px) {
                padding-bottom: rem(260);
              }
              @include break(768px, 1023px) {
                padding-bottom: rem(400);
              }
              @include break(1024px, 1400px) {
                padding-bottom: rem(550);
              }
            }
            
          }
          .m-tab-benefits-list_wrap_right_content_group_img_hightlight {
            overflow: visible;
            opacity: 1;
            height: auto;
          }
        }
      }
    }
  }
}
