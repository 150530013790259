.a-iconButton {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: 0;
        border: none;
    }
    &-radius {
        border-radius: 50%;
    }
    &-bgBlue {
        padding: 5px;
        background-color: $bg_blue;
    }
    &-bgOriolesOrange{
        padding: 5px;
        background-color: $orioles_orange;
    }
    &-bgYellow {
        padding: 5px;
        background-color: $bg_yellow;
    }
}
