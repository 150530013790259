.m-fileinfo {
  display: flex;

  &_content{
    flex: 1;
    display: flex;
    padding: rem(10) rem(20);
    border-radius: rem(5);
    justify-content: space-between;
    align-items: center;
    color: $brand_blue_dark;
    background-color: $white;
    transition: all $transition ease;
    cursor: pointer;
    margin-top: rem(10);
    @include hover {
      box-shadow: 0 3px 6px 0 rgba(25, 93, 118, 0.2);
    }  
  }

  &_thumbinfo {
    min-width: rem(200);
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: rem(10);
    @include desktop-down{
      max-width: rem(200);
    } 
    @include break-max(1365px){
      max-width: 100%;
    }
  }
  &_icon {
    flex: 0 0 rem(33);
    max-width: rem(33);
    min-width: rem(33);
    height: rem(42);
  }
  &_label {
    padding-left: rem(10);
    font-size: rem(16);
    white-space: unset;
    word-break: break-all;
    width: rem(152);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex-grow: 1;
    @include max-desktop-down {
      width: auto;
    }

    @include mobile-down {
      font-size: rem(12);
      width: rem(178);
    }
  }

  &_upload{
    flex-shrink: 0;
    min-width: rem(100);
    margin-right: rem(10);
    @include mobile-down {
      font-size: rem(12);
    }
  }

  &_size {
    flex-shrink: 0;
    min-width: rem(65);
    text-align: right;
    @include mobile-down {
      font-size: rem(12);
    }
  }

  
  &_texttooltip {
    min-width: rem(122);
    height: rem(21);
    position: relative;
    display: flex;
    border-radius: rem(4);
    justify-content: center;
    align-items: center;
    background-image: $gradient_icon_late_submistion;
    border: $border_yellow;
    font-family: $font-muli;
    font-size: rem(12);
    line-height: rem(34);
    color: $white;
    margin-left: rem(10);
    margin-right: rem(10);

    @include mobile-down {
      flex: none;
      min-width: rem(88);
      font-size: rem(10);
    }
  }

  &_btnVersion {
    position: relative;
    margin-left: rem(20);
    .a-button {
      padding: rem(8);
      font-size: rem(12);
      margin: auto;
      line-height: 1.25;
    }
    &.disabled-button {
      .a-button {
        font-size: rem(12);
        background: #f5f5f5;
        color: #707070;
        border: 1px solid #d5d5d5;
      }
    }
  }
}