.m-languageSelection {
  &_wrap {
    display: flex;
    list-style: none;
    height: rem(100);
    padding: 0 rem(20);
    align-items: center;
    position: relative;
    p {
      display: flex;
      align-self: center;
      font-size: rem(24);
      font-family: $font-muli;
      list-style-type: none;
      color: $white;
      line-height: rem(36);
    }
  }
  &_avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: rem(10);
    cursor: pointer;

    &_country {
      width: rem(24);
      height: rem(24);
      margin-right: rem(10);

      @include tablet {
        margin-right: rem(7);
        margin-left: rem(11);
      }

      @include mobile-down {
        margin-right: rem(7);
        margin-left: rem(11);
      }
    }

    &_short_name_country {
      width: auto;
      height: rem(15);
      font-family: $font-muli;
      font-size: rem(12);
      line-height: rem(15);
      color: $white;
      margin-right: rem(5);

      @include tablet {
        font-size: rem(24);
      }
    }
  }
  &_submenu {
    height: auto;
    position: absolute;
    transition: all 0.5s ease;
    top: 100%;
    padding-top: rem(55);
    margin-left: rem(-50);
    background-color: darkcyan;
    @include tablet-down {
      margin-left: 0;
      left: calc(50% - 73px);
      padding-top: rem(40);
    }

    @include mobile-down {
      margin-left: 0;
      left: calc(50% - 73px);
    }

    &_wrap{
      position: relative;
    }

    ul {
      position: absolute;
      float: left;
      padding: 0;
      list-style: none;
      border: rem(2) solid $chardonnay;
      border-radius: rem(3);
      width: rem(150);
      display: flex;
      flex-direction: column;
      z-index: 1;
      background-color: rgba(22, 34, 42, 0.9);
      overflow: hidden;
      .hover {
        flex: 1;
        display: flex;
        padding: rem(18) rem(46);
        justify-content: center;
        align-items: center;
        border-right: 0;
        border-left: 0;
        color: $white;
        font-size: rem(16);
        font-family: $font-muli;
        font-weight: bold;
        border-top: $bg_white;
        border-bottom: $bg_white;
        cursor: pointer;
        &:first-child {
          border-top: 0;
          border-bottom: 0;
        }
        &:last-child {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    
    .hover:hover {
      background-color: $bright-blue;
    }
    
  }

  &_triangle {
    position: absolute;
    background-color: $bg_yellow;
    top: rem(-15);
    left: rem(67);
    width: rem(12);
    height: rem(12);
    border-top-right-radius: 28%;
    z-index: 2;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

    &:before, &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      width:  rem(12);
      height: rem(12);
      border-top-right-radius: 0%;
    }
    &:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    &:after {
      transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
  }

  &_triangle_override {
    position: absolute;
    background-color: $bg_night_blue;
    top: rem(-11.5);
    left: rem(68);
    width: rem(10);
    height: rem(10);
    border-top-right-radius: 0%;
    z-index: 2;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

    &:before, &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      width:  rem(10);
      height: rem(10);
      border-top-right-radius: 0%;
    }
    &:before {
      top: 0;
      left: 0;
      transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    &:after {
      transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
  }

}
