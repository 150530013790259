.o-chart {
    border-radius: 5px;
    border: solid 1px $brand_yellow;
    &_title{
        font-size: rem(50);
        line-height: rem(63);
        color:$brand_blue_dark ;
        font-weight: bold;
        font-family: $font;
        text-align: center;
        @include media-breakpoint-down(md){
            font-size: rem(30);
        }
        @include media-breakpoint-down(sm){
            font-size: rem(24);
        }
        @include media-breakpoint-down(xs){
            font-size: rem(16);
            line-height: rem(24);
        }
    }
    .dtaLabels{
        background-color: red;
    }
}
