.o-frameCarousel {
  width: '100%';
  height: '100%';
}

.t-frameCarousel {
  $root: &;
  padding: rem(48) rem(100) 0 rem(100);
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }

  .modal-content {
    display: flex;
    background-color: transparent;
    border: none;
    justify-content: center;
    height: 100%;

    .carousel {
      .carousel-control-prev {
        display: none;
        @include mobile-down {
          display: block;
          .carousel-control-prev-icon {
            display: none;
          }
        }
      }

      .carousel-control-next {
        display: none;
        @include mobile-down {
          display: block;
          .carousel-control-next-icon {
            display: none;
          }
        } 
      }
    }
  
    .slick-list .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #{$root}_btn_close {
      .a-button {
        position: absolute;
        right: -4%;
        top: 0;
        z-index: 1;
        @include max-desktop-down {
          right: -4%;
          top: rem(6);
        }
        @include tablet {
          top: rem(7);
          right: rem(-48);
        }
        @include mobile-down {
          top: 2%;
          right: rem(-8);
        }
      }

      &-fullscreen {
        .a-button {
          z-index: 100;
          position: fixed;
          top: rem(32);
          right: rem(32);
        }
      }
    }

    #{$root}_btn_open_fullscreen {
      .a-button {
        position: absolute;
        left: -4%;
        top: 0;
        z-index: 1;
        @include max-desktop-down {
          left: -4%;
          top: rem(6);
        }
        @include tablet {
          top: rem(7);
          left: rem(-48);
        }
        @include mobile-down {
          top: 2%;
          left: rem(-8);
        }
      }
    }
    
    @include mobile-down {
      transform: none;
    }
  }

  &_item {
    &_heading{
      @include textOverflow(2);
      > .a-heading {
        color: $brand_white;
        text-align: center;
        font-size: rem(24);
        font-family: $font-muli;
        width: 100%;
        @include mobile-down {
          font-size: rem(16);
        }
      }
    }
    &_content{
      background-color: $black05;
      height: 70vh;
      margin: rem(36) 0;
      @include mobile-down {
        margin: rem(10) 0;
      }

      &-fullscreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        margin: 0;
        z-index: 99;
      }
    }

    &_download{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &_iframe{
      width: 100%;
      height: 100%;
    }

    &_wrapImagePhoto{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &_imagePhoto{
      max-width: 100%;
      max-height: 100%;
    }

    &_pdf {
      overflow: auto;
      height: 100%;
      max-height: 100%;
      width: 100%;
      background-color: $white;
      @include scrollbars(8);
      .react-pdf__Page {
        margin-top: rem(10);
      }
      .react-pdf__Page__textContent {
        border: $bd_darkgrey;
        box-shadow: 0 rem(5) rem(5) rem(1) $light_grey;
        border-radius: rem(5);
      }
      .react-pdf__Page__annotations.annotationLayer {
        padding: rem(20);
      }
      .react-pdf__Page__canvas {
        margin: 0 auto;
      }
    }
  }

  .disabled-control-left {
    display: none;
  }

  .disabled-control-right {
    display: none;
  }

  .slick-arrow {
    width: rem(35);
    height: rem(35);
    z-index: $z-index-1000;
    &::before {
      background: transparent;
      font-family: $font-muli;
      font-weight: bold;
      font-size: rem(17);
    }
    &::after {
      content: "";
      width: rem(20);
      height: rem(35);
      position: absolute;
      background-color: red;
      top: 0;
    }
    &.slick-next {
      &::before {
        content: "Next page";
        position: absolute;
        top: rem(8);
        text-align: right;
        width: rem(120);
        @include max-desktop-down {
          content: "";
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: rem(138);
        background: url("~images/icons/angle-right.svg") no-repeat;
        background-size: contain;
        @include max-desktop-down {
          left: rem(40);
        }
      }
      @include max-desktop-down {
        right: -5.5%;
        transform: translate(-50%, 50%);
      }
    }
    &.slick-prev {
      &::before {
        content: "Previous page";
        position: absolute;
        top: rem(8);
        left: rem(-115);
        width: rem(120);
        @include max-desktop-down {
          content: "";
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: rem(-150);
        background: url("~images/icons/angle-left.svg") no-repeat;
        background-size: contain;
        @include max-desktop-down {
          content: "";
          left: rem(-20);
        }
      }
      @include max-desktop-down {
        left: -1%;
        transform: translate(-50%, 50%);
      }
    }
    @include mobile-down {
      &.slick-next, &.slick-prev {
        visibility: hidden;
      }
    }
  }

  @include max-desktop-down {
    padding: rem(16) rem(68) 0 rem(68);
  }

  @include mobile-down {
    padding: 0;
    position: absolute;
    top: -30%;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  #{$root}_wrapper_download {
    position: fixed;
    bottom: 3%;
  }
}
