/* MIXIN*/

@mixin max-desktop {
  @media (min-width: 1441px) {
    @content;
  }
}

@mixin max-desktop-down {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: 768px) and (max-width: 1199px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

@mixin mobile-down {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (min-width: 576px) and (max-width: 991px) {
    @content;
  }
}

@mixin small-mobile {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin small-mobile-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin max-width-center($pixel) {
  max-width: $pixel;
  margin-left: auto;
  margin-right: auto;
}

@mixin block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin break-max($pixel) {
  @media (max-width: $pixel) {
    @content;
  }
}

@mixin break-min($pixel) {
  @media (min-width: $pixel) {
    @content;
  }
}

@mixin break($pixel1, $pixel2) {
  @media (min-width: $pixel1) and (max-width: $pixel2) {
    @content;
  }
}

/* FORM */

@mixin reset-button {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0; // Iphone5
  outline: 0;
  cursor: pointer;
}

@mixin reset-input {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0; // Iphone5
  outline: 0;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
}

@mixin reset-autofill($color: #000) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background 5000s ease-in-out 0s;
    -webkit-text-fill-color: $color;
  }
}

@mixin textOverflow($number: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ($number);
}

@mixin scrollbars(
  $size: 12,
  $thumbColor: linear-gradient($bg_blue_dark, $bg_blue, $bg_blue_light),
  $trackColor: $white,
  $borderRadiusThumb: 5,
  $borderThumb: 2
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: rem($size);
    height: rem($size);
    margin-right: rem(10);
  }

  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: rem($borderRadiusThumb);
    border: rem($borderThumb) solid $trackColor;
  }

  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $thumbColor;
    scrollbar-track-color: $trackColor;
  }
}