.t-popupcourse{
  max-width: rem(823);
  background: linear-gradient(to bottom,$blue_dark,$blue_light);
  margin: auto;
  text-align: center;
  border:3px solid $brand_yellow;
  border-radius: rem(5);
  padding:0 rem(140);
  @include mobile-down {
    width: 100%;
    padding:0 rem(20);
  }
  .a-heading{
    font-weight: 500;
    margin-top: rem(50);
    margin-bottom: rem(53);
    @include mobile-down {
     font-size: rem(24);
     line-height: rem(32);
     margin-top: rem(30);
     margin-bottom: rem(33);
    }
  }
  .a-countdown{
    margin-bottom: rem(50);
    @include mobile-down {
      margin-bottom: rem(30);
    }
  }
  .t-popupcourse_alert{
    .a-text{
      max-width: rem(535);
      margin: auto; 
      @include mobile-down {
        font-size: rem(18);
        line-height: rem(24);
      }
    }
  }
  .a-button{
    margin: rem(50) auto;
    @include mobile-down {
      margin: rem(30) auto;
    }
  }
}