.o-footer {
  $root: &;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 ;
  font-family: $font-muli;
  color: $brand_white;
  background: #0f2027;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #0f2027),
    color-stop(0.477, #203a43),
    color-stop(1, #2c5364)
  );
  background: -webkit-linear-gradient(#0f2027, #203a43, #2c5364);
  background: -moz-linear-gradient(#0f2027, #203a43, #2c5364);
  background: -o-linear-gradient(#0f2027, #203a43, #2c5364);
  background: -ms-linear-gradient(#0f2027, #203a43, #2c5364);
  background: linear-gradient(#0f2027, #203a43, #2c5364);
  font-family: $font-muli;
  @include break-min(768px) {
    padding: 40px 0;
  }
  &-logo {
    &-pc {
      img {
        display: none;
        @include break-min(768px) {
          display: inline;
        }
      }
    }
    &-mobile {
      img {
        display: none;
        @include break-max(767px) {
          display: inline;
        }
      }
    }
  }
  &-top {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
  }
  &-menu {
    margin-top: 20px;
    padding: 20px 15px 0 15px;
    border-top: solid 1px #747381;
    width: 100%;
    display: flex;
    justify-content: center;
    @include break-min(768px) {
      margin-top: 30px;
      padding: 20px 15px;
    }
    .nav-link {
      color: $blue_gray_light;
      font-family: $font-muli;
      font-size: 20px;
      font-weight: 500;
      &:hover {
        color: $blue_hover;
      }
    }
  }
  &-text {
    font-size: 18px;
    @include break-max(767px) {
      font-size: 14px;
    }
  }
  &-social {
    .m-list {
      margin-top: 0;
      margin-bottom: 30px;
    }
    &-wrap {
      width: 23px;
      height: 23px;
      line-height: 23px;
      display: block;
      background-position: center;
      background-size: contain;
      img{
        max-width: rem(23);
      }
    }
  }
}
