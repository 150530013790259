.o-modalRegistration{
  width: 100%;
  min-height: 30vh;
  padding: rem(50) rem(138) rem(80) rem(138);
  @include break-max(1366px){
    padding-left: rem(70);
    padding-right: rem(70);
  }
  @include tablet-down {
    padding: rem(50);
  }
  @include small-mobile{
    padding-left: rem(20);
    padding-right: rem(20);
  }
  &_row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-77);
    @include break-max(1366px){
      margin: 0 rem(-20);
    }
  }
  &_personalTitle{
    margin-top: rem(50);
    padding-top: rem(50);
    padding-bottom: rem(20);
    border-top: 1px solid $gray_light;
    @include small-mobile{
      padding:rem(15) 0 ;
    }
  }
  &_button{
    padding-top: rem(30);
  }
}
.o-blockFeild{
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 0 rem(77);
  margin-top: rem(20);
  @include break-max(1366px){
    padding: 0 rem(20);
  }
  @include tablet-down {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include small-mobile{
    flex: 0 0 100%;
    max-width: 100%;
  }
  &-maxwidth{
    flex: 0 0 100%;
    max-width: 100%;
    @include break-max(1366px){
      padding: 0 rem(20);
    }
  }
  &-small{
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding: 0 rem(77);
    margin-top: rem(20);
    @include break-max(1366px){
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 rem(20);
    }
    @include small-mobile{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &_feild{
    margin-top: rem(20);
  }
  &_titleSmall{
    display: flex;
    width: 100%;
  }
  &_tooltip{
    margin-left: rem(10);
    display: inline-block;
    width: rem(16);
    height: rem(16);
    background: url("~images/icons/icon_tooltip.svg") no-repeat center / 100% auto;
  }
}