.p-notfound {
  .main {
    background-image: linear-gradient(
      to bottom,
      $blue_dark,
      $blue 48%,
      $blue_light
    );
    padding-top: rem(64.7);
    padding-bottom: rem(165);
  }

  &_img {
    display: flex;
    justify-content: center;
  }

  &_info {
    margin-top: rem(31.6);
    margin-bottom: rem(32);

    .a-text {
      line-height: rem(18);
      margin-top: rem(24);
    }
  }

  &_heading {
    .a-heading {
      font-size: rem(48);
      line-height: normal;
    }
  }

  &_gobackBtn {
    .a-button {
      margin: auto;
      width: rem(200);
    }
  }
}
