.m-textvalue{
  .a-text{
    font-size: 1rem;
    line-height: 1.25rem;
    @include mobile-down {
      font-size: 0.875rem;
    }
    span{
      margin-left: 0.3125rem;
      font-size: 0.875rem;
      color: $rick_black;
      font-weight: normal;
      @include mobile-down {
        font-size: 0.8125rem;
      }
    }
  }
  &-block{
    .a-text{
      span{
        margin-left: 0;
        margin-top: 0.3125rem;
        display: block;
        line-height: 1.29rem;
        word-break: break-all;
        @include mobile-down {
          margin-top: 0.25rem;
          line-height: 1.25rem;
        }
      }
    }
  }
}