.t-profileBanner {
  display: flex;
  width: 100%;
  height: rem(180);
  position: relative;
  overflow: hidden;

  @include mobile-down {
    height: rem(134.5);
  }

  $root: &;

  &_img_banner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-image: url("~images/t-profileBanner/profilebanner.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    color: $white;
    font-family: $font-muli;
    box-shadow: $shadow_inset_black;

    &::before {
      content: "";
      position: absolute;
      top: rem(-4);
      left: rem(-31);
      width: rem(105.7);
      height: rem(111.5);
      background-image: url("~images/t-profileBanner/red_puzzle.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;

      @include mobile-down {
        width: rem(32);
        height: rem(34);
        top: rem(-1);
        left: rem(-8);
      }
    }
  }

  &_image_avatar {
    position: absolute;
    height: 100%;
    z-index: 1;
    top: 0;
    right: rem(2);
    flex-shrink: 0;
    width: 23%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    @include desktop {
      width: 26%;
    }

    @include break(1366px, 1599px) {
      width: 30%;
    }

    &_name {
      height: 100%;
      background-color: $brand_blue_dark;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 110px;
      padding-left: 24%;
    }

    @include tablet-down {
      width: rem(239);
    }

    @include mobile-down {
      width: rem(181);
    }
  }

  #{$root}_wrapper_info {
    margin-left: 10%;
    margin-right: 25.5%;

    @include tablet-desktop {
      margin-left: 7%;
      margin-right: 25%;
    }

    @include tablet-down {
      display: none;
    }

    .row {
      display: flex;

      .a-text {
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        font-weight: bold;
        text-align: left;
        height: auto;
        margin-top: 0;
        margin-bottom: 0;
        line-height: rem(18);
        font-size: rem(14);
        word-break: break-word;

        @include tablet-down {
          font-size: rem(12);
        }
      }

      :last-child {
        font-weight: normal;
        @include textOverflow(2);
      }
    }

    &_line {
      display: flex;
      width: 100%;
      align-self: center;
      margin-top: rem(12);
      margin-bottom: rem(12);
      justify-content: center;

      #{$root}_line {
        height: rem(0.5);
        background-color: $white;
        width: 65%;
      }
    }
  }

  &_wrapper_button {
    display: none;

    .a-button {
      padding-left: rem(24);
      padding-right: rem(24);

      @include mobile-down {
        line-height: rem(15);
      }

      @include break-max(374px) {
        font-size: rem(12);
        padding-left: rem(18);
        padding-right: rem(18);
      }
    }

    @include tablet {
      display: flex;
      width: 78%;
      justify-content: center;
    }

    @include mobile-down {
      display: flex;
      padding-left: rem(31);
      margin-top: rem(13.8);
    }
  }

  &_title {
    margin-top: rem(10);
    margin-bottom: rem(10);

    .a-text {
      width: 93%;
      text-align: center;
      height: rem(46);
      font-size: rem(32);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;

      @include tablet {
        width: 78%;
        font-size: rem(24);
      }

      @include mobile-down {
        width: rem(124);
        padding-left: rem(31);
        font-size: rem(18);
        line-height: rem(23);
      }

      @include break-max(374px) {
        font-size: rem(12);
      }
    }

    @include mobile-down {
      margin-top: rem(15.9);
      margin-bottom: rem(10);
    }

    @include tablet {
      margin-top: rem(40);
      margin-bottom: rem(10);
    }
  }
}
