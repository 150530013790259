@mixin responTableCellMd {
    @include media-breakpoint-down(md) {
        padding: rem(16);
        font-size: rem(12);
    }
}

.o-listFiles {
    box-shadow: 0 3px 10px $shadow_black;
    padding-right: rem(20);

    @include break(768px, 1366px) {
        width: 100%;
    }

    &_date {
        &_wrapper {
            max-height: rem(524);
            overflow: auto;
            padding-right: rem(25);

            &_tooltip {
                width: auto;
                position: relative;
                margin-left: rem(10);
                font-family: $font-muli;
                color: $white;

                >.a-text {
                    width: rem(10);
                    height: rem(10);
                    background-image: $gradient_icon_late_submistion;
                    border-radius: 50%;
                    font-size: rem(12);
                    font-size: rem(6);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &_text {
                    position: absolute;
                    top: rem(-30.5);
                    left: rem(-36.5);
                    width: rem(147);
                    

                    .a-text {
                        position: absolute;
                        width: rem(122);
                        height: rem(21);
                        display: flex;
                        font-weight: normal;
                        align-items: center;
                        justify-content: center;
                        background-image: $gradient_icon_late_submistion;
                        border: $border_yellow;
                        font-size: rem(12);
                        line-height: rem(34);
                        z-index: 1;
                        border-radius: rem(4);
                        left: rem(8);
                    }
                }

                &_triangle {
                    position: absolute;
                    background-color: $bg_yellow;
                    top: rem(16);
                    left: rem(37);
                    width: rem(10);
                    height: rem(10);
                    border-top-right-radius: 28%;
                    z-index: 0;
                    transform: rotate(119deg) skewX(-30deg) scale(1, 0.866);

                    &:before {
                        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
                    }

                    .m-languageSelection_triangle:after {
                        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
                    }

                    &_override {
                        position: absolute;
                        background: $gradient_icon_late_submistion;
                        top: rem(16);
                        left: rem(37.6);
                        width: rem(8);
                        height: rem(8);
                        border-top-right-radius: 0;
                        z-index: 1;
                        transform: rotate(119deg) skewX(-30deg) scale(1, 0.866);

                        :before {
                            top: 0;
                            left: 0;
                            transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
                        }

                        :after {
                            transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
                        }
                    }
                }
            }
        }

        &-red {
            color: $red;
        }
    }

    &_table {
        width: 100%;
        border-radius: 5px;

        &_img {
            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
            }
        }
        &_nameFile{
            max-width: rem(332);
            @include textOverflow(1);
        }

        thead {
            tr {
                th {
                    color: $brand_blue_dark;
                    text-align: center;
                    font-weight: bold;
                    padding: rem(20);
                    position: sticky;
                    top: 0;
                    background-color: $white;
                    z-index: 2;

                    &:first-child {
                        width: 15%;

                        @include media-breakpoint-down(lg) {
                            width: 25%;
                        }
                    }

                    @include responTableCellMd;
                }
            }
        }

        tbody {
            tr {
                color: $manatee;

                &:hover {
                    color: $brand_blue_dark;
                    cursor: pointer;
                }

                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }

                td {
                    font-weight: bold;
                    text-align: center;
                    padding: rem(20);
                    border-bottom: solid 1px $manatee;
                    transition: $transition ease;

                    &:first-child {
                        text-align: left;
                    }

                    &:nth-child(2) {
                        max-width: 15.125rem;
                        flex-wrap: wrap;
                        .o-listFiles_date {
                            display: flex;
                            align-items: center;
                        }
                    }

                    @include responTableCellMd;

                    @include mobile-down {
                        &:first-child {
                            text-align: center;
                        }
                    }

                    .a-image-ratio {
                        margin-right: rem(20);
                        width: rem(50);
                        min-width: rem(50);

                        @include media-breakpoint-down(md) {
                            width: rem(30);
                        }

                        @include mobile-down {
                            margin: auto;
                        }

                        &:before {
                            padding-bottom: 59/50 * 100%;
                        }
                    }

                    .o-listFiles_table_img {
                        min-width: rem(332);
                        @include small-mobile{
                            min-width: rem(150);
                        }
                        .a-image-ratio_img {
                            width: rem(57);
                            object-fit: contain;
                        }
                    }
                }

                .o-listFiles_td-btn {
                    .a-button {
                        padding: rem(8);
                        margin: auto;
                        line-height: 1.25;
                        font-size: rem(12);
                    }
                }

                .disabled-button {
                    .a-button {
                        background: #f5f5f5;
                        color: #707070;
                        border: 1px solid #d5d5d5;
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar-track {
        margin-top: rem(64);
        margin-bottom: rem(20);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: rem(10);

        @include media-breakpoint-down(md) {
            margin-top: rem(52);
        }
    }
}