.t-layout{
  background-image: url("~images/background_home.jpg");
  background-size: 100% auto;

  @include mobile-down {
    padding-top: rem(96);
  }
  .main{
    min-height: 70vh;
  }
}
