// FONTS
$font: "Muli", serif;
$font-muli: "Muli", serif;
// TRANSITION
$transition: 0.25s;
$transitionFast: 0.15s;
$transitionLong: 0.5s;
// Z-index
$z-index-1:1;
$z-index-2:2;
$z-index-3:3;
$z-index-10:10;
$z-index-998: 998;
$z-index-999:999;
$z-index-1000: 1000;
// BRAND COLOR
$anti_flash_white: #ecf4f6;
$brand_white: #ffffff;
$white: #ffffff;
$black: #000000;
$bg_white: 1px solid white;
$brand_blue_dark: #195d76;
$brand_gray: #707070;
$bd-gray: 1px solid #707070;
$bd_darkgrey: 1px solid darkgrey;
$blue_gray_light: #c9d2d8;
$brand_body: #f5f8fc;
$brand_yellow: #ffc371;
$alpha_black_16: rgba(0, 0, 0, 0.16);
$alpha_black_2:rgba(0,0,0,0.2);
$bg_blue_dark: #00468c;
$bg_blue_light: #4ca1af;
$bg_blue: #00468c;
$bg_red_dark: #ff636d;
$bg_red_light: #ff5f6d;
$bg_red: #b53f24;
$bg_yellow: #ffc371;
$blue_dark: #0f2027;
$blue_gray_dark: #747381;
$blue_hover: #3979f3;
$blue_light: #2c5364;
$blue-lighter: #8e9eab;
$blue: #203a43;
$border_gray: solid 1px #ecf2f9;
$border_light_gray: solid 1px #ced3d9;
$border_yellow: solid 1px #ffc371;
$brand_red_dark: #ff636d;
$cadet: #5c6572;
$chardonnay: #ffc371;
$dark_jungle_green: #16222a;
$deep_space_sparkle: #3a6073;
$dim_gray: #616b74;
$gray_light_2: #ecf2f9;
$gray_light:#ced3d9;
$green:#38c976;
$midnight_blue_dark: #00468c;
$midnight_blue: #4ca1af;
$orioles_orange: #fc4529;
$red:#fc4529;
$rick_black: #010607;
$shadow_black: rgba(0,0,0,0.05);
$ufo_green: #38c976;
$blue_dark_1:#16222a;
$blue_light_1:#3a6073;
$white-light:#ced3d9;
$gray_dark:#5c6572;
$bg_night_blue:#16222a;
$bg_night_blue_opcatity_09: rgba(22, 34, 42, 0.9);
$bg_light_blue:#3a6073;
$light_gray:#ced3d9;
$light_gray_pale:#d5d5d5;
$white_smoke: #f5f5f5;
$ghost_white: #f6f8fc;
$dodger_blue: #1a92eb;
$blue_deep:#0d196a;
$blue_sky:#5efef9;
$isabelline: #efefef;
$persian_green: #00a78d;
$manatee: #9aa3aa;
$platinum: #e6eaed ;
$topaz_alpha: rgba(255, 195, 113, 0.2);
$oriolesOrange_alpha: rgba(252, 69, 41, 0.1);
$ufoGreen_alpha: rgba(56, 201, 118, 0.2);
$bright-blue: #375567 ;
$shadow_alpha:rgba(92, 101, 114, 0.2);
$gray-light-3:#8a9099;
$seashell : #fff1f0 ;
$lg_light_blue_to_dark_blue: 90deg, #68c8ea 0%, #195d76 100%;
$box_shadow_light: 0 3px 6px 0 rgba(0,0,0,0.16);
$box_shadow_grey: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
$light_grey: #ccc;
$raspberry:#f40b5d;
$light_yellow: #fce197;
$shadow_inset_black: inset 0 0 32px -9.6px #010607;
$shadow_text_light_grey: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
$color_text_grey: rgba(92, 101, 114, 0.4);
$tahiti_radial_gradient: radial-gradient(#ee6723, #d65c1f, #be521c, #a64818);
$gradient_icon_late_submistion: linear-gradient(50deg, #b53f24 18%, #ff5f6d 87%);
$black05: rgba($black, 0.5);
$azure: #007bff;
