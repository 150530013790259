.a-checkbox {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  &-bg-white {
    .a-checkbox-input {
      &::before {
        background: $white;
      }
      &::after {
        background-image: url("~images/icons/icon_check_blue.svg");
      }
    }
  }
  &-input {
    position: relative;
    display: inline-block;
    width: rem(24);
    height: rem(24);
    flex-shrink: 0;
    &::before {
      cursor: pointer;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 1px solid;
      border-radius: rem(5);
      border-color: $brand_yellow;
      background-image: linear-gradient(
        to bottom,
        $bg_night_blue,
        $bg_light_blue
      );
    }

    &::after {
      background-image: url("~images/icons/icon_check.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      width: rem(15);
      height: rem(20);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-checked {
    &:after {
      content: "";
    }
  }
  &-label {
    padding-left: rem(10);
    font-size: rem(18);
    color: $rick_black;
  }
}
.a-checkboxCustom{
  $root:&;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &_input{
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &_checkmark{
    margin-right: rem(3);
    left: 0;
    position: relative;
    height: rem(24);
    width: rem(24);
    border: 1px solid $brand_yellow;
    border-radius: rem(5);
    background-image: linear-gradient(
      to bottom,
      $bg_night_blue,
      $bg_light_blue
    );
    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      background: url("~images/icons/icon_check.svg") no-repeat center center;
    }
  }
  &_input:checked ~ #{$root}_checkmark::after{
    display: block; 
  }
}