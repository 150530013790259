.t-contactform {
  width: rem(1088);
  padding: rem(50) rem(138);
  margin: auto;
  background: $white;
  border: solid 1px $brand_gray;
  @include tablet-down {
    padding: rem(50) rem(40);
    width: 100%;
  }
  @include small-mobile {
    padding: rem(20);
    width: 100%;
  }
  .a-heading {
    font-family: $font-muli;
    text-align: center;
    color: $cadet;
    line-height: 0.75;
    margin-bottom: rem(50);
    @include small-mobile {
      font-size: rem(24);
      margin-bottom: rem(30);
    }
  }
  .a-text {
    margin-bottom: rem(20);
    @include break-max(375px) {
      font-size: rem(16);
    }
  }
  &_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(30);
    @include small-mobile {
      display: block;
      margin-bottom: 0;
    }
    &_block {
      @include small-mobile {
        margin-bottom: rem(10);
      }
      .a-input {
        min-width: rem(270);
        @include small-mobile {
          min-width: unset;
        }
        .a-input_error {
          font-size: rem(11);
          bottom: calc(100% + 5px);
          .a-text{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &_message {
    position: relative;
    .a-input_error {
      color: $bg_red_dark;
      font-size: rem(11);
      bottom: rem(255);
      @include small-mobile {
        bottom: rem(161);
      }
    }
    .m-textarea_input {
      min-height: rem(244);
      padding: rem(12) rem(20);
      resize: unset;
      font-size: rem(16);
      color: $gray-light-3;
      @include small-mobile {
        min-height: rem(150);
      }
      &::placeholder {
        font-size: rem(16);
      }
    }
  }
  &_btn {
    margin-top: rem(50);
    display: flex;
    justify-content: center;
    .a-button {
      margin: 0 rem(38);
      @include small-mobile {
        margin: 0 rem(10);
      }
      &:first-child {
        color: #010607;
      }
      &-bold {
        @include small-mobile {
          min-width: rem(120);
        }
      }
    }
  }
}
