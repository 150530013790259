.m-course-support-carousel-list {
  $root: &;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 40px #000;

  img {
    max-width: 100%;
    height: auto;
    width: 100%;
    @include break-min(768px) {
      max-width: 577px;
    }
  }

  &_thumbnail {
    width: 100%;
    position: relative;
    &:before {
      width: 100%;
      height: 100%;
      content: "";
      background-image: url("~assets/images/bg_gradient_down_blue.svg");

      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &_bg {
      &:before {
        width: 100%;
        height: 100%;
        content: "";
        background-image: url("~assets/images/bg_pattern.svg");
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.1;
      }
    }
  }
  &_bg {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: url("~assets/images/bg_course_support_marterials.jpg")
      no-repeat center;
    padding-top: 180px;
    padding-bottom: 140px;
    @include tablet {
      background-size:cover;
    }
    .container {
      @include break-max(768px) {
        max-width: 100%;
        margin: 0;
        padding: 0;
      }
    }
    @include break-max(768px) {
      background: none;
      padding-top: 0;
      padding-bottom: 0;
      &:before {
        background: none;
      }
    }
  }
  &_bg_thumbs {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    height: 0;
    h3 {
      padding: 50px 0 30px 0;
      @include break-min(768px) {
        padding: 50px 0 30px 0;
      }
    }
  }
  &_item {
    &_wrap {
      display: flex;
      align-items: center;
      max-height: 100%;
      position: relative;
      padding: 30px 35px;
      color: $brand_white;
      @include break-min(768px) {
        padding: 50px 0;
      }
      @include break-max(768px) {
        padding: 0;
        .m-courseSelection {
          padding: 20px 68px;
        }
      }
      &_button_onclick {
        display: flex;
        justify-content: center;
        margin-top: -70px;
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
      &_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        @include break-max(768px) {
          text-align: center;
          padding: 40px 15px;
          background: url("~assets/images/bg_course_support_marterials.jpg")
            no-repeat center;
          background-size: cover;
        }
        &_title {
          position: relative;
          width: 100%;
          font-family: $font-muli;
          font-size: 60px;
          font-weight: bold;
          z-index: 2;
          h3 {
            font-size: rem(50);
            @include break-max(1200px) {
              font-size: 35px;
            }
            @include break-max(992px) {
              font-size: 25px;
            }
            @include mobile-down{
              font-size: rem(16);
            }
          }
        }
        &_des_text {
          position: relative;
          font-size: rem(24);
          font-family: $font;
          max-width: rem(674);
          max-height: rem(400);
          overflow: auto;
          z-index: 2;
          margin: rem(30) rem(15) 0 rem(15);
          * {
            margin-bottom: rem(36);
            padding-left: 0;
            // fontsize set fixed because not CKeditor edit
            @include mobile-down {
              font-size: rem(14)!important;
              line-height: rem(18)!important;
            }
          }
          @include break-max(768px) {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
            text-align: left;
          }
        }
      }
    }
  }

  &_pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0;
    padding-bottom: 100px;
    &_item {
      position: relative;
      width: 177px;
      min-height: 200px;
      border: solid 3px #ffc371;
      border-radius: 10px;
      background-color: $brand_white;
      max-width: 100%;
      margin: 8px;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      &_wrap {
        $root: &;
        position: relative;
        height: 100%;
        min-height: 300px;
        min-height: 200px;
        text-align: center;
        &_bg {
          color: red;
          &_icon {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 0;
            margin-top: 20px;
            &:focus {
              outline: none;
            }
          }
        }
        &_content {
          color: $brand_gray;
          padding: 0 20px 20px 20px;
          &_title {
            transition: 0.2s;
          }
          &_des {
            color: $brand_gray;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            font-size: 16px;
            line-height: 22.4px; /* fallback */
            margin-top: 20px;
            max-height: 65px; /* fallback */
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
            transition: 0.2s;

            @include break(768px, 1919px) {
              max-height: 45px;
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &-selection {
    position: relative;
    display: flex;
    align-items: center;
    &:first-of-type {
      z-index: 1;
    }
    &:last-of-type {
      margin-left: 88px;
      margin-top: -174px;
    }
    .m-courseSelection {
      &:last-of-type {
        margin-left: -84px;
      }
    }
  }

  .swiper-wrapper {
    position: relative;
  }
  .swiper-wrap {
    // position: relative;

    .swiper-button {
      width: calc(var(--swiper-navigation-size) / 38 * 19);
      color: #ffc371;
      margin-top: 30px;
      z-index: 5555;
      top: 50%;
      transform: translateY(-50%);
      &-prev {
        left: 5%;
        transform: translateX(-15%);
        &:focus {
          outline: none;
        }
      }
      &-next {
        right: 5%;
        transform: translateX(-15%);
        &:focus {
          outline: none;
        }
      }
    }
  }
  .expaned {
    .m-course-support-carousel-list_bg_thumbs {
      overflow: visible;
      opacity: 1;
      height: auto;
    }
  }
  .hidden {
    .m-course-support-carousel_wrap_button_onclick {
      margin-top: -120px;
    }
  }
}

.divider {
  width: 812px;
  height: 1px;
  background-color: $brand_white;
  margin: auto;
}
