.m-customInputDatePicker {
  position: relative;
  & > input {
    border: $border_light_gray;
    position: relative;
    font-size: rem(16);
    line-height: 1.25;
    padding: rem(11) rem(20);
    padding-right: rem(30);
    border-radius: rem(5);
    color: $gray-light-3;
    outline: 0;
    -webkit-appearance: none;
    &:focus {
      border-color: $brand_yellow;
    }
    &::placeholder {
      color: $brand_gray;
      opacity: 0.4;
      @include textOverflow(1);
    }
    &:disabled {
      background-color: transparent;
      color: $light_gray_pale;
      &::placeholder {
        color: $brand_gray;
        opacity: 0.4;
      }
    }
  }
  &_icon{
    position: absolute;
    top: 50%;
    right: rem(10);
    transform: translateY(-50%);
    width: rem(18);
    height: rem(15);
    background: url("~images/icons/icon_calender_blue.svg") no-repeat center / 100% auto;
  }
  &-error{
    & > input {
      border-color:$bg_red_dark!important;
    }
  }
  &_errormessage{
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0;
    @include textOverflow(1);
  }
}
.react-datepicker-wrapper{
  width: 100%;
  input{
    width: 100%;
  }
}
