.a-scrollTopButton {
  display: flex;
  width: rem(60);
  height: rem(60);
  background-color: $white;
  box-shadow: 0 rem(3) rem(6) 0 $alpha_black_16;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: $z-index-998;
  right: rem(32);
  cursor: pointer;

  @include mobile-down {
    width: rem(40);
    height: rem(40);
    right: rem(16);
  }
        
  @include tablet {
    right: rem(40);
  }
  
  @include tablet-desktop {
    right: rem(42);
  }

  &-show {
    display: flex;
  }

  &-hide {
    display: none;
  }
}