.o-course-more {
  background: #ff5f6d;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ff5f6d), color-stop(1, #ffc371));
  background: -webkit-linear-gradient(#ff5f6d, #ffc371);
  background: -moz-linear-gradient(#ff5f6d, #ffc371);
  background: -o-linear-gradient(#ff5f6d, #ffc371);
  background: -ms-linear-gradient(#ff5f6d, #ffc371);
  background: linear-gradient(#ff5f6d, #ffc371);

  &_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    h4 {
      margin-bottom:0;
    }
    @include break-min(992px) {
      min-height: 150px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
