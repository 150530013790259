.o-modal_children {
  display: flex;
  .a-image-ratio_img {
    border-radius: 5px;
    border: solid 2px $brand_yellow;
  }
  &_content {
    width: calc(100% - 15px);
    margin-left: rem(15);
    border-radius: 5px;
    border: solid 2px $brand_yellow;
    height: rem(621);
    background-color: $white;
    padding: rem(45) rem(40);
    &_info {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      &_item {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 10px;
        font-family: $font-muli;
        margin-bottom: rem(30);
        &_label {
          font-size: rem(16);
          font-weight: bold;
          font-style: normal;
          line-height: 1.13;
          color: $brand_blue_dark;
          min-width: rem(100);
          max-width: rem(100);
        }
        &_text {
          font-size: rem(24);
          font-weight: normal;
          line-height: 0.75;
          color: $brand_blue_dark;
          letter-spacing: normal;
        }
        &:last-child {
          flex: 0 0 100%;
          max-width: 100%;
          .o-modal_children_content_info_item_text {
            flex: 0 0 100%;
            max-width: 100%;
            border-radius: 5px;
            background-color: $white_smoke;
            padding: rem(20);
            margin-top: rem(22);
            line-height: 1.5;
            max-height: rem(274);
            overflow-y: scroll;
          }
        }
      }
    }
  }
  .a-button {
    min-width: rem(200);
    color: $white;
    border-radius: rem(20);
    margin: rem(20) auto 0 auto;
  }
}