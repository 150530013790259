.p-expertpublishcourse {
  $root: &;
  overflow-x: hidden;
  font-family: $font-muli;

  &_banner {
    padding-top: rem(103);

    @include tablet-down {
      padding-top: rem(20);
    }
  }

  &_list-participant {
    margin-left: rem(35);
  }

  &_list-subscription-participant {
    margin-left: rem(20);
  }

  &_free-course {
    margin-bottom: rem(20);

    &-title {
      display: flex;
      align-items: baseline;
      column-gap: rem(10);
      color: #10a88b;
    }
  }

  &_employee-work-folder-title {
    display: flex;
    align-items: baseline;
    column-gap: rem(10);
    color: #10a88b;
    margin-bottom: rem(10);
  }

  &_category-title {
    margin-left: rem(20);
    .a-text {
      color: $brand_blue_dark;
    }
  }

  &_participant-card {
    margin-bottom: rem(30);

    .o-participantCard {
      z-index: 1;

      &_img {
        height: rem(266);

        .a-text {
          top: unset;
        }
      }
    }
  }

  &_course {
    margin-bottom: rem(20);

    &_title {
      margin-bottom: rem(25);
      margin-left: rem(40);
      color: $brand_blue_dark;
    }
  }

  &_empty-text {
    padding-top: rem(50);
    padding-bottom: rem(50);

    .a-text {
      font-size: rem(50);
      line-height: rem(63);
      color: $light_gray_pale;
    }
  }

  &_empty-text-small {
    padding-top: rem(30);
    padding-bottom: rem(30);

    .a-text {
      font-size: rem(30);
      line-height: rem(50);
      color: $light_gray_pale;
    }
  }

  &_category {
    margin-bottom: rem(40);
  }

  &_actionContainer {
    padding-top: rem(20);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_actions {
    display: flex;
    flex-direction: row;
    margin-left: auto;

    .a-button {
      width: rem(200);
      position: relative;

      @include mobile-down {
        width: rem(135);
        height: auto;
      }
    }
  }

  @include break-max(992px){
    #{$root}_actionContainer{
      flex-direction: column-reverse;
      row-gap: rem(20);
      align-items: start;

      
    }

    #{$root}_breadcrumb{
      margin-bottom: rem(20);
    }
  }

  &_cardList {
    position: relative;
    z-index: 1;
    padding-bottom: rem(50);
    min-height: rem(486);
  }

  &_card {
    padding-top: rem(70);
    max-width: rem(260);
    .a-image_img{
      height:   rem(366);
    }
  }

  &_viewmoreBtn {
    padding-bottom: rem(50);
    display: flex;
    justify-content: center;

    .a-button {
      width: rem(200);
    }
  }

  &_approve{
    margin-right: rem(20);
  }

  &_action {
    position: relative;
    margin-right: rem(20);

    .a-button{
      &::after{
        content: '';
        position: absolute;
        top:50%;
        right: rem(20);
        border: solid $white;
        border-width: 0 rem(2) rem(2) 0;
        width: rem(10);
        height: rem(10);
        transform: translateY(-75%) rotate(45deg);
        -webkit-transform: translateY(-75%) rotate(45deg) ;
        transition: transform $transition linear;
      }
    }

    &-open{
      .a-button{
        &::after{
          transform: translateY(-50%) rotate(-135deg);
          -webkit-transform: translateY(-50%) rotate(-135deg) ;
          transition: transform $transition linear;
        }
      }
    }
  }

  &_actionTooltip {
    visibility: hidden;
    opacity: 0;
    width: rem(200);
    background-color: $white;
    border-radius: rem(5);
    border: solid rem(1) $brand_yellow;
    transition: all $transitionFast linear;
    position: absolute;
    top: rem(50);
    z-index: 2;

    &-show {
      visibility: visible;
      opacity: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: rem(-7);
      left: 50%;
      background-color: $white;
      width: rem(14);
      height: rem(14);
      transform: translateX(-50%) rotate(45deg);
      border-top: solid rem(1) $brand_yellow;
      border-left: solid rem(1) $brand_yellow;
      z-index: -1;
    }

    ul {
      padding-inline-start: 0;
      margin-block-end: 0;
    }
  }

  &_actionBtn {
    list-style-type: none;
    padding: rem(19) 0 rem(19) rem(20);
    font-weight: bold;
    color: $brand_gray;

    &:hover {
      background-image: linear-gradient(to right, $bg_red_light, $chardonnay);
      color: $white;
      cursor: pointer;
    }
  }

  &_filter {
    position: relative;
  }

  &_filterTooltip {
    visibility: hidden;
    opacity: 0;
    width: rem(536);
    background-color: $white;
    border-radius: rem(5);
    border: solid rem(1) $brand_yellow;
    transition: all $transitionFast linear;
    position: absolute;
    top: rem(62);
    right: 0;
    z-index: 2;
    padding: rem(17) rem(40) rem(40) rem(40);

    @include mobile-down {
      width: rem(288);
      padding: rem(17) rem(20) rem(20);
    }

    &-show {
      visibility: visible;
      opacity: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: rem(-16);
      right: 15%;
      background-color: $white;
      width: rem(32);
      height: rem(32);
      transform: rotate(45deg);
      border-top: solid rem(1) $brand_yellow;
      border-left: solid rem(1) $brand_yellow;
      z-index: -1;
    }

    &-heading {
      padding-bottom: rem(10);
    }

    &-sectionTitle {
      padding-top: rem(30);
      display: flex;
      align-items: center;

      @include mobile-down {
        padding-top: rem(14);
      }
      .a-text {
        display: inline-block;
        padding-right: rem(20);
      }
      &::after {
        content: "";
        flex-grow: 1;
        display: inline-block;
        height: rem(1);
        background-color: $platinum;
      }
    }
  }

  &_radioBtnGroup {
    display: flex;
    justify-content: space-between;
    padding-top: rem(20);

    @include mobile-down {
      display: inline-block;
    }
  }

  &_radioBtn {
    position: relative;
    display: inline-block;
    cursor: pointer;

    @include mobile-down {
      display: block;
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    label {
      cursor: pointer;
      padding-left: rem(34);
      @include mobile-down {
        font-size: rem(14);
      }
    }

    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      border: solid rem(2) $brand_yellow;
      border-radius: 50%;
      width: rem(24);
      height: rem(24);

      @include mobile-down {
        width: rem(18);
        height: rem(18);
        top: rem(2);
      }
    }

    &-checked {
      &::after {
        content: "";
        position: absolute;
        top: rem(6);
        left: rem(6);
        background-color: $brand_yellow;
        width: rem(12);
        height: rem(12);
        border-radius: 50%;

        @include mobile-down {
          top: rem(5);
          left: rem(3);
        }
      }
    }
  }

  &_searchInput {
    padding-top: rem(20);
    padding-bottom: rem(40);

    .a-input-search {
      @include mobile-down {
        padding-left: rem(20);
        padding-right: rem(20);
      }
    }

  }

  &_chooseBtn {
    display: flex;
    justify-content: center;

    .a-button {
      width: rem(200);
    }
  }
}
