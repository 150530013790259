.p-employerlistparticipants {
  $root: &;
  overflow-x: hidden;
  font-family: $font-muli;

  &_title {
    margin-top: rem(20);
  }

  &_banner {
    padding-top: rem(100);

    .o-banner {
      background-image: linear-gradient(264deg, $blue_sky, $blue_deep);
      box-shadow: inset 0 0 rem(32) rem(-9.6) $rick_black;

      &_heading {
        .a-heading {
          text-align: center;

          @include tablet {
            font-size: rem(32);
          }
        }
      }
    }

    @include tablet-down {
      padding-top: rem(20);
    }
  }

  &_actionContainer {
    padding-top: rem(20);
    display: flex;
    justify-content: space-between;

    @include break-max(992px) {
      align-items: center;
      flex-direction: column;
    }
  }

  &_breadcrumb {
    display: flex;
    align-items: center;

    > a {
      width: auto;
      height: rem(40);
      font-size: rem(32);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: rem(40);
      letter-spacing: normal;
      text-align: left;
      color: $cadet;
      padding-right: rem(30);
      border-right: rem(2) solid $brand_gray;
      text-decoration: underline;
      margin-right: rem(30);
    }

    @include break-max(992px) {
      margin-bottom: rem(20);
    }

    @include mobile-down {
      font-size: rem(24);
    }

    &_text_filter {
      display: flex;
      align-items: center;

      .a-text {
        &:nth-child(1) {
          width: auto;
          font-size: rem(16);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: rem(20);
          letter-spacing: normal;
          text-align: left;
          color: $brand_blue_dark;
        }

        &:nth-child(2) {
          display: flex;
          align-items: center;
          width: auto;
          font-size: rem(24);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: rem(24);
          letter-spacing: normal;
          text-align: left;
          color: $cadet;
          margin-left: rem(20);
          text-decoration: none;

          @include mobile-down {
            font-size: rem(18);
          }
        }
      }
    }
  }

  &_actions {
    display: flex;
    flex-direction: row;

    .a-button {
      width: rem(200);
      position: relative;
      box-shadow: 0 rem(3) rem(6) 0 rgba(0, 0, 0, 0.16);

      @include mobile-down {
        width: rem(135);
        height: auto;
      }
    }
  }

  &_cardList {
    position: relative;
    z-index: 1;
  }

  &_card {
    padding-top: rem(30);
    max-width: rem(260);

    .a-image_img {
      height: rem(366);
    }
  }

  &_emptyText {
    padding-top: rem(80);
    padding-bottom: rem(50);

    .a-text {
      font-size: rem(25);
      color: $light_gray_pale;
    }
  }

  &_viewmoreBtn {
    padding-top: rem(50);
    padding-bottom: rem(50);
    display: flex;
    justify-content: center;

    .a-button {
      width: rem(200);
    }
  }

  &_approve {
    margin-right: rem(20);
  }

  &_action {
    position: relative;
    margin-right: rem(20);

    .a-button {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: rem(20);
        border: solid $white;
        border-width: 0 rem(2) rem(2) 0;
        width: rem(10);
        height: rem(10);
        transform: translateY(-75%) rotate(45deg);
        -webkit-transform: translateY(-75%) rotate(45deg);
        transition: transform $transition linear;
      }
    }

    &-open {
      .a-button {
        &::after {
          transform: translateY(-50%) rotate(-135deg);
          -webkit-transform: translateY(-50%) rotate(-135deg);
          transition: transform $transition linear;
        }
      }
    }
  }

  &_actionTooltip {
    visibility: hidden;
    opacity: 0;
    width: rem(200);
    background-color: $white;
    border-radius: rem(5);
    border: solid rem(1) $brand_yellow;
    transition: all $transitionFast linear;
    position: absolute;
    top: rem(50);
    z-index: 2;

    &-show {
      visibility: visible;
      opacity: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: rem(-7);
      left: 50%;
      background-color: $white;
      width: rem(14);
      height: rem(14);
      transform: translateX(-50%) rotate(45deg);
      border-top: solid rem(1) $brand_yellow;
      border-left: solid rem(1) $brand_yellow;
      z-index: -1;
    }

    ul {
      padding-inline-start: 0;
      margin-block-end: 0;
    }
  }

  &_actionBtn {
    list-style-type: none;
    padding: rem(19) 0 rem(19) rem(20);
    font-weight: bold;
    color: $brand_gray;

    &:hover {
      background-image: linear-gradient(to right, $bg_red_light, $chardonnay);
      color: $white;
      cursor: pointer;
    }
  }

  &_filter {
    position: relative;
  }

  &_filterTooltip {
    visibility: hidden;
    opacity: 0;
    width: rem(536);
    background-color: $white;
    border-radius: rem(5);
    border: solid rem(1) $brand_yellow;
    transition: all $transitionFast linear;
    position: absolute;
    top: rem(62);
    right: 0;
    z-index: 2;
    padding: rem(17) rem(40) rem(40) rem(40);

    @include mobile-down {
      width: rem(296);
      padding: rem(17) rem(20) rem(20);
      transform: translate(27%, 0);
    }

    @include tablet {
      transform: translate(31%, 0%);
    }

    &-show {
      visibility: visible;
      opacity: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: rem(-16);
      right: 15%;
      background-color: $white;
      width: rem(32);
      height: rem(32);
      transform: rotate(45deg);
      border-top: solid rem(1) $brand_yellow;
      border-left: solid rem(1) $brand_yellow;
      z-index: -1;

      @include mobile-down {
        right: 44%;
      }

      @include tablet {
        right: 47%;
      }
    }

    &-heading {
      padding-bottom: rem(10);
    }

    &-sectionTitle {
      margin-top: rem(20);
      display: flex;
      align-items: center;

      @include mobile-down {
        margin-top: rem(10);
      }

      .a-text {
        display: inline-block;
        padding-right: rem(20);
      }

      &::after {
        content: "";
        flex-grow: 1;
        display: inline-block;
        height: rem(1);
        background-color: $platinum;
      }
    }
  }

  &_pulldown {
    margin-top: rem(10);
  }

  &_radioBtnGroup {
    display: flex;

    @include mobile-down {
      display: inline-block;
    }

    &.inlineBlock {
      display: inline-block;

      #{$root}_radioBtn {
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  &_radioBtn {
    position: relative;
    cursor: pointer;
    margin-top: rem(20);
    font-size: rem(18);

    &:last-child {
      margin-left: rem(20);

      @include mobile-down {
        margin-left: 0;
      }
    }

    @include mobile-down {
      display: block;
      margin-top: rem(10);
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    label {
      padding-left: rem(34);
      cursor: pointer;
      @include mobile-down {
        font-size: rem(14);
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      border: solid rem(2) $brand_yellow;
      border-radius: 50%;
      width: rem(24);
      height: rem(24);

      @include mobile-down {
        width: rem(18);
        height: rem(18);
        top: rem(7);
      }
    }

    &-checked {
      &::after {
        content: "";
        position: absolute;
        top: rem(6);
        left: rem(6);
        background-color: $brand_yellow;
        width: rem(12);
        height: rem(12);
        border-radius: 50%;

        @include mobile-down {
          top: rem(10);
          left: rem(3);
        }
      }
    }
  }

  &_searchInput {
    display: flex;

    @include mobile-down {
      display: inline-block;
    }

    .a-input-search {
      @include mobile-down {
        padding-left: rem(20);
        padding-right: rem(20);
      }
    }

  }

  &_chooseBtn {
    display: flex;
    justify-content: center;

    .a-button {
      width: rem(200);
    }
  }

  &_text {
    .a-text {
      color: $brand_yellow;
    }
  }
}
