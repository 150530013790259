.m-folder {
  max-width: rem(153);
  $root: &;
  &_icon {
    width: 100%;
    height: rem(111);
    cursor: pointer;
    background-image: url("~images/icons/folder-available.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &_label {
    font-size: rem(24);
    color: $brand_blue_dark;
    text-align: center;
    line-height: 1.25;
    font-weight: bold;
    margin-top: rem(20);
    word-break: break-word;
  }
  &-available {
    &:hover {
      #{$root}_icon {
        background-image: url("~images/icons/folder-hover.svg");
      }
    }
  }
  &-empty {
    #{$root}_icon {
      background-image: url("~images/icons/folder.svg");
    }
  }
}