@mixin FullAbsolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.o-cardPhase {
    max-width: rem(260);
    display: inline-block;
    width: 100%;
    &-open {
        .o-cardPhase_img {
            border: 2px solid $brand_yellow;
        }
        .o-cardPhase_info{  
            margin-top:rem(-22);
            height: rem(150);
            &_heading{
                transition: all $transition linear;
                margin-top: rem(-10);
            }
        }
        &:hover{
            .o-cardPhase_info{
                &_heading{
                    margin-top: rem(10);
                }
            }
        }
    }
    &_info {
        position: relative;
        z-index: 2;
        overflow: hidden;
        &_heading {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            font-size: rem(24);
            line-height: 1.9rem;
            color: $brand_blue_dark;
            align-items: center;
            margin-top: rem(10);
            span {
                font-size: rem(12);
                color: $rick_black;
                display: block;
                font-weight: normal;
                margin-left: 5px;
                line-height: 1;
            }
        }
        &_stat {
            display: flex;
            align-items: center;
            .a-icon {
                width: rem(18);
                height: rem(18);
            }
        }
        &_desc {
            margin-top: rem(10);
            color: $brand_blue_dark;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    &_img {
        border: 2px solid $dim_gray;
        border-radius: 5px;
        position: relative;
        transition: 0.2s ease;
        overflow: hidden;
        z-index: 1;
        &:hover {
            box-shadow: 0 3px 6px 0 rgba($blue_dark, 0.4);
            .a-image-card {
                .a-image_img {
                    transform: translate(-50%, -50%) scale(1.2);
                }
            }
        }
        .a-image-card {
            .a-image_img {
                width: 100%;
                transition: 0.2s ease;
            }
        }
    }
    &_button{
        transition: all $transition linear;
        display: flex;
        justify-content: center;
        opacity: 0;
    }
    &:hover{
        .o-cardPhase_button{
            opacity: 1;
        }
    }
    &_layer {
        @include FullAbsolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:before {
            content: '';
            @include FullAbsolute;
            background-image: linear-gradient(to top, #16222a, #3a6073);
            z-index: 1;
            opacity: 0.6;
        }
        span {
            text-transform: uppercase;
            color: $white;
            font-weight: bold;
            margin-top: rem(20);
            z-index: 2;
            position: relative;
        }
        .a-image_img {
            max-width: rem(60);
            z-index: 3;
            position: relative;
        }
    }
}
