.o-wrap-cardcourse {
  width: 100%;
  height: 100%;
  float: left;
}
.o-project-card {
  $root: &;
  height: rem(440);

  &_status {
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-color: $brand_yellow;
    border-width: 0 1px 1px 0;
    
    &-text {
      border-top-left-radius: 4px;
      padding: 0 10px;
    }

    .unpublish {
      background: linear-gradient(to right, $bg_red, $bg_red_light);
    }

    .publish {
      background: linear-gradient(to right, $bg_blue_light, $persian_green);
    }

    .pending {
      background: linear-gradient(to right, $light_yellow, $bg_yellow);
    }
  }

  &_button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &_first_char {
    padding-bottom: 123%;
    background-color: $brand_blue_dark;
    border-radius: 4px;

    .a-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: rem(120);
    }
  }

  @include break-max(767px) {
    height: rem(380);
  }
  &_image {
    border-radius: rem(5);
    position: relative;
    transition: all $transition linear;
    object-fit: contain;
    box-shadow: $box_shadow_grey;
    cursor: pointer;
    border: rem(2) solid $chardonnay;
    .a-image-ratio {
      border-radius: rem(4);
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: all $transition linear;
      border-radius: inherit;
      background-color: $light_gray;
    }
  }
  &_subscribe {
    position: absolute;
    right: rem(-30);
    top: rem(-30);
    width:rem(86);
    height:rem(86);
    background-image: url(~assets/images/bg_course_structure_on.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    .a-icon{
      width:rem(18);
      height:rem(18);
      margin-right: rem(5);
    }
    span {
      color: $white;
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(11);
    }
  }
  &_info {
    transition: all $transition linear;
    text-align: center;
    &_title {
      margin-top: rem(10);
      margin-bottom: rem(10);
      a {
          text-decoration: none;
      }
    }
    &_description {
      font-family: $font-muli;
      font-size: rem(12);
      color: $brand_blue_dark;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
  &_link {
    display: block;
    img {
      height: rem(320);
    }
    &-full-image {
      width: 100%;
    }
  }
  &_overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: rem(3);
    transition: all $transitionFast ease-out;
    &_wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: rem(5);
    }
    &_button {
      transition: opacity 0.5s ease;
      text-align: center;
      color: $white;
      border-radius: rem(20);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 85%;
      .a-button {
        width: 100%;
        padding-left: rem(12);
        padding-right: rem(12);
      }
    }
    @include tablet-down {
      &_button {
        .a-button {
          font-size: rem(10);
        }
      }
    }
  }
  @include hover {
    #{$root}_image {
      box-shadow: 0 rem(3) rem(6) 0 rgba(0, 0, 0, 0.4);
      // border-color: $chardonnay;
    }
  }

  &-grayscale {
    height: rem(440);
    filter: grayscale(100%);
    #{$root}_image {
      border-color: transparent;
    }
    
    #{$root}_image, #{$root}_overlay .a-button, #{$root}_info_title a {
      cursor: default;
    }
  }

  @include break-max(767px) {
    height: rem(380);
  }
}

@include max-desktop-down {
  .o-cardcourse {
    max-height: rem(408);
    &-grayscale {
      max-height: rem(408);
    }
    &_overlay_button {
      .a-button {
        font-size: rem(14);
      }
    }
  }
}

@include break-max(1280px) {
  .o-cardcourse {
    &_overlay_button {
      .a-button {
        font-size: rem(12);
      }
    }
  }
}

@include tablet {
  .o-cardcourse {
    max-height: rem(412);
    &-grayscale {
      max-height: rem(412);
    }
  }
}

@include mobile {
  .o-cardcourse {
    max-height: rem(348);
    &-grayscale {
      max-height: rem(348);
    }
  }
}

@include small-mobile {
  .o-cardcourse {
    max-height: rem(486);
    &-grayscale {
      max-height: rem(486);
    }
    &_overlay_button {
      .a-button {
        font-size: rem(10);
      }
    }
  }
}

@include break-max(500px) {
  .o-cardcourse {
    max-height: rem(344);
    &-grayscale {
      max-height: rem(360);
    }
  }
}

@include break-max(375px) {
  .o-cardcourse {
    max-height: rem(330);
    &-grayscale {
      max-height: rem(330);
    }
  }
}
