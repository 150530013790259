%wrap_frame {
  border: $border_yellow;
  background-color: $white;
  border-radius: rem(5);
}

.p-exportScoreProcess {
  $root: &;
  font-family: $font-muli;

  &_wrap_breadCrumb {
    padding-top: rem(20);
    margin-bottom: rem(20);

    @include tablet-down {
      padding-top: rem(24);
    }

    @include mobile-down {
      .m-breadcrumbitem {
        margin-right: rem(20);

        &_link {
          padding-right: rem(20);

          &::after {
            right: rem(-5);
          }
        }
      }
    }
  }

  &_wrap_content {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: calc(100% - 556px) 536px;
    grid-column-gap: rem(20);
    margin-bottom: rem(159);
    @include break(1365px,1500px){
      grid-template-columns: calc(100% - 440px) 420px;
    }
    @include break-max(1365px){
      grid-template-columns: 1fr;
      display: block;
      margin-bottom: rem(24);
    }

    #{$root}_wrap_profileBanner {
      width: 100%;
      height: rem(181);
      margin-right: rem(16);
      @include break(1365px,1500px){
        height: rem(200);
        .t-profileBanner{
          height: rem(200);
        }
      }
      @include mobile-down {
        height: auto;
      }
    }

    #{$root}_wrap_folders {
      width: rem(536);
      height: rem(332);
      grid-row: span 2;
      grid-column: span 1;
      @extend %wrap_frame;
      padding-top: rem(20);
      padding-bottom: rem(20);
      @include break(1365px,1500px){
        width: rem(420);
      }
      @include break-max(1365px){
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: rem(20);
        padding: rem(40);
      }

      @include mobile-down {
        height: auto;
        margin-top: rem(31);
        padding: rem(24) rem(16);
      }

      &_title {
        overflow: hidden;
        .a-text {
          width: auto;
          height: auto;
          font-family: $font-muli;
          font-size: rem(24);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: $brand_blue_dark;
          padding-left: rem(20);

          @include mobile-down {
            line-height: rem(36);
          }

          @include break-max(374px) {
            font-size: rem(16);
            line-height: rem(20);
          }
        }

        .a-button {
          margin-left: rem(20);
          background: transparent;
          color: #195d76;
          border: none;
          font-size: rem(24);
          font-weight: bold;
          display: flex;
          width: 100%;
          justify-content: flex-start;

          .a-text {
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }

      &_list {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          min-height: rem(140);
          max-height: rem(250);
          margin-top: rem(20);
          margin-right: rem(20);
          @include break-max(1365px){
            padding-left: rem(20);
            padding-right: rem(20);  
            margin-right: 0;
          }
          @include break(1365px,1500px){
            max-height: rem(200);
          }
          &.clear-flex{
            display: block;
            &::-webkit-scrollbar {
              width: rem(6);
              height: rem(6);
            }
            .m-fileinfo{
              .m-fileinfo_thumbinfo{
                .m-fileinfo_icon{
                  img{
                    height: rem(42);
                    width: rem(33);
                  }
                }
              }
            }
          }
          .m-fileinfo {
            margin-bottom: rem(5);
          }
          @include break-max(1365px){
            max-height: rem(400);
          }
          @include mobile-down {
            max-height: rem(300);
          }
          @include mobile-up {
            min-height: rem(240);
          }
          .m-folder {
            flex: 0 0 20%;
            max-width: 20%;
            padding-left: rem(10);
            padding-right: rem(10);
            margin-bottom: rem(20);
            @include media-breakpoint-down(lg) {
              flex: 0 0 25%;
              max-width: 25%;
            }
            @include media-breakpoint-down(sm) {
              flex: 0 0 33.33333%;
              max-width: 33.33333%;
            }
            &_icon {
              @include max-desktop {
                height: rem(50);
              }
              @include media-breakpoint-down(sm) {
                height: rem(50);
              }
            }
            &_label {
              word-break: break-word;
              @include break-min(1365px){
                font-size: rem(12);
                margin-top: rem(10);
                font-weight: normal;
              }
              @include media-breakpoint-down(sm) {
                margin-top: rem(10);
                font-size: rem(14);
              }
            }
          }

        &::-webkit-scrollbar-track {
          width: rem(6);
          border-radius: rem(10);
          box-shadow: 0 3px 6px 0 $alpha_black_16;
          background-color: $white;
          margin-top: rem(50);
          margin-left: rem(20);
        }

        .o-listFiles {
          box-shadow: none;
          padding-right: 0;

          @include break-max(1500px) {
            padding-left: rem(20);
          }

          &_table {
            tr {
              th {
                padding: rem(4);
                &:first-child {
                  width: auto;
                }
              }
            }
            tbody {
              tr { 
                td {
                  padding: rem(6);
                  .o-listFiles_table_img {
                    min-width: rem(236);
                    .a-text {
                      max-width: rem(216);
                      word-break: break-word;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                    }
                  }
                  .a-image-ratio_img {
                    width: rem(33);
                    height: rem(42);
                  }

                  &.o-listFiles_td-btn {
                    .a-text {
                      min-width: rem(100);
                    }
                  }
                }
              }
            }
          }
        }
        &::-webkit-scrollbar:horizontal {
          height: 7px;
        }
      }
      .a-button{
        span{
          position: absolute;
          left: 0;
          width: rem(10);
          height: rem(19);
        }
      }
    }

    #{$root}_wrap_scores {
      width: 100%;
      height: rem(784);
      @extend %wrap_frame;
      box-shadow: $box_shadow_light;
      grid-row: span 3;
      grid-column: span 1;
      margin-top: rem(20);
      padding-left: rem(40);
      padding-right: rem(20);
      @include desktop-down {
        padding-left: rem(20);
      }

      @include break-max(1365px){
        padding-left: rem(40);
        padding-right: rem(40);
        padding-bottom: rem(10);
        height: auto;  
      }

      @include mobile-down {
        padding: rem(24) 0 rem(24) rem(16);
      }
      &_title {
        width: 100%;
        height: auto;
        margin-top: rem(40);
        display: flex;
        font-family: $font-muli;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: $brand_blue_dark;

        @include mobile-down {
          margin-top: rem(24);
        }

        #{$root}_title_left {
          width: rem(225);
          height: rem(96);

          @include break-max(1365px){
            width: rem(205);
            height: auto;
          }

          @include mobile-down {
            width: rem(119);
            height: auto;
          }

          .a-text {
            width: 100%;
            height: auto;
            font-size: rem(32);
            line-height: rem(40);

            @include mobile-down {
              font-size: rem(18);
              line-height: rem(25.5);
            }

            @include break-max(374px) {
              font-size: rem(16);
              line-height: rem(20);
            }
          }

          &_line {
            width: rem(130);
            height: rem(2);
            border-radius: rem(100);
            margin-top: rem(10);
            background-color: $brand_blue_dark;

            @include mobile-down {
              width: rem(119);
              margin-top: rem(8);
            }

            @include break-max(374px) {
              width: rem(100);
            }
          }
        }

        #{$root}_title_right {
          width: auto;
          height: auto;
          margin: auto;

          @include break-max(1365px){
            padding: rem(40);
          }

          @include desktop-down {
            padding: rem(20);
          }

          @include mobile-down {
            margin-left: 1%;
          }

          .a-text {
            font-size: rem(24);
            @include mobile-down {
              font-size: rem(18);
              line-height: rem(25.5);
            }

            @include break-max(374px) {
              font-size: rem(16);
              line-height: rem(20);
            }
          }

          &_line {
            width: rem(150);
            height: rem(2);
            border-radius: rem(100);
            margin-top: rem(10);
            background-color: $brand_blue_dark;

            @include mobile-down {
              display: none;
            }
          }
        }
      }
      &_table{
        padding-bottom: rem(30);
        width: 100%;
        max-height: rem(610);
        overflow: auto;
        @include mobile-down {
          max-height: 100%;
        }
        &::-webkit-scrollbar:horizontal {
          height: 7px;
        }
        &::-webkit-scrollbar-track {
          width: 6px;
          border-radius: 10px;
          box-shadow: 0 3px 6px 0 $alpha_black_16;
          background-color: $white;
          margin-top: rem(50);
          margin-left: rem(170);
          @include desktop-down {
            margin-top: rem(80);
          }
          @include small-mobile {
            margin-left: rem(120);
          }
        }
        &::-webkit-scrollbar-thumb {
          width: 4px;
          border-radius: 10px;
          height: 4px;
          background-image: linear-gradient(
            to bottom,
            $bg_blue_dark,
            $bg_blue_light
          );
        }
      }
    }

    #{$root}_right {
      width: rem(536);
      height: auto;
      @include break(1365px,1500px){
        width: rem(420);
      }
      @include break-max(1365px){
        width: 100%;
        margin-left: 0;
      }

      @include mobile-down {
        margin-top: rem(24);
      }

      &_feedback {
        width: 100%;
        height: rem(410);
        @extend %wrap_frame;
        margin-top: rem(20);
        padding: rem(20) rem(11) rem(20) rem(20);

        @include break-max(1365px){
          height: auto;
          padding-left: rem(40);
          padding-right: rem(40);
        }

        @include mobile-down {
          height: auto;
          overflow: hidden;
          padding: rem(24) 0 rem(24) rem(16);
        }

        >.a-text {
          width: auto;
          height: rem(30);
          font-family: $font-muli;
          font-size: rem(24);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: $brand_blue_dark;

          @include mobile-down {
            width: rem(170);
            height: auto;
            font-size: rem(18);
            line-height: rem(27);
          }

          @include break-max(374px) {
            font-size: rem(16);
            line-height: rem(20);
          }
        }

        &_phase {
          max-height: rem(340);
          overflow: auto;
          margin-top: rem(10);

          @include mobile-down {
            max-height: 100%;
          }
          &::-webkit-scrollbar:horizontal {
            height: rem(5);
          }

          &::-webkit-scrollbar-track {
            width: rem(4);
            border-radius: rem(10);
            box-shadow: 0 rem(3) rem(6) 0 $alpha_black_16;
            background-color: $white;
            margin-top: 0;

            @include break-max(1365px){
              margin-top: 0;
            }

            @include mobile-down {
              margin-bottom: 30;
            }
          }

          &::-webkit-scrollbar-thumb {
            width: rem(4);
            border-radius: rem(10);
            height: rem(4);
            background-image: linear-gradient(to bottom,
                $bg_blue_dark,
                $bg_blue_light);
          }

          &_comment {
            padding-right: rem(10);
            font-family: $font-muli;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: rem(10);

            .m-pulldown {
              margin-left: rem(1);
            }

            .a-text {
              &:first-child {
                width: auto;
                height: rem(20);
                font-size: rem(16);
                line-height: 1.25;
                color: $brand_blue_dark;
                margin-bottom: rem(10);
              }

              &:last-child {
                width: auto;
                overflow: auto;
                font-size: rem(12);
                line-height: 1.5;
                color: $gray_dark;
                border-radius: rem(5);
                border: $border_light_gray;
                box-shadow: $shadow_text_light_grey;
                height: rem(60);
                padding: rem(14) rem(12) rem(13) rem(20);

                &::-webkit-scrollbar:horizontal {
                  visibility: hidden;
                }

                &::-webkit-scrollbar:vertical {
                  visibility: hidden;
                }

                &::-webkit-scrollbar-track {
                  width: 0;
                  border-radius: rem(10);
                  background-color: $white;
                  margin-top: rem(2);
                  margin-bottom: rem(2);

                  @include break-max(1365px){
                    margin-top: 0;
                  }
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: rem(10);
                  visibility: hidden;
                }
              }
            }

            &.available {
              .a-text {
                color: $color_text_grey;
              }
            }
            &.active{
              .a-text {
                color: $brand_blue_dark;
              }
            }
          }
          .m-textarea_input{
            padding: rem(14) rem(20);
            resize: none;
          }
        }
      }

      &_comments {
        width: 100%;
        height: rem(140);
        @extend %wrap_frame;
        margin-top: rem(20);
        padding: rem(20) rem(11) rem(20) rem(20);

        @include break-max(1365px){
          padding-left: rem(40);
          padding-right: rem(40);
        }

        @include mobile-down {
          height: rem(216);
          padding: rem(24) 0 rem(24) rem(16);
        }

        >.a-text {
          width: auto;
          height: rem(30);
          font-family: $font-muli;
          font-size: rem(24);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: $brand_blue_dark;

          @include mobile-down {
            width: rem(132);
            height: auto;
            font-size: rem(18);
            line-height: rem(27);
          }

          @include break-max(374px) {
            font-size: rem(16);
            line-height: rem(20);
          }
        }

        &_phase {
          margin-top: rem(10);
          margin-right: rem(16);
          .m-textarea_input {
            resize: none;
            width: 100%;
            overflow: auto;
            font-size: rem(12);
            line-height: 1.5;
            color: $gray_dark;
            border-radius: rem(5);
            border: $border_light_gray;
            box-shadow: $shadow_text_light_grey;
            height: rem(60);
            padding: rem(14) rem(12) rem(13) rem(20);

            @include mobile-down {
              height: rem(102);
              padding: rem(16) rem(20);
            }

            &::-webkit-scrollbar:horizontal {
              visibility: hidden;
            }

            &::-webkit-scrollbar:vertical {
              visibility: hidden;
            }

            &::-webkit-scrollbar-track {
              width: 0;
              border-radius: rem(10);
              background-color: $white;
              margin-top: rem(2);
              margin-bottom: rem(2);
              @include break-max(1365px){
                margin-top: rem(80);
              }
              @include small-mobile {
                margin-left: rem(120);
              }
            }
            &::-webkit-scrollbar-thumb {
              border-radius: rem(10);
              visibility: hidden;
            }
          }
        }
      }

      &_complete_phase {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        margin-top: rem(21);
      }
    }
  }
}
.modalassessment{
  width: 100%;
  padding-top: rem(70);
  padding-bottom: rem(70);
  .a-text{
    @include mobile-tablet {
      font-size: rem(16);
    }
    @include small-mobile {
      font-size: rem(12);
      line-height: rem(16);
    }
  }
  &_email{
    margin-bottom: rem(20);
    span{
      font-weight: normal;
    }
  }
  &_title{
    margin-bottom: rem(30);
    .a-text{
      @include mobile-tablet {
        font-size: rem(24);
      }
      @include small-mobile {
        font-size: rem(16);
      }
      @include break-max(375px) {
        font-size: rem(16);
      }
    }
  }
  &_line{
    margin-top: rem(20);
    margin-bottom: rem(20);
    height: rem(0.5);
    background-color: $white;
    width: 100%;
  }
}