.o-aboutPhase{
  width: 100%;
  overflow: hidden;
  &_wrap{
    background-image: url(~images/course-structure/bg_research_innovation.png);
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    padding-top: rem(50);
    @include tablet-down {
      background-image: none!important;
    }
    @include mobile-down{
      padding-top: rem(20);
    }
    &-2 {
      background-image: url(~images/course-structure/bg_refine.png);
    }
    &-1 {
      background-image: url(~images/course-structure/bg_decision.png);
    }
    &-0 {
      background-image: url(~images/course-structure/bg_research_innovation.png);
    }
    &-4 {
      background-image: url(~images/course-structure/bg_finalize.png);
    }
    &-3 {
      background-image: url(~images/course-structure/bg_infrastructure.png);
    }
  }

  &_left{
    padding:rem(23) rem(100) rem(135) rem(80);
    @include break-max(1366px){
      padding-right: rem(20);
    }
    @include desktop-down {
      padding-left: rem(50);
      padding-right: 0;
    }
    @include tablet-down {
      padding: 0 rem(50);
    }
    @include mobile-down{
      padding:0 rem(20)
    }
    &_title{
      display: flex;
      align-items: center;
      @include tablet-down {
        .a-heading{
          color: $brand_blue-dark;
        }
      }
    }
    &_iconTitle{
      width: rem(75);
      height: rem(75);
      font-size: rem(14);
      flex-shrink: 0;
      color: white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-image: url(~images/course-structure/bg_course_structure_on.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @include mobile-down{
        width: rem(50);
        height: rem(50);
      }
    }
    &_ratioPlayer{
      height: 0;
      padding-bottom:calc(403 / 661 * 100%);
      position: relative;
      .m-player{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &_right{
    padding: rem(60) rem(100) rem(120) rem(50);
    @include break-max(1366px){
      padding-left: rem(50);
    }
    @include desktop-down {
      padding-left: rem(30);
      padding-right: rem(20)
    }
    @include tablet-down {
      padding-top: rem(80) ;
      padding-bottom: rem(80);
      background-image: url(~images/course-structure/bg_research_innovation.png);
      background-size: cover;
      background-position: right top;
      background-repeat: no-repeat;
    }
    &_ratioDescription{
      height: 0;
      padding-bottom:calc(550 / 674 * 100%);
      position: relative;
      overflow: auto;
      @include tablet-down {
        padding-bottom: 0;
        height: auto;
        max-height: rem(550);
      }
      @include small-mobile{
        max-height: rem(350);
      }
    }
    &_description{
      padding-right: rem(50);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      @include desktop-down {
        padding-right: rem(20);
      }
      @include tablet-down {
        position: inherit;
      }
      &_text{
        color: white;
        font-size: rem(24);
        line-height: rem(26);
        *{
          @include mobile-down {
            color: white;
            font-size: rem(14)!important;
            line-height: rem(18)!important;
          }
        }
      }
    }
  }
}