.m-menu {
  @include break-max(991px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    opacity: 0.9;
    background-color: $dark_jungle_green;
    color: $brand_white;
    z-index: $z-index-999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    border: rem(2) solid $bg_yellow;
    border-radius: rem(4);
    overflow: hidden;
    &_title {
      flex: 1;
      border-bottom: $bd-gray;
      margin: 0 rem(12.5);
      text-align: center;
      padding: rem(22) 0;
      color: $white;
      font-weight: bold;
      font-size: rem(32);
      font-family: $font-muli;
    }
  }
  @include break-min(992px) {
    opacity: 1 !important;
    transform: translateX(0) !important;
    height: rem(100);
    &_title {
      display: none;
    }
  }
  .m-list {
    @include break-max(991px) {
      display: block;
    }
    .account_item{
      display: none; 
      &.first{
        text-transform: uppercase;
        font-size: rem(24);
        font-weight: bold;
        letter-spacing: rem(1.44);
        margin-bottom: rem(24);
      }
      &.last{
        border-bottom: $bd-gray;
        padding-bottom:rem(26);
        max-width: rem(150);
        margin: auto;
        letter-spacing: rem(1.44);
      }
      @include break-max(991px) {
        display: flex;
      }
      .a-link{
        letter-spacing: 0.96px;
        font-size: rem(16);
        font-weight: normal;
      }
    }
    li {
      .a-icon {
        cursor: pointer;
      }
      @include break-max(991px) {
        display: flex;
        justify-content: center;
        margin: rem(16) 0;
        height: auto;
        &.navigate{
          .a-link{
            font-size: rem(24);
          }
          &.login{
            display: flex;
            max-width: rem(214);
            margin: rem(16) auto;
            padding-bottom: rem(16);
            border-bottom: $bd-gray;
          }
        }

        &.navigate-current {
          &.login {
            display: flex;
            max-width: rem(210);
            margin: rem(16) auto;
            padding-bottom: rem(16);
            border-bottom: $bd-gray;
          }
        }
        &.m-account-header_wrap{
          display: none;
        }
      }
      .a-link {
        color: $blue_gray_light;
        font-family: $font-muli;
        cursor: pointer;
        font-size: rem(20);
        line-height: rem(27);
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.44px;
        @include break-min(992px) {
          &:before {
            content: "";
            width: 100%;
            height: rem(4);
            border-radius: rem(2);
            background: linear-gradient($lg_light_blue_to_dark_blue);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -3px;
            overflow: hidden;
            opacity: 0;
            transition: 1s all ease;
            margin-left: rem(-15);
          }
          &:hover {
            color: white;
            &:before {
              overflow: visible;
              opacity: 1;
              margin-left: 0;
              -webkit-animation: 0.5s draw linear forwards;
              animation: 0.5s draw linear forwards;
            }
          }
        }
        @keyframes draw {
          0% {
            stroke-dasharray: 140 540;
            stroke-dashoffset: -474;
            stroke-width: rem(8);
          }
          100% {
            stroke-dasharray: 760;
            stroke-dashoffset: 0;
            stroke-width: rem(2);
          }
        }
      }
      &.navigate-current {
        a {
          &:before {
            overflow: visible;
            opacity: 1;
            margin-left: 0;
            -webkit-animation: 0.5s draw linear forwards;
            animation: 0.5s draw linear forwards;
          }
        }
      }
    }

    .m-languageSelection_wrap {
      @include tablet-down {
        padding-top: rem(8);
        padding-bottom: rem(8);
      }
    }

    .m-menu-home {
      .m-languageSelection_submenu {
        @include tablet-down {
          transform: translate(0, 330px);
        }
      }
    }
  }

  &-hide-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .a-text {
      @include tablet-up {
        display: none;
      }

      @include break-max(375px) {
        font-size: rem(12);
      }
    }
  }

  &_laguages {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .m-languageSelection_wrap {
      width: 100%;
    }

    .m-languageSelection_avatar {
      justify-content: center;
    }
  }
}
