.o-employer-assessmentSheet {
  .slick-list {
    padding-left: 0;
    padding-top: rem(20);
  }

  .slick-arrow{
    display: none!important;
  }

  .slick-dots {
    bottom: rem(-26);

    li {
      width: rem(10);
      height: rem(10);
      margin-left: rem(8);
      margin-right: rem(8);

      &.slick-active {
        width: rem(30);

        button {
          background: linear-gradient(90deg,
          $bg_red_light 0%,
          $bg_yellow 100%);
          border-radius: 5px;
        }
      }

      button {
        width: 100%;
        height: 100%;
        background: $brand_blue_dark;
        border-radius: 100%;
        transition: $transition ease;

        &:before {
          opacity: 0;
          height: rem(10);
        }
      }
    }
  }

  .slick-slide {
    outline: 0;

    &:not(:last-child):not(:first-child) {
      .m-assessment-card_card_wrap {
        &.hover {
          background-image: url('~images/m-assessment-sheet/between_slider.png');
          background-repeat: no-repeat;
          background-size: contain;
          height: rem(167);

          @include mobile-down {
            height: rem(135);
          }
        }
      }

      .m-assessment-card {
        background-image: url('~images/m-assessment-sheet/unactive_between_slider.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: rem(167);

        @include mobile-down {
          height: rem(135);
        }
      }
    }

    &:first-child {
      .m-assessment-card {
        &_card_wrap {
          &.hover {
            background-image: url('~images/m-assessment-sheet/first_slider.png');
            background-repeat: no-repeat;
            background-size: contain;
            height: rem(170);

            @include mobile-down {
              height: rem(135);
            }

            .m-assessment-card {
              background-image: none;
            }
          }
        }


        background-image: url('~images/m-assessment-sheet/unactive_first_slider.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: rem(167);

        @include mobile-down {
          height: rem(135);
        }
      }
    }


    &:last-child:not(:first-child) {
      .m-assessment-card {
        &_card_wrap {
          &.hover {
            background-image: url('~images/m-assessment-sheet/last_slider.png');
            background-repeat: no-repeat;
            background-size: contain;
            height: rem(167);

            @include mobile-down {
              height: rem(122);
            }
          }
        }

        background-image: url('~images/m-assessment-sheet/unactive_last_slider.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: rem(167);

        @include mobile-down {
          height: rem(122);
        }
      }
    }
  }
}
