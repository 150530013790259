.o-cardFootwearCourse {
  $root: &;
  position: relative;
  border-radius: rem(5);
  cursor: pointer;
  &:hover {
    #{$root}_img {
      .a-image-ratio_img {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    transition: 0.2s ease;
    z-index: -1;
    border-radius: rem(5);
    transform: scale(1);
    box-shadow: $box_shadow_light;
  }
  &-approved {
    &:before {
      background-color: $ufo_green;
    }
  }
  &-denied {
    &:before {
      background-color: $orioles_orange;
    }
  }
  &-pending {
    &:before {
      background-color: $brand_yellow;
    }
  }

  &_evaluate {
    background: transparent
      url("~images/course-structure/bg_course_structure_on.svg") no-repeat
      center/75px auto;
    position: absolute;
    z-index: 3;
    top: rem(-30);
    right: rem(-30);
    width: rem(75);
    height: rem(75);
    display: flex;
    justify-content: center;
    align-items: center;
    &-denied {
      background: transparent
        url("~images/course-structure/bg_course_structure_on_red.svg") no-repeat
        center/75px auto !important;
    }
    &-approved {
      background: transparent
        url("~images/course-structure/bg_course_structure_on_blue.svg")
        no-repeat center/75px auto !important;
    }
  }
  &_img {
    position: relative;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
    max-width: rem(260);
    height: rem(366);
    .a-image_img {
      transition: 0.2s ease;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .a-text {
      position: absolute;
      top: -15%;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(110);
      font-weight: Bold;
      color: $white;
    }
  }
  &_status {
    position: absolute;
    top: rem(-2);
    left: rem(-2);
    width: rem(106);
    border: solid 1px $brand_yellow;
    border-top-left-radius: rem(5);
    z-index: 2;
    text-align: center;

    &-pending {
      background-image: linear-gradient(to right, $light_yellow, $brand_yellow);
    }

    &-approved {
      background-image: linear-gradient(
        to right,
        $midnight_blue,
        $persian_green
      );
    }

    &-denied {
      background-image: linear-gradient(to right, $bg_red, $bg_red_light);
    }
  }

  &_checkbox {
    width: rem(29);
    height: rem(29);
    border: rem(1) solid $brand_yellow;
    border-radius: rem(5);
    background-color: $white;
    position: absolute;
    z-index: 3;
    bottom: rem(10);
    right: rem(10);

    &-checked {
      background-image: linear-gradient(
        to bottom,
        $bg_blue_light,
        $persian_green
      );

      &::before {
        content: "";
        position: absolute;
        top: rem(5);
        left: rem(4);
        background-image: url("~images/icons/icon_correct.svg");
        width: rem(20);
        height: rem(16);
      }
    }
  }
  &_info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    color: $white;
    text-align: center;
    font-size: rem(12);
    padding: 1rem;
    border-radius: 5px;
    background-image: linear-gradient(
      to top,
      #000,
      rgba(37, 79, 86, 0.23),
      rgba(76, 161, 175, 0)
    );
    &-name {
      font-size: rem(24);
      line-height: 1.9rem;
      font-weight: bold;
      text-transform: capitalize;
    }
    &-gender {
      text-transform: capitalize;
      p {
        margin-bottom: 0 !important;
      }
    }
    p {
      margin-bottom: rem(10);
    }
  }
  a {
    display: block;
    text-decoration: none;
    color: $white;
  }

  &_name {
    margin-top: rem(10);
  }
}
