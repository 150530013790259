.m-program-carousel {
  position: relative;

  &_bg {
    min-height: 830px;
    padding-top: 60px;
    width: 100%;
    margin: -8px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include break-min(1920px) {
      min-height: 1437px;
      padding-top: 360px;
    }
    @include break(1599px, 1919px) {
      min-height: 1437px;
      padding-top: 360px;
    }
    @include break(1339px, 1599px) {
      min-height: 1192px;
      padding-top: 260px;
    }
    @include break(1335px, 1339px) {
      min-height: 1150px;
      padding-top: 260px;
    }
    @include break(769px, 1335px) {
      min-height: 1032px;
      padding-top: 280px;
    }
    @include break(769px, 1024px) {
      padding-top: rem(200);
    }
    @include break(669px, 768px) {
      min-height: 100%;
    }
    @include break(601px, 668px) {
      min-height: 500px;
    }
    @include break-max(600px) {
      min-height: 335px;
    }
    @include break-max(1024px) {
      background-image: url(~images/o-tab-program/bg_o_tab_program_blue_mobile.jpg) !important;
    }

  }

  &_content {
    float: right;
    padding: 25px 50px 25px 25px;
    overflow: hidden;
    width: 100%;
    @include break(1025px, 1919px) {
      width: calc((100% - 100px) - (450 / 1242 * 100%));
    }
    @include break-min(1920px) {
      width: calc((100% - 5px) - (560 / 1242 * 100%));
    }
    @include break-max(1300px) {
      max-height: rem(576);
    }
    @include break(669px, 768px) {
      min-height: 530px;
      padding: 20px 40px;
    }
  
    @include break-max(640px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    &_title {
      h2 {
        @include break-max(1024px){
          text-transform: uppercase;
          text-align: center;
          padding-right: rem(25);
          font-size: rem(16);
          line-height: rem(21);
          margin-bottom: rem(19);
        }
        @include break(640px, 1366px) {
          font-size: 32px;
        }
      }
      h4 {
        margin-top: 50px;
      }
    }
    &_text {
      overflow: auto;
      overflow-x: hidden;
      padding-right: 50px;
      padding-top: 15px;
      transition: all 0.2s ease-in-out;
      max-height: 120px;
      color: $white;
      font-family: $font-muli;
      font-style: normal;
      font-weight: normal;
      font-size: rem(24);
      // fontsize set fixed because not CKeditor edit
      * {
          @include mobile-down {
            font-size: rem(14)!important;
            line-height: rem(18)!important;
          }
        }

      @include break-min(1600px) {
        padding-top: 25px;
        max-height: 600px;
      }
      @include break(1366px, 1600px) {
        padding-top: 25px;
        max-height: 450px;
      }
      @include break(991px,1365px){
        max-height: 350px;
      }
      @include break(769px, 1024px) {
        max-height: rem(550);
      }
      @include break(641px, 768px) {
        max-height: 300px;
      }
      @include break(599px, 640px) {
        max-height: 250px;
      }
      @include small-mobile {
        padding-right: rem(10);
      }
    }
  }

  &_pagination {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0;
    margin-right: 0;
    text-indent: inherit;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    @include break-min(1920px) {
      display: block;
      float: right;
      left: 50px;
      margin-top: 90px;
    }
    @include break(1366px, 1919px) {
      margin-top: 117px;
    }
    @include break(1200px, 1365px) {
      display: block;
      top: 60%;
      margin-top: 45px;
    }
    @include break(1024px, 1199px) {
      margin-top: 115px;
    }
    @include break-max(1024px) {
      display: none;
    }

    &_item {
      $root: &;
      &_bg_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 156px;
        text-align: center;
        font-family: $font;
        font-weight: bold;
        font-size: 16px;
        color: $brand_white;
        border-radius: 0;
        opacity: inherit;
        height: auto;
        position: relative;
        cursor: pointer;
        @include break-min(1920px) {
          width: 559px;
          height: 372px;
        }
        @include break(1335px, 1919px) {
          width: 459px;
          height: 305px;
          font-size: 14px;
        }
        @include break(1025px, 1335px) {
          width: 359px;
          height: 239px;
          font-size: 14px;
        }
        @include break(769px, 1024px) {
          width: 300px;
          height: 187.54px;
          font-size: 12px;
        }
      }
      &_bg_icon {
        text-transform: none;
      }

      &:first-child {
        #{$root}_bg_icon {
          background: url(~assets/images/bg_o_tab_item_blue.png) no-repeat center / 60% auto;
        }
      }
      &:last-child {
        #{$root}_bg_icon {
          background: url(~assets/images/bg_o_tab_item_yellow.png) no-repeat center / 50% auto;
        }
      }
      &-active {
        #{$root}_bg_icon {
          background-color: transparent;
          text-transform: uppercase;
          @include break-min(1920px) {
            font-size: 30px;
          }
          @include break(1024px, 1919px) {
            font-size: 28px;
          }
          @include break(1335px, 1919px) {
            font-size: 26px;
          }
          @include break(1025px, 1335px) {
            font-size: 22px;
          }
          @include break(769px, 1024px) {
            font-size: 18px;
          }
        }
        &:first-child {
          #{$root}_bg_icon {
            background: url(~assets/images/bg_o_tab_item_blue_active.png) no-repeat center / 100% auto;
          }
        }
        &:last-child {
          #{$root}_bg_icon {
            background: url(~assets/images/bg_o_tab_item_yellow_active.png) no-repeat center / 100% auto;
          }
        }
      }
    }
  }
  &_thumbnail {
    width: 100%;
  }
  .swiper-button {
    &-prev {   
      width: 10px;
      height: 20px;
      color: #ffc371;
      margin-top: 30px;
      z-index: 5555;
      top: 47px;
      left: 30px;
      &:after {
        font-size: 20px;
        @include break(768px,1024px) {
          font-size: rem(36);
        }
      }
      &:focus {
        outline: none;
      }
      @include break-min(1025px) {
        display: none;
      }
      @include break(769px,1024px) {
        top: rem(190);
        left: rem(100);
      }
    }
    &-next { 
      width: 10px;
      height: 20px;
      color: #ffc371;
      margin-top: 30px;
      z-index: 5555;
      top: 47px;
      right: 50px;
      &:after {
        font-size: 20px;
        @include break(768px,1024px) {
          font-size: rem(36);
        }
      }
      &:focus {
        outline: none;
      }
      @include break-min(1025px) {
        display: none;
      }
      @include break(769px,1024px) {
        top: rem(190);
        right: rem(100);
      }
    }
    &-disabled {
      opacity: 0;
    }
  }
  .swiper-slide {
    width: 100% !important;
    &-active {
      &:last-child {
        .bg_blue {
          background-image: url(~assets/images/bg_o_tab_program_yellow.jpg) !important;
        }
      }
      &:first-child {
        .bg_blue {
          @include break-max(768px) {
            background-image: url(~assets/images/bg_o_tab_program_mobile_blue.png) !important;
          }
        }
      }

      &:last-child {
        .bg_blue {
          @include break-max(1024px) {
            background-image: url(~assets/images/bg_o_tab_program_mobile_yellow.png) !important;
          }
        }
      }
    }
  }
}
