.p-participant-view-result-course {
  $root: &;
  font-family: $font-muli;

  &_titlepage {
    width: 100%;
    height: auto;
    font-size: rem(50);
    text-align: center;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: normal;
    font-weight: bold;
    font-stretch: normal;
    color: $brand_blue_dark;
    padding-top: rem(50);
    text-transform: uppercase;
    @include tablet-down {
      font-size: rem(24);
    }
  }
  &_warpsfolders {
    margin-top: rem(20);

    .a-button{
      background: transparent;
      color: #195d76;
      border: none;
      font-size: rem(24);
      font-weight: bold;
      span{
        position: absolute;
        left: 0;
        width: rem(10);
        height: rem(19);
      }
    }
  
    .a-text {
      width: 100%;
      line-height: 1.25;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      height: rem(30);
      font-size: rem(24);
      text-align: left;
      font-weight: bold;
    }
    &_folders {
      display: flex;
      flex-wrap: wrap;
      padding: rem(50);
      background-color: $white;
      margin-top: rem(20);
      margin-bottom: rem(50);
      border-radius: rem(5);
      box-shadow: 0 rem(3) rem(6) 0 $alpha_black_16;
      border: solid 1px $bg_yellow;
      @include tablet-down {
        padding: rem(50) rem(10);
      }
      @include small-mobile {
        padding: rem(20) rem(10);
      }
      &.clear-padding {
        padding-top: 0;
      }
      .m-folder {
        flex: 0 0 12.5%;
        max-width: 12.5%;
        padding-left: rem(10);
        padding-right: rem(10);
        margin-bottom: rem(20);
        @include media-breakpoint-down(lg) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @include media-breakpoint-down(sm) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
        &_label {
          word-break: break-word;
          @include media-breakpoint-down(sm) {
            margin-top: rem(10);
            font-size: rem(14);
          }
        }
      }
      .o-listFiles {
        width: 100%;
        max-height: rem(700);
        overflow: auto;
        box-shadow: unset;
        padding: unset;
        &::-webkit-scrollbar-track {
          margin-top: rem(30);
        }
        &::-webkit-scrollbar:horizontal {
          height: rem(7);
        }
        &_date {
          justify-content: center;
        }
      }
    }
  }
  &_breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(57);
    margin-bottom: rem(20);

    @include mobile-down {
      margin-top: rem(41);
    }

    .m-breadcrumb {
      background-color: transparent;

      .m-breadcrumbitem {
        &_link {
          font-weight: bold;
        }
      }
    }
  }

  &_banner {
    .o-banner {
      &_title h4 {
        text-transform: none;

        @include mobile-down {
          font-size: rem(16);
          line-height: rem(18);
        }

        @include tablet {
          font-size: rem(24);
        }
      }
    }
    .o-banner_description {
      @include mobile-down {
        font-size: rem(12);
      }
    }
  }

  &_warpslidephase {
    min-height: rem(254);
    margin-top: rem(20);
    .a-text {
      width: 100%;
      height: rem(30);
      font-size: rem(24);
      line-height: 1.25;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      font-weight: bold;
    }

    .o-employer-assessmentSheet {
      margin-top: rem(20);
      .slick-slider .slick-list .slick-track {
        float: left;
      }
    }
  }

  &_wrapreport {
    margin-bottom: rem(177);

    @include mobile-down {
      margin-bottom: rem(80);
    }
    &_titleReport{
      margin-top: rem(10);
      margin-bottom: rem(20);
      display: flex;
      align-items: center;
      >.a-text {
        height: auto;
        font-style: normal;
        font-size: rem(24);
        letter-spacing: normal;
        font-stretch: normal;
        text-align: left;
        line-height: rem(30);
        font-weight: bold;
      }
    }
    &_buttonViewAssessment{
      margin-left: rem(10);
    }

    .o-chart {
      background-color: $white;
      height: 100%;

      &_title {
        margin-top: rem(50);

        @include mobile-down {
          margin-top: rem(20);
        }
      }
    }

    &_chart {
      height: rem(502);

      @include desktop-down {
        height: auto;
      }
    }

    .t-statisticalreportmodal_comment {
      margin-top: 0;
      height: 100%;
      background-color: $white;

      @include desktop-down {
        margin-top: rem(20);
      }
    }
  }
}
