.a-heading {
  $root: &;
  font-weight: bold;
  margin: 0;

  @at-root h1#{&} {
    font-family: $font-muli;
    font-size: 36px;
    line-height: 46px;
  }

  @at-root h2#{&} {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-muli;

    @include break-min(768px) {
      font-size: 64px;
      line-height: 77px;
    }

    &-font-lato {
      font-family: $font-muli;
      font-size: 16px;
      line-height: 24px;

      @include break-min(768px) {
        font-size: 64px;
        line-height: 89.6px;
      }
    }

    &-font-muli {
      font-family: $font-muli;
      font-size: 16px;
      line-height: 24px;

      @include break-min(768px) {
        font-size: rem(33.6);
        line-height: rem(50);
      }
    }

    &-md {
      font-size: rem(50);
      line-height: rem(75);

      @include mobile-down {
        font-size: rem(18);
        line-height: rem(30);
      }
    }

    &-large-max {
      font-size: 16px;
      line-height: 24px;

      @include break-min(768px) {
        font-size: 77px;
        line-height: 108px;
      }
    }

    &-large {
      font-family: $font-muli;
      font-size: 16px;
      line-height: 24px;

      @include break-min(768px) {
        font-size: 72px;
        line-height: 92px;
      }
    }
  }

  @at-root h3#{&} {
    font-family: $font-muli;
    font-size: 12px;
    line-height: 22px;

    @include break-min(768px) {
      font-size: 46px;
      line-height: 81px;
    }

    &-font-lato {
      font-family: $font-muli;
      font-size: 16px;
      line-height: 19px;

      @include break-min(768px) {
        font-size: 24px;
        line-height: 29px;
      }

      &-large {
        font-size: 16px;
        line-height: 31px;

        @include break-min(768px) {
          font-size: 36px;
        }
      }
    }

    &-md {
      font-size: rem(24);
      line-height: rem(30);

      @include mobile-down {
        font-size: rem(16);
        line-height: rem(24);
      }
    }

    &-lg {
      font-size: rem(32);
      line-height: rem(40);

      @include mobile-down {
        font-size: rem(24);
        line-height: rem(30);
      }
    }
  }

  @at-root h4#{&} {
    font-size: 10px;
    line-height: 18px;

    @include break-min(768px) {
      font-size: 32px;
      line-height: 44.8px;
    }

    &-bigger {
      font-size: rem(24);
      line-height: rem(30);

      @include mobile-down {
        font-size: rem(16);
        line-height: rem(24);
      }
    }

    &-large {
      font-size: 12px;
      line-height: 31px;

      @include break-min(768px) {
        font-size: 40px;
        line-height: 31px;
      }
    }

    &-small {
      font-size: 12px;
      line-height: 16px;
      font-family: $font-muli;

      @include break-min(768px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &-xsmall {
      font-size: 14px;
      line-height: 23.8px;
    }

  }

  @at-root h5#{&} {
    font-size: 15px;
  }

  @at-root h6#{&} {
    font-size: 14px;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-italic {
    font-style: italic;
  }

  &-light {
    font-weight: 300;
  }

  &-bold {
    font-weight: 800;
  }

  &-bold-lg {
    font-weight: 900;
  }

  &-white {
    color: $brand_white;
  }

  &-brand-blue-dark {
    color: $brand_blue-dark;
  }

  &-gray {
    color: $brand_gray;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-cadet {
    color: $cadet;
  }
}