.a-link {
  color: $brand_gray;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
  &-text-underline{
    text-decoration: underline;
    @include hover {
      text-decoration: underline
    }
  }
  &-white{
    color: $white;
  }
  &-fs-small{
    font-size: rem(18);
    text-transform: capitalize;
    @include mobile-down {
      font-size: rem(14);
    }
  }
}
