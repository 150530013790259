.a-icon {
  display: inline-block;
  &_iconPadLock
  &_center {
    text-align: center;
  }
  &-angle-right {
    width: rem(10);
    height: rem(16);
    background: url("~images/icons/angle-right.svg") no-repeat center center / 100% auto;
  }
  &-angle-left{
    width: rem(20);
    height: rem(38);
    background: url("~images/icons/icon_angle_left.svg") no-repeat center center / 100% auto;
  }
  &-user{
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_user.svg")no-repeat center center  / 70% auto;
    @include break-max(767px) {
      width: rem(20);
      height: rem(20);
    }
  }
  &-account{
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/ic-account.svg")no-repeat center center  / 100% auto;
    @include break-max(767px) {
      width: rem(20);
      height: rem(20);
    }
  }
  &-account-active{
    width: rem(36);
    height: rem(36);
    background: url("~images/icons/ic-account-active.svg")no-repeat center center  / 100% auto;
    @include break-max(767px) {
      width: rem(32);
      height: rem(32);
    }
  }
  &-user-light{
    width: rem(36);
    height: rem(36);
    background: url("~images/icons/icon_user_light.svg")no-repeat center center  / 70% auto;
    @include break-max(767px) {
      width: rem(32);
      height: rem(32);
    }
  }
  &-upload{
    width: rem(14);
    height: rem(17);
    background: url("~images/icons/upload.svg")no-repeat center center  / 70% auto;
  }
  &-close {
    width: rem(60);
    height: rem(60);
    background: url("~images/icons/icon_close.svg") no-repeat center center / 110% auto;
    @include break-max(767px) {
      width: rem(40);
      height: rem(40);
    }
  }

  &-down {
    width: rem(30);
    height: rem(30);
    background: url("~images/icons/icon_down.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(15);
      height: rem(15);
    }
  }
  &-pdf {
    width: rem(30);
    height: rem(35);
    background: url("~images/icons/icon_pdf.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(25);
      height: rem(30);
    }
  }
  &-mp4 {
    width: rem(36);
    height: rem(36);
    background: url("~images/icons/icon_mp4.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(30);
      height: rem(30);
    }
  }

  &-xlsx {
    width: rem(28.8);
    height: rem(36);
    background: url("~images/icons/icon_xlsx.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      max-width: rem(25.2);
      height: rem(30);
    }
  }

  &-down-blue {
    width: rem(30);
    height: rem(30);
    background-image: url("~images/icons/icon_down_blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &-black-man {
    width: rem(18);
    height: rem(18);
    background: url("~images/icons/black-man.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(16);
      height: rem(16);
    }
  }
  &-correct {
    width: rem(20);
    height: rem(16);
    background: url("~images/icons/icon_correct.svg") no-repeat top left / 100% auto;
  }

  &-up {
    width: rem(30);
    height: rem(30);
    background: url("~images/icons/icon_up.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(15);
      height: rem(15);
    }
  }

  &-up-blue {
    width: rem(30);
    height: rem(30);
    background: url("~images/icons/icon_up_blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include break-max(767px) {
      width: rem(20);
      height: rem(20);
    }
  }

  &-up-orange {
    width: rem(25);
    height: rem(20);
    background: url("~images/icons/icon_up_orange.svg") no-repeat center center/ 100% auto;
    @include mobile-down {
      width: rem(20);
      height: rem(12);
    }
  }

  &-next-icon {
    width: rem(30);
    height: rem(30);
    background: url("~images/icons/icon_next_round.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(15);
      height: rem(15);
    }
  }

  &-file {
    width: rem(26);
    height: rem(34);
    background: url("~images/icons/icon_file.svg") no-repeat top left / 100% auto;
  }

  &-instagram {
    width: rem(18);
    height: rem(17);
    background: url("~images/icons/icon_in.svg") no-repeat top left / 100% auto;
  }

  &-pinterest {
    width: rem(18);
    height: rem(23);
    background: url("~images/icons/icon_p.svg") no-repeat top left / 100% auto;
  }

  &-twitter {
    width: rem(20);
    height: rem(16);
    background: url("~images/icons/icon_t.svg") no-repeat top left / 100% auto;
  }

  &-facebook {
    width: rem(10);
    height: rem(22);
    background: url("~images/icons/icon_f.svg") no-repeat top left / 100% auto;
  }

  &-instagram-blue {
    width: rem(18);
    height: rem(17);
    background: url("~images/icons/icon_in_blue.svg") no-repeat top left / 100% auto;
  }

  &-pinterest-blue {
    width: rem(18);
    height: rem(23);
    background: url("~images/icons/icon_p_blue.svg") no-repeat top left / 100% auto;
  }

  &-twitter-blue {
    width: rem(20);
    height: rem(16);
    background: url("~images/icons/icon_t_blue.svg") no-repeat top left / 100% auto;
  }

  &-facebook-blue {
    width: rem(10);
    height: rem(22);
    background: url("~images/icons/icon_f_blue.svg") no-repeat top left / 100% auto;
  }

  &-menu-mb {
    width: rem(20);
    height: rem(20);
    background: url("~images/icons/icon_menu-mb.svg") no-repeat center;
  }

  &-subscribe {
    width: rem(280.25);
    height: rem(197.818);
    background: url("~images/icons/icon_subscribe.svg") no-repeat top left / 100% auto;
  }

  &-warning {
    width: rem(81);
    height: rem(73);
    background: url("~images/icons/icon_warning.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(50);
      height: rem(45.06);
    }
  }

  &-x-white {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_x_white.svg") no-repeat center center / 100% auto;
    background-size: rem(30);
  }

  &-info-white {
    width: rem(14);
    height: rem(14);
    background: url("~images/icons/icon_info_white.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(10);
      height: rem(10);
    }
  }

  &-subscribe_new {
    width: rem(20);
    height: rem(20);
    background: url("~images/icons/icon_subscribe_new.svg") no-repeat top left / 100% auto;
    @include break-max(767px) {
      width: rem(10);
      height: rem(10);
    }
  }
  
  &-error {
    width: rem(20);
    height: rem(20);
    background: url("~images/icons/icon_error.svg") no-repeat top left / 100% auto;
  }

  &-return {
    width: rem(16);
    height: rem(16);
    background: url("~images/icons/icon_back.svg") no-repeat top left / 100% auto;
    background-size: 16px 16px;
    @include break-max(767px) {
      width: rem(10);
      height: rem(10);
      background-size: rem(10) rem(10);
    }
  }

  &-triangle-down-white {
    width: rem(8);
    height: rem(6);
    background: url("~images/icons/icon_triangle_down_white.svg") no-repeat top left / 100% auto;
    @include tablet {
      width: rem(12);
      height: rem(10);
    }
  }

  &-vietnam {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_vietnam.svg") no-repeat top left / 100% auto;
  }

  &-russia {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_russia.svg") no-repeat top left / 100% auto;
  }

  &-kingdom {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_kingdom.svg") no-repeat top left / 100% auto;
  }

  &-japan {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_japan.svg") no-repeat top left / 100% auto;
  }

  &-upload-orange {
    width: rem(40);
    height: rem(51);
    background: url("~images/icons/icon_upload.svg") no-repeat top center / 100% auto;
    @include break-max(767px) {
      width: rem(32);
      height: rem(32);
      background-size: rem(32) rem(32);
    }
  }

  &-bell {
    width: rem(36);
    height: rem(36);
    background: url("~images/icons/icon_bell.svg") no-repeat center center / 100% auto;
    @include break-max(767px) {
      width: rem(32);
      height: rem(32);
    }
  }

  &-user-white {
    width: rem(36);
    height: rem(36);
    background: url("~images/icons/icon_user_white.svg") no-repeat center center  / 100% auto;
    @include break-max(767px) {
      width: rem(32);
      height: rem(32);
    }
  }

  &-download {
    width: rem(14);
    height: rem(17);
    background: url("~images/icons/icon_download.svg") no-repeat center center  / 100% auto;
  }
  &-search {
    width: rem(26);
    height: rem(22);
    background: url("~images/icons/icon_search.svg") no-repeat center center  / 100% auto;
    @include mobile-down {
      width: rem(20);
      height: rem(18);
    }
  }
  &-filter{
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/filter.png") no-repeat top left / 100% auto;
    @include mobile-down {
      width: rem(18);
      height: rem(18);
    }
  }
  &-cancel{
    width: rem(26);
    height: rem(22);
    background: url("~images/icons/icon_cancel.svg") no-repeat center center / 50% auto;
  }
  &-phone {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_phone.svg") no-repeat center / 100% auto;
  }
  &-filter-white {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/filter_white.svg") no-repeat center / 100% auto;
    @include mobile-down {
      width: rem(18);
      height: rem(18);
    }
  }
  &-pad-lock{
    width: rem(43);
    height: rem(57);
    background: url("~images/icons/icon_padlock.svg") no-repeat center / 100% auto;
    @include mobile-down {
      width: rem(23);
      height: rem(37);
    }
  }
  &-prev-orange{
    width: rem(20);
    height: rem(38);
    background: url("~images/icons/icon_prev.svg") no-repeat center / 120% auto;
  }
  &-next-orange{
    width: rem(20);
    height: rem(38);
    background: url("~images/icons/icon_next.svg") no-repeat center / 120% auto;
  }
  &-up-orange-small{
    width: rem(8);
    height: rem(14);
    background: url("~images/icons/icon_up_orange_small.svg") no-repeat center center/ 100% auto;
  }
  &-down-dark-small{
    width: rem(8);
    height: rem(14);
    background: url("~images/icons/icon_down_dark_small.svg") no-repeat center center/ 100% auto;
  }
  &-photo {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_photo.svg") no-repeat top left / 100% auto;
  }
  &-upload-photo {
    width: rem(60);
    height: rem(80);
    background: url("~images/icons/icon_upload_photo.svg") no-repeat center center / 100% auto;
  }
  &-upload-file {
    width: rem(80);
    height: rem(70);
    background: url("~images/icons/icon_upload_file.svg") no-repeat center center / 100% auto;
  }
  &-clock-blue {
    width: rem(15);
    height: rem(15);
    background: url("~images/icons/icon_clock_blue.svg") no-repeat center / 100% auto;
  }
  &-icon-info {
    width: rem(24);
    height: rem(24);
    background: url("~images/icons/icon_info.svg") no-repeat center / 100% auto;
  }
  &-fullscreen {
    width: rem(56);
    height: rem(56);
    background: url("~images/icons/icon_fullscreen.png") no-repeat center / 100% auto;
  }
}