.category-card {
  &_img {
    border-radius: 5px;
    cursor: pointer;
    border-radius: rem(5);
    overflow: hidden;
    position: relative;
  
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
      border: 3px solid $brand_yellow;
      
      &::after {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
        border: 3px solid $brand_yellow;
      }
    }
  
    &::after {
      content: "";
      position: absolute;
      top: rem(-3);
      left: rem(-3);
      right: rem(-3);
      bottom: rem(-3);
      z-index: 1;
      transition: 0.3s ease;
      border: rem(3) solid transparent;
      border-radius: rem(5);
    }
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0.6;
      transition: 0.3s ease;
      transform: scale(0);
      background-image: linear-gradient(to top, #16222a, #3a6073);
    }
  
    &_first-character {
      position: absolute;
      overflow: hidden;
      display: block;
      max-width: 100%;
      max-height: 100%;
      background-color: $brand_blue_dark;
      @include aspectRatio(260, 320);
      .a-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(110);
        font-weight: Bold;
        color: $white;
      }
    }
  }

  &_name {
    margin-top: rem(15);

    .a-text {
      color: $black;
    }
  }
}
