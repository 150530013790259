.m-courseSelection-puzz {
  cursor: pointer;
  $root: &;
  text {
    transition: 0.4s;
  }
  &-button {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
  }
  &-active {
    #{$root}-button {
      visibility: visible;
      opacity: 1;
    }
  }
}
