@mixin absolute {
  content: "";
  position: absolute;
}
.modal-backdrop {
  opacity: 0.8 !important;
  background-color: $rick_black !important;
}
.modal-custom-main {
  background: transparent;
  max-width:100%;
  @include small-mobile-up {
    max-width: 90%;
  }
  @include max-desktop {
    max-width:1400px;
  }
  &-login{
    max-width: 800px;
    @include tablet-down {
      max-width: 90%;
    }
    @include small-mobile {
      max-width: 100%;
    }
  }
  &-report{
    max-width: 1242px;
    @include break-max(1300px) {
      max-width: 90%;
    }
    @include tablet-down {
      max-width: 100%;
    }
    @include mobile-tablet {
      max-width: 95%;
    }
  }
  &-lg{
    max-width: 1088px;
    @include desktop-down {
      max-width: 90%;
    }
    @include small-mobile {
      max-width: 100%;
    }
  }

  &-resgistration{
    max-width: 1364px;
    @include max-desktop {
      max-width: 90%;
    }
    @include small-mobile {
      max-width: 100%;
    }
  }
 
  &.modal-border .modal-body {
    &::after {
      width: 100%;
      height: 100%;
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      filter: blur(6px);
      border: solid 3px $rick_black;
      z-index: $z-index-1;
    }
  }
  .modal-content {
    background: transparent;
    border: none;
    .modal-body {
      position: relative;
      padding: 0;
      .o-modal {
        position: relative;
        &_icon{
          display: inline-block;
          position: absolute;
          z-index: $z-index-3;
          top: rem(20);
          right: rem(20);
        }
        &_children {
          position: relative;
          z-index: $z-index-2;
        }
        &-login {
          background: linear-gradient(to bottom right, $blue_deep, $blue_sky);

          &::before {
            @include absolute;
            top: 0;
            left: 0;
            width: rem(73.7);
            height: rem(111.5);
            background: url(~images/o-modal/icon_bg_blue.svg) center center;
          }
          &::after {
            @include absolute;
            width: rem(81.5);
            height: rem(87.1);
            bottom: 0;
            right: 0;
            background: url(~images/o-modal/icon_bg_red.svg) center rem(1);
          }
        }
        &-upload {
          background-color: white;
          border-radius: 5px;
          &::before {
            @include absolute;
            width: rem(138.4);
            height: rem(106.5);
            top: 0;
            right: 0;
            background: url(~images/o-modal/icon_bg_red_light.svg) center center;
          }
          &::after {
            @include absolute;
            width: rem(178);
            height: rem(100);
            bottom: 0;
            left: 0;
            background: url(~images/o-modal/icon_bg_blue_light.svg) center
              rem(2);
          }
        }
        &-report {
          background-color: white;
          .o-modal_children{
            min-height: rem(200);
          }
          @include mobile-down {
            padding-top: rem(40);
          }
          &::before {
            @include absolute;
            top: 0;
            left: 0;
            width: rem(73.7);
            height: rem(111.5);
            background: url(~images/o-modal/icon_bg_yellow_deep.svg) center
              center;
          }
          &::after {
            @include absolute;
            width: rem(81.5);
            height: rem(87.1);
            bottom: 0;
            right: 0;
            background: url(~images/o-modal/icon_bg_red_deep.svg) center rem(1);
          }
        }
      }
    }
  }
}
