@mixin paddingBorderBox($hasBorder) {
    @if $hasBorder {
        padding: rem(7) rem(40);
    } @else {
        padding: rem(8) rem(40);
    }
    @include media-breakpoint-down(lg){
        padding-left: rem(20);
        padding-right: rem(20);
    }
    @include media-breakpoint-down(sm){
        padding-left: rem(14);
        padding-right: rem(14);
    }
}
@mixin responText{
    @include media-breakpoint-down(lg){
        font-size: 14px;
    }
    @include media-breakpoint-down(md){
        font-size: 12px;
    }
}
@mixin textWhiteHoverColor {
    color: $white;
    &:hover {
        color: $white;
    }
}

.a-button {
    // word-break: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box !important;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;

    -webkit-appearance: none;
    @include paddingBorderBox(true);
    font-family: $font-muli;
    color: $brand_gray;
    font-size: rem(16);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    border-radius: 9px;
    border: 1px solid $brand_yellow;
    transition: 0.4s ease;
    background: linear-gradient(90deg, #ff5f6d 0%, #ffc371 100%);
    @include responText;
    &:hover {
        text-decoration: none;
    }
    &-ovalFill {
        @include textWhiteHoverColor;
        border-radius: rem(33);
        &:hover{
            background: linear-gradient(
                90deg,
                rgba(242, 95, 103, 1) 15%,
                rgba(242, 137, 105, 1) 67%,
                rgba(242, 173, 106, 1) 100%
            );
        }
    }
    &-ovalFillBlue {
        @include textWhiteHoverColor;
        border-radius: rem(33);
        border: 1px solid $blue_sky;
        background: linear-gradient(to bottom right, rgba( $blue_deep , 0.9), $blue_sky);
        &:hover{
            background: linear-gradient(to bottom right, $blue_deep , $blue_sky );
        }
    }
    &-ovalFillAlert {
        @include textWhiteHoverColor;
        background: linear-gradient(90deg, $bg_red 0%,
                $bg_red_light 100%,);
        border-radius: rem(33);
        &:hover {
            background: linear-gradient(90deg, #952006 0%, #dc4b57 100%);
        }
    }
    &-white {
        color: $white;
        &:hover{
            color: $white;
        }
    }
    &-blueDark {
        color: $brand_blue_dark !important;
    }
    &-fs32 {
        font-size: rem(32);
        @include media-breakpoint-down(md){
            font-size: rem(14);
        }
    }
    &-h45{
        height: 45px;
        @include media-breakpoint-down(md){
            height: 35px;
        }
    }
    &-shadow {
        box-shadow: 0 3px 6px rgba($rick_black, 0.16);
    }
    &-icon {
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        &:hover {
            opacity: 0.9;
        }
        &-rightSide {
            flex-direction: row;
            .a-icon {
                margin-left: rem(10);
                @include media-breakpoint-down(md){
                    margin-left: rem(5);
                }
            }
        }
        &-leftSide {
            flex-direction: row-reverse;
            .a-icon {
                margin-right: rem(10);
                @include media-breakpoint-down(md){
                    margin-right: rem(5);
                }
            }
        }
    }
    &-transparent {
        background: transparent !important;
        border: 0;
        &.a-button-icon-leftSide {
            padding-right: 0;
        }
        &.a-button-icon-rightSide {
            padding-left: 0;
        }
    }
    &-ovalOutline {
        color: $rick_black;
        background: $white;
        @include paddingBorderBox(true);
        border-radius: rem(33);
        &:hover {
            color: $brand_gray;
            background: $seashell;
        }
    }
    &-puzzle {
        text-transform: uppercase;
        width: rem(260);
        height: rem(124);
        text-align: center;
        border: none;
        background: url(~images/bg_button.svg) no-repeat center;
        background-size: rem(260);
        font-size: rem(14);
        line-height: 1em;
        padding-top: rem(12);
        padding-bottom: rem(14);
    }
    &-uppercase {
        text-transform: uppercase;
    }
    &-bold{
        font-weight: bold;
    }
    &-disabled {
        &.a-button-ovalFill,
        &.a-button-ovalOutline {
            background: $white_smoke !important;
            color: $brand_gray;
            border: 1px solid $light_gray_pale;
            @include paddingBorderBox(true);
        }
    }
    &-pd-small{
        @include desktop-up {
            padding:rem(7) rem(25);
        }
        @include break(1200px,1366px){
            font-size: rem(14);
        }
    }
}
