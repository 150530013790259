.t-statisticalreport{
  background-color: $white;
  padding:rem(20);
  width: 100%;
}
.t-statisticalreportmodal {
  $root: &;
  width: 100%;
  &_modalContent{
    position: relative;
  }
  &_modalIconClose{
    position: absolute;
    top: rem(5);
    right: rem(5);
    .a-icon-close{
      @include mobile-down{
        width: rem(30);
        height: rem(30);
      }
    }
  }
  &_modal {
    margin: auto;
    max-height: rem(1077);
    max-width: rem(1120);
    width: 100%;
    background-color: $white;
    padding: rem(20) rem(16);
    border: solid rem(2) $brand_yellow;
    border-radius: rem(5);
    @include mobile-down{
      padding: rem(5);
    }
  }

  &_chart{
    .o-chart_title {
      margin-top: rem(50);
      @include break-max(768px) {
        margin-top: rem(40);
      }
    }
  }

  &_comment {
    margin-top: rem(30);
    padding: rem(20) 0;
    border-radius: rem(5);
    border: solid rem(1) $brand_yellow;

    &-container {
      max-height: rem(400);
      overflow: auto;
      @include small-mobile{
        max-height: rem(200);
      }
      #{$root}_commentTitle{
        .a-text{
          @include small-mobile{
            font-size: rem(12);
          }
        }
      }
      #{$root}_commentContent{
        .a-text{
          @include small-mobile{
            font-size: rem(12);
          }
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin: 0 rem(20) rem(10);
      .a-text{
        width: rem(187);
        @include small-mobile{
          font-size: rem(16);
        }
      }
      &::after {
        content: "";
        height: rem(1);
        width: 100%;
        background-color: $brand_blue_dark;
        margin-left: rem(20);
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: rem(10) rem(20);

      &:hover {
        background-color: $gray_light;
        #{$root}_commentImage{
          border: solid rem(3) $brand_yellow;
        }
      }
    }
  }

  &_commentImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: rem(80);
    width: 100%;
    height: rem(80);
    margin-right: rem(38);
    border-radius: 50%;
    transition: all $transition linear;
    @include small-mobile{
      max-width: rem(50);
      height: rem(50);
    }
    &-pharse1 {
      background-image: url("~images/t-statisticalreportmodal/p1.png");
    }

    &-pharse2{
      background-image: url("~images/t-statisticalreportmodal/p2.png");
    }

    &-pharse3{
      background-image: url("~images/t-statisticalreportmodal/p3.png");
    }

    &-pharse4{
      background-image: url("~images/t-statisticalreportmodal/p4.png");
    }
    &-pharse5{
      background-image: url("~images/t-statisticalreportmodal/p5.png");
    }
    &-pharse10{
      background-image: url("~images/t-statisticalreportmodal/total.png");
    }
  }

  &_commentContent{
    .a-text{
      font-size: rem(14);
    }
  }
}
