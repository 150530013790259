.o-assessmentSheet {
    .slick-list{
      padding-left: 0 ;
      padding-top: rem(20);
    }
    .slick-dots {
      bottom: rem(-26);
        li {
            width: rem(10);
            height: rem(10);
            margin-left: rem(8);
            margin-right: rem(8);
            &.slick-active {
              width: rem(30);
                button {
                    background: linear-gradient(
                        90deg,
                        $bg_red_light 0%,
                        $bg_yellow 100%
                    );
                    border-radius: 5px;
                }
            }
            button {
                width: 100%;
                height: 100%;
                background: $brand_blue_dark;
                border-radius: 100%;
                transition: $transition ease;
                &:before {
                    opacity: 0;
                    height: rem(10);
                }
            }
        }
    }
    .slick-slide{
      outline: 0;
      &:not(:last-child):not(:first-child){
        .m-assessment-sheet_card_wrap{
          &.unavailable{
            left: 45%;
          }
          &.available{
            left: 30%;
          }
        }
      }
      &:not(:first-child){
        .m-assessment-sheet_card_wrap{
          &.available.hover{
            padding-left: rem(35);
          }
        }
      }
      &:first-child{
        .m-assessment-sheet{
          &_card.unavailable{
            background-image: url('~images/m-assessment-sheet/first-unavailable-sharp.png');
          }
          &_card_wrap.unavailable{
            left: 42%;
          }
          &.available{
            .m-assessment-sheet_card_wrap.not_hover.available{
              left: 0;
              width: 82%;
            }
          }
        }
      }
      &:nth-child(2){
        .m-assessment-sheet{
          &_card.available{
            background-image: url('~images/m-assessment-sheet/child-2-available-sharp.png');
          }
        }
      }
      &:nth-child(3){
        .m-assessment-sheet{
          &_card.available{
            background-image: url('~images/m-assessment-sheet/child-3-available-sharp.png');
          }
        }
      }
      &:nth-child(4){
        .m-assessment-sheet{
          &_card.available{
            background-image: url('~images/m-assessment-sheet/child-4-available-sharp.png');
          }
        }
      }
      &:last-child:not(:first-child){
        .m-assessment-sheet{
          &_card.available{
            background-image: url('~images/m-assessment-sheet/last-available-sharp.png');
          }
          &_card_wrap{
            &.available.hover{
              padding-left: rem(50);
            }
            &.available.not_hover{
              left: 40%;
            }
          }
        }
      }
    }
}
