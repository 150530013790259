.o-modaltermpolicy {
  position: relative;
  padding: rem(115) rem(215);
  background: linear-gradient(to bottom, $blue_dark, $blue_light);
  margin: auto;
  text-align: center;
  border: 3px solid $brand_yellow;
  border-radius: rem(5);
  &_icon{
    display: inline-block;
    position: absolute;
    z-index: $z-index-3;
    top: rem(20);
    right: rem(20);
  }
  @include tablet {
    padding: rem(115) rem(100);
  }
  @include mobile-down {
    padding: rem(80) rem(20);
  }
  .a-button.a-button-bold {
    color: $rick_black;
    &:hover {
      background-color: $white;
    }
  }
  .a-checkbox {
    justify-content: center;
    span {
      font-size: rem(18);
      color: $white;
      @include mobile-down {
        font-size: rem(14);
      }
    }
  }
  &_titleWillStart{
    margin-bottom: rem(20);
    .a-text{
      @include tablet-down {
        line-height: rem(36);
      }
      @include mobile-down {
        line-height: rem(24);
      }
    }
  }
  &_titleCanNotStart{
    padding:0 rem(20);
    .a-text{
      @include tablet-down {
        line-height: rem(36);
      }
      @include mobile-down {
        line-height: rem(24);
      }
    }
  }
  &_aboutCourse{
    color: $white;
    max-height: rem(300);
    overflow: auto;
    margin-bottom: rem(20);
    padding-right: rem(10);
    text-align: left;
    *{
      @include mobile-down {
        font-size: rem(14)!important;
        line-height: rem(24)!important;
      }
    }
  }
}
