@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-reportmodal {
  width: 100%;
  padding: rem(50) 0px rem(30) 0px;
  $root: &;
  @include desktop-down {
    width: 100%;
    padding-right: rem(30);
  }
  @include mobile-down {
    padding: rem(10) rem(5);
  }
  &_header {
    #{$root}_top {
      padding: rem(30) rem(62) rem(58) rem(138);
      @include flex;
      @include desktop-down {
        padding: rem(10) rem(70);
      }
      @include tablet-down {
        flex-wrap: wrap;
        padding-left: rem(10);
        padding-right: rem(10);
        padding-bottom: rem(27);
        border-bottom: 1px solid $light_gray_pale;
      }
      #{$root}_title {
        @include tablet-down {
          padding-bottom: rem(24);
          width: 100%;
          text-align: center;
          border-bottom: 1px solid $light_gray_pale;
          .a-text {
            font-size: rem(24);
            line-height: rem(24);
          }
        }
      }
      #{$root}_info {
        @include tablet-down {
          flex-basis: 100%;
          @include flex;
        }
        @include tablet-down {
          margin-top: rem(24);
          flex-wrap: wrap;
        }
        #{$root}_exam {
          text-align: center;
          @include small-mobile {
            text-align: left;
            flex-basis: 100%;
          }
          @include tablet-down {
            .a-text {
              font-size: rem(18);
              line-height: rem(18);
            }
          }
        }
        #{$root}_date {
          margin-top: rem(10);
          display: flex;
          .m-textvalue {
            margin-right: rem(34);
            span {
              font-size: rem(16);
            }
            @include small-mobile {
              margin-right: rem(0);
            }
          }
          .m-textvalue:last-child {
            margin-right: rem(0);
          }
          @include small-mobile {
            width: 100%;
            justify-content: space-between;
          }
          @include tablet-down {
            .a-text {
              font-size: rem(16);
              span {
                font-size: rem(14);
              }
            }
          }
          #{$root}_name-blue {
            line-height: 1.25rem;
            .a-text {
              font-size: rem(16);
              line-height: 1.25rem;
            }
            .a-link {
              display: inline-block;
              margin-top: rem(5);
              color: $brand_blue_dark;
              text-decoration: underline;
              font-size: rem(16);
              @include tablet-down {
                font-size: rem(14);
              }
              @include mobile-down {
                margin-top: rem(4);
              }
            }
            margin-right: rem(34);
            @include small-mobile {
              margin-right: rem(0);
            }
          }
        }
      }
    }
  }
  &_footer {
    padding: 0 rem(30);
    @include tablet-down {
      padding-left: rem(10);
      padding-right: rem(10);
      padding-bottom: rem(32);
    }
    #{$root}_bottom {
      margin-top: rem(20);
      #{$root}_comment {
        @include flex;
        align-items: center;
        @include tablet-down {
          &_btn {
            .a-text {
              font-size: rem(12);
            }
          }
          .a-text {
            font-size: rem(18);
            line-height: rem(20);
            @include break-max(375px) {
              font-size: rem(16);
            }
          }
        }
        &_btn {
          cursor: pointer;
          .a-text {
            font-size: rem(12);
          }
        }
      }
      #{$root}_text {
        @include flex;
        margin-top: rem(10);
        min-height: rem(117);
        @include tablet-down {
          flex-wrap: wrap;
        }
        #{$root}_textarea {
          width: 100%;
          max-width: rem(700);
          @include desktop-down {
            max-width: rem(550);
          }
          @include tablet-down {
            flex-basis: 100%;
            max-width: 100%;
            margin-top: rem(10);
          }

          .m-textarea {
            textarea {
              min-height: rem(117);
              background-color: $ghost_white;
              @include small-mobile {
                min-height: rem(224);
                font-size: rem(16);
              }
            }
          }
          &_expert {
            padding: rem(10) rem(20);
            background-color: $ghost_white;
            min-height: rem(200);
            &_info {
              display: flex;
              justify-content: space-between;
              margin-bottom: rem(10);
              .a-text:first-child {
                font-size: rem(18);
                font-weight: bold;
                color: $rick_black;
                @include small-mobile {
                  font-size: rem(16);
                }
              }
              .a-text:last-child {
                font-size: rem(12);
                color: $light_gray_pale;
              }
            }
            &_comment {
              max-height: rem(200);
              overflow: auto;
              .a-text {
                color: $rick_black;
                font-size: rem(16);
                line-height: 1.25;
              }
            }
            &.placehoder {
              .a-text {
                color: $light_gray_pale;
              }
            }
          }
        }
        #{$root}_score {
          height: 100%;
          @include flex;
          flex-direction: column;
          margin-right: rem(152);
          text-align: center;
          @include break-max(1360px) {
            margin-right: 0;
          }
          @include tablet-down {
            flex-basis: 100%;
          }
          @include tablet-down {
            .a-text {
              font-size: rem(18);
              margin-top: rem(16);
              margin-bottom: rem(16);
            }
          }
          &_confirm {
            display: flex;
            .a-button {
              margin-left: rem(30);
              min-width: rem(114);
              &:first-child {
                color: $rick_black;
              }
              @include tablet-up {
                margin-top: 0;
              }
            }
          }
          #{$root}_total {
            height: 100%;
            margin-top: rem(10);
            .m-score {
              margin: auto;
              font-size: rem(32);
              border: none;
              @include tablet-down {
                font-size: rem(24);
              }
            }
          }
        }
      }
    }
  }
  &_body {
    padding: 0 rem(30);
    overflow: hidden;
    @include tablet-down {
      padding-left: rem(10);
      padding-right: rem(10);
      margin-top: rem(13);
      padding-bottom: rem(16);
      border-bottom: 1px solid $platinum;
    }
    #{$root}_middle {
      max-height: rem(550);
      overflow: auto;
      padding-bottom: rem(10);
      position: relative;
      @include small-mobile {
        max-height: unset;
      }
      &::-webkit-scrollbar:horizontal {
        height: 7px;
      }
      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 $alpha_black_16;
        background-color: $white;
        margin-top: rem(50);
        margin-left: rem(170);
        @include desktop-down {
          margin-top: rem(80);
        }
        @include small-mobile {
          margin-left: rem(120);
        }
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 10px;
        height: 4px;
        background-image: linear-gradient(
          to bottom,
          $bg_blue_dark,
          $bg_blue_light
        );
      }
      table {
        width: 100%;
        border-collapse: unset;
        thead {
          th {
            position: sticky;
            top: -2px;
            left: 0;
            z-index: 3;
            background: white;
            padding: rem(10) 0;
            cursor: pointer;
            &.active {
              .a-text {
                color: $brand_blue_dark;
              }
            }
            .a-text {
              color: $gray_light;
              font-weight: 700;
            }
            &:first-child {
              cursor: inherit;
              .a-text {
                color: $brand_blue_dark;
                font-weight: 700;
              }
            }
            @include mobile-down {
              .a-text {
                font-size: rem(18);
              }
              &:first-child {
                .a-text {
                  line-height: 1.42;
                }
              }
            }
            @include small-mobile {
              position: inherit;
            }
          }
        }
        tr {
          td {
            text-align: center;
            padding: rem(10) rem(15);
            position: relative;
            &.active {
              .score-ab {
                background: $anti_flash_white;
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 1px;
                  box-shadow: -3px 0px 6px 0 $alpha_black_2;
                }
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  height: 100%;
                  width: 1px;
                  box-shadow: 3px 0px 6px 0 $alpha_black_2;
                }
              }
            }
            &.score-mark {
              .score-ab {
                border: none !important;
                background-color: $ghost_white;
              }
            }
            .score-ab {
              position: absolute;
              top: 1px;
              left: 50%;
              width: rem(98);
              height: 100%;
              z-index: 1;
              transform: translateX(-50%);
              border-left: 1px solid $platinum;
              border-right: 1px solid $platinum;
              @include small-mobile {
                width: rem(70);
              }
            }
            .m-score {
              margin: auto;
              position: relative;
              z-index: 2;
              @include small-mobile {
                width: rem(50);
              }
            }
          }
        }
        tbody {
          tr:first-child {
            td {
              .score-ab {
                border-top: 1px solid $platinum;
                border-radius: 3px 3px 0px 0px;
              }
              &.active {
                span {
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 100%;
                  height: 1px;
                  box-shadow: 0 -3px 6px 0 $alpha_black_2;
                }
              }
            }
          }
          tr:last-child {
            td {
              .score-ab {
                border-bottom: 1px solid $platinum;
                border-radius: 0px 0px 3px 3px;
              }
              &.active {
                span {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: 1px;
                  box-shadow: 0 3px 6px 0 $alpha_black_2;
                }
              }
              &:first-child {
                box-shadow: 0 3px 6px 3px white;
              }
            }
          }
        }
        tr td:first-child,
        tr th:first-child {
          text-align: left;
          max-width: rem(300);
          min-width: rem(170);
          padding-right: 0;
          padding-left: 0;
          @include small-mobile {
            min-width: rem(120);
            position: sticky;
            top: 0;
            left: 0;
            z-index: 3;
            background: $white;
          }
        }
        tr td:first-child {
          border-right: 3px solid $white-light;
          @include small-mobile {
            font-size: rem(14);
          }
        }
      }
    }
  }
  &_info {
    @include desktop-up {
      margin-right: rem(52);
    }
  }
}
