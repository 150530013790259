.o-tabBenefits {
  @include break(1024px, 1500px) {
    padding-left: rem(35);
  }
  @include break(1337px, 1500px) {
    padding-left: 150px;
  }
  @include break(768px, 1023px) {
    padding-bottom: rem(30);
  }
  .m-tab-benefits-list_wrap {
    @include break(1024px, 1500px) {
      min-height: rem(450);
    }
    @include break(768px, 1023px) {
      min-height: rem(400);
    }
    .m-tab-benefits-list_wrap_left {
      .list-group {
        .m-tab-benefits-list_link-bg {
          @include break(768px, 1023px) {
            width: rem(100);
            height: rem(100);
          }
          &:first-child {
            @include break(1024px, 1500px) {
              left: rem(-105);
            }
            @include break(768px, 1023px) {
              width: rem(200);
              height: rem(200);
              left: rem(-70);
            }
          }
          &:nth-child(2) {
            @include break(1024px, 1500px) {
              right: rem(190);
            }
            @include break(768px, 1023px) {
              left: rem(93);
              top: rem(15);
            }
          }
          &:nth-child(3) {
            @include break(1024px, 1500px) {
              right: rem(70);
            }
            @include break(768px, 1023px) {
              left: rem(142);
              top: rem(115);
            }
          }
          &:last-child {
            @include break(1024px, 1500px) {
              top: rem(440);
              right: rem(220);
            }
            @include break(768px, 1023px) {
              top: rem(200);
              left: rem(72);
            }
          }
        }
      }
    }
    .m-tab-benefits-list_wrap_right {
      .m-tab-benefits-list_wrap_right_content_group_des_box_title {
        h3 {
          @include small-mobile {
            font-size: rem(14);
            white-space: unset;
          }
        }
      }
      .m-tab-benefits-list_wrap_right_content_group._employers {
        h3 {
          @include small-mobile {
            font-size: rem(14);
            white-space: unset;
          }
        }
      }
      .m-tab-benefits-list_wrap_right_content_group_des_box_text {
        @include break(768px, 1023px) {
          min-width: rem(250);
        }
        p {
          @include break(768px, 1023px) {
            font-size: rem(14);
            line-height: rem(22);
          }
        }
      }
      .col-5 {
        .m-tab-benefits-list_wrap_right_content_group_des {
          @include break(1024px, 1500px) {
            margin-left: rem(-80);
          }
          @include break(768px, 1023px) {
            right: rem(35);
          }
        }
      }
      .col-7 {
        .m-tab-benefits-list_wrap_right_content_group_des {
          @include break(1024px, 1500px) {
            margin-left: rem(80);
            width: rem(400);
            max-width: rem(400);
          }
          @include break(768px, 1023px) {
            left: rem(43);
            padding-left: rem(40);
          }
          &_box_text {
            @include break(1024px, 1500px) {
              max-height: rem(300);
            }
            @include break(1337px, 1500px) {
              max-height: rem(400);
              width: rem(270);
            }
            @include break(768px, 1023px) {
              min-width: rem(336);
              width: 100%;
            }
          }
        }
      }
      .col-4 {
        .m-tab-benefits-list_wrap_right_content_group_des {
          @include break(1024px, 1500px) {
            top: rem(190);
            right: rem(-120);
          }
          @include break(1337px, 1500px) {
            // top: rem(-14);
            // right: rem(-200);
            transform: translate(11%, -24.7%);
          }
          @include break(768px, 1023px) {
            top: rem(50);
            left: rem(40);
          }
          &_box {
            @include break(1024px, 1500px) {
              margin-top: rem(150);
            }
            @include break(1337px, 1500px) {
              margin-top: rem(151);
            }
            @include break(641px, 764px) {
              margin-left: rem(40);
            }
            &_text {
              @include break(1501px, 1650px) {
                width: rem(400);
              }
              @include break(1024px, 1500px) {
                max-height: rem(300);
              }
              @include break(1337px, 1500px) {
                width: rem(300);
              }
              @include break(768px, 1023px) {
                min-height: rem(250);
              }
            }
          }
        }
      }
      .col-8 {
        .m-tab-benefits-list_wrap_right_content_group_des {
          @include break(1024px, 1500px) {
            margin-top: rem(400);
            margin-left: rem(180);
          }
          @include break(768px, 1023px) {
            margin-top: rem(190);
            margin-left: rem(200);
          }
          &_box {
            @include break(1024px, 1500px) {
              max-width: rem(340);
              margin-left: rem(-80);
            }
            @include break(768px, 1023px) {
              margin-left: rem(-80);
            }
          }
        }
      }
    }
  }
}
