.o-modalSubscribePackage {
  position: relative;
  padding: rem(80) rem(215);
  background: linear-gradient(to bottom, $blue_dark, $blue_light);
  margin: auto;
  text-align: center;
  border: 3px solid $brand_yellow;
  border-radius: rem(5);
  font-family: $font-muli;
  &_icon{
    display: inline-block;
    position: absolute;
    z-index: $z-index-3;
    top: rem(20);
    right: rem(20);
  }
  @include tablet {
    padding: rem(115) rem(100);
  }
  @include mobile-down {
    padding: rem(80) rem(20);
  }
  .a-button.a-button-bold {
    color: $rick_black;
    &:hover {
      background-color: $white;
    }
  }

  &_title {
    color: $white;
    text-align: center;
    font-size: rem(32);
    font-weight: bold;
    margin-bottom: rem(40);
    @include tablet-down {
      line-height: rem(36);
    }
  }

  &_desc {
    font-weight: bold;
    margin-bottom: rem(24);

    .a-text {
      font-size: rem(20);
      text-align: left;

      @include tablet-down {
        line-height: rem(32);
      }
    }
  }

  &_bank {
    text-align: left;

    .a-text {
      font-size: rem(18);

      @include mobile-down {
        line-height: rem(30);
      }
    }
  }

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(12);
    margin-top: rem(20);

    .a-button {
      margin: 0 !important;

      &:last-child {
        color: $white;
        background-color: $dodger_blue;
        &:hover {
          background-color: $white;
          color: $rick_black;
        }
      }
    }

    @include mobile-down {
      flex-direction: column;
    }
  }
}
