.o-scoreprocesstable {
  width: 100%;
  border-collapse: unset;
  thead {
    th {
      position: sticky;
      top: -2px;
      left: 0;
      z-index: 3;
      background: $white;
      padding: rem(10) 0;
      cursor: pointer;
      &.active {
        .a-text {
          color: $brand_yellow;
        }
      }
      .a-text {
        text-transform: capitalize;
        color: $gray_light;
        font-weight: 700;
      }
      &:first-child {
        cursor: inherit;
        .a-text {
          color: $brand_blue_dark;
          font-weight: 700;
        }
      }
      @include mobile-down {
        .a-text {
          font-size: rem(18);
        }
        &:first-child {
          .a-text {
            line-height: 1.42;
          }
        }
      }
      @include small-mobile {
        position: inherit;
      }
    }
  }
  tr {
    td {
      text-align: center;
      padding: rem(10) rem(15);
      position: relative;
      .score-ab {
        position: absolute;
        top: 1px;
        left: 50%;
        width: rem(98);
        height: 100%;
        z-index: 1;
        transform: translateX(-50%);
        border-left: 1px solid $platinum;
        border-right: 1px solid $platinum;
        @include small-mobile {
          width: rem(70);
        }
      }
      .a-input {
        width: rem(78);
        height: rem(30);
        margin: auto;
        position: relative;
        z-index: 2;
        @include small-mobile {
          width: rem(50);
        }
        input {
          width: 100%;
          height: 100%;
          background-color: $white;
          text-align: center;
          padding-left: rem(0);
          padding-right: rem(0);
          color: $cadet;
          font-weight: bold;
          &::placeholder {
            font-family: $font-muli;
            color: $cadet;
            font-weight: bold;
            opacity: 1;
          }
        }
      }
      &.score-mark {
        .score-ab {
          background-color: $white;
          border: none;
          border-left: 1px solid $platinum;
          border-right: 1px solid $platinum;
        }
        .a-input_input {
          font-weight: normal;
          opacity: 0.4;
        }
      }
      &.marked {
        .score-ab {
          background-color: $ghost_white;
          border: none;
        }
      }
      &.active {
        .score-ab {
          background: $brand_yellow;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 1px;
            box-shadow: -3px 0px 6px 0 $alpha_black_2;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 1px;
            box-shadow: 3px 0px 6px 0 $alpha_black_2;
          }
        }
        .a-input_input {
          border: none;
          opacity: 1;
        }
      }
    }
  }
  tbody {
    tr:first-child {
      td {
        .score-ab {
          border-top: 1px solid $platinum;
          border-radius: 3px 3px 0px 0px;
        }
        &.active {
          span {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 1px;
            box-shadow: 0 -3px 6px 0 $alpha_black_2;
          }
        }
        &.marked {
          .score-ab {
            border: none;
          }
        }
      }
    }
    tr:last-child {
      td {
        .score-ab {
          border-radius: 0px 0px 3px 3px;
          border-bottom: 1px solid $platinum;
        }
        &.active {
          span {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            box-shadow: 0 3px 6px 0 $alpha_black_2;
          }
        }
        &.marked {
          .score-ab {
            border: none;
          }
        }
        &:first-child{
          box-shadow: 0 3px 6px 3px $white;
        }
      }
    }
  }
  tr td:first-child,
  tr th:first-child {
    text-align: left;
    max-width: rem(300);
    min-width: rem(170);
    padding-right: 0;
    padding-left: 0;
    @include small-mobile {
      min-width: rem(120);
      position: sticky;
      top: 0;
      left: 0;
      z-index: 3;
      background: $white;
    }
  }
  tr td:first-child {
    border-right: 3px solid $white-light;
    @include small-mobile {
      font-size: rem(14);
    }
  }
}
