.m-cardCoursePaymentImage{
  position: relative;
  border-radius: rem(5);
  overflow: hidden;
  cursor: pointer;
  &-active{
    border: 2px solid $brand_yellow;
  }
  &-dontPayment{
    cursor:initial;
  }

  &_layerBg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color:$brand_blue_dark;
    opacity: 0.6;
    z-index: 2;
    transition: 0.3s;
  }

  &_layerPayment{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  &_payment{
    text-align: center;
    .a-icon{
      cursor: pointer;
    }
  }

  &_text{
    margin-top: rem(10);
  }

  &_status{
    padding: rem(10);
    background-color: white;
    border-radius: rem(5);
    border: 1px solid $brand_blue_dark;
    position: absolute;
    z-index: 1;
    bottom: rem(10);
    right: rem(10);
    @include mobile-down {
      padding:rem(5);
    }
  }
}

.m-cardCoursePayment{
  $root:&;
  &:hover{
    .m-cardCoursePaymentImage_layerBg{
      top: -100%;
    }
    .m-cardCoursePaymentImage-dontPayment{
      .m-cardCoursePaymentImage_layerBg{
        top:0;
      }
    }
  }
  &-active{
    #{$root}_content{
      opacity: 1;
    }
    .m-cardCoursePaymentImage_layerBg{
      display: none;
    }
  }
  &_content{
    opacity: 0.4;
  }
  &_title{
    padding-top: rem(20);
    @include textOverflow(2);
    @include mobile-down {
      padding-top: rem(16);
    }
  }
  &_description{
    width: 75%;
    margin-top: rem(10);
    font-size: rem(12);
    line-height: rem(18);
    color: white;
    @include textOverflow(3);
    @include mobile-down {
      width: 100%;
      *{
        font-size: rem(12)!important;
        line-height: rem(18)!important;
      }
    }
    &-brand-blue-dark{
      color:$brand_blue_dark;
    }
  }
  &-unavailable{
    filter: grayscale(100%);
    pointer-events: none;
  }
}