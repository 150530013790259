#TransitionDragging {
  transition: padding .15s ease-in-out;
}

.t-uploadModal {
  width: 100%;
  height: 100%;
  &_wrap {
    width: 100%;
    padding: rem(50) rem(138);
    display: flex;
    flex-direction: column;
  }
  &_title {
    width: 100%;
    height: rem(70);
    p {
      width: auto;
      height: rem(40);
      font-family: $font-muli;
      font-size: rem(32);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: normal;
      color: $gray_dark;
      text-align: center;
    }
  }
  &_line {
    width: 100%;
    height: rem(1);
    background-color: $rick_black;
    opacity: 0.13;
  }
  &_content {
    width: 100%;
    height: rem(311);
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: rem(40) 0 rem(50);
    
    &_form {
      flex-grow: 1;
      width:  100%;
      height: rem(311);
      border-radius: rem(5);
      transition: all .3s ease;
    }

    .size-area-upload {
      width: rem(398);
      transition: all .3s ease;
    }

    .dragging-file {
      padding: rem(8);
      @extend #TransitionDragging;
    }

    &_area_upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: rem(5);
      border: dashed rem(3) $chardonnay;
      background-color: $white;

      > input {
        display: none;
      }

      > label {
        width: auto;
        height: rem(20);
        font-family: $font-muli;
        font-size: rem(16);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: $gray_dark;
        font-weight: normal;
        margin-top: rem(10);

        &:after {
          content: "Browse to upload";
          font-weight: bold;
          color: $dodger_blue;
          text-decoration: underline;
          cursor: pointer;
        }
      }
     
    }

    &_upload {
      width: rem(398);
      height: rem(311);
      margin-left: rem(16);
      transition: all .3s ease;

      p {
        width: auto;
        height: rem(30);
        font-family: $font-muli;
        font-size: rem(24);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: $rick_black;
      }

      &_file {
        width: 100%;
        height: rem(265);
        margin-top: rem(20);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &_line:not(:last-child) {
      width: 100%;
      height: rem(1);
      opacity: 0.13;
      margin-top: rem(22);
      background-color: $rick_black;
    }
  }

  @include tablet-down {
    &_wrap {
      padding: rem(50) rem(20);
    }
  }

  @include desktop-down {
    &_content {
      &_area_upload label {
        width: 90%;
        text-align: center;
      }
    }
  }

  &_note {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      width: auto;
      height: rem(15);
      object-fit: contain;
      font-family: $font-muli;
      font-size: rem(12);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $gray_dark;
    }
  }

  &_note_limit {
    margin-top: rem(4);
    margin-bottom: rem(30);
  }

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding-left: rem(70);
      padding-right: rem(70);
    }
  }

  @include mobile-down {
    &_title {
      height: rem(50);
      p {
        font-size: rem(24);
      }
    }
    &_content {
      height: auto;
      flex-direction: column;
      margin: rem(40) 0 rem(30);

      .size-area-upload {
        width: 100%;
      }
      
      &_form {
        height: rem(140);
      }

      &_area_upload {
        label {
          font-size: rem(12);
        }
      }

      &_upload {
        width: 100%;
        margin-left: 0;
        margin-top: rem(16);
        height: auto;

        p {
          font-size: rem(16);
        }

        &_file { 
          height: auto;
          
        }
      }

      &_line {
        margin-top: rem(12);
      }
  
      &_line:last-child {
        margin-top: 0;
      }
    }

    &_note {
      margin-bottom: rem(10);
    }
  }
}