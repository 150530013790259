.m-menuitem {
  padding: rem(30) 0;
  border-bottom: solid 1px $white-light;
  &_link {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $gray_dark;
    &.not-pointer{
      cursor: unset;
    }
    &:hover {
      color: $gray_dark;
      text-decoration: none;
    }
    span {
      padding-left: rem(10);
      font-size: rem(16);
      font-weight: bold;
      font-family: $font-muli;
    }
  }
  &-active {
    .m-menuitem_link {
      color: $brand_blue_dark;
      .a-icon {
        width: rem(36);
        height: rem(36);
      }
      span {
        font-size: rem(18);
      }
      @include tablet-up {
        position: relative;
        div.border-navlink {
          position: absolute;
          width: 3px;
          height: 100%;
          background: linear-gradient(to top, $bg_red_light, $brand_yellow);
          right: 0;
        }
      }
    }
  }
}
